import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgClass, NgForOf, NgIf, UpperCasePipe } from '@angular/common';
import { Subscriber } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { TableVirtualScrollDataSource, TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ResizeDirectiveModule } from '@core/directives/resize-directive/resize-directive.module';
import { SymbolFlagModule } from '@c/shared/symbol-flag/symbol-flag.module';
import { ObservableService as ObservableServiceV2 } from '@s/observable.service';
import { SmileyDataService } from '@s/smiley-data.service';
import { UserDataService } from '@s/user-data.service';
import { transformShortNumberToFullNumber } from '@u/utils';
import { isOverlayOpen } from '@u/ui-utils';
import { SortDirection } from '@core/types';
import { IScannerTableItem, SortState } from '@c/shared/scanner-symbols-list/scanner-symbols-list.model';
import { Flags, SmileyListType } from '@mod/symbol-smiley/symbol-smiley.model';
import {
  DEFAULT_SCANNER_TABLE_SORT,
  SELECT_SCANNER_SYMBOL_DEBOUNCE_TIME,
} from '@c/shared/scanner-symbols-list/scanner-symbols-list.data';

@Component({
  selector: 'app-scanner-symbols-list',
  templateUrl: './scanner-symbols-list.component.html',
  styleUrls: ['./scanner-symbols-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TableVirtualScrollModule,
    ScrollingModule,
    ResizeDirectiveModule,
    MatTableModule,
    MatSortModule,
    SymbolFlagModule,
    UpperCasePipe,
    NgClass,
    NgIf,
    MatTooltipModule,
    NgForOf
  ]
})
export class ScannerSymbolsListComponent<T extends IScannerTableItem = IScannerTableItem>
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() saveSortStateKey: string | null = null;
  @Input() isActive: boolean;
  @Input() hideRemoveButton = true;
  @Input() data: T[] = [];
  @Input() smileyListType: SmileyListType | null = null;
  @Input() userSettingsSymbolKey: string;
  @Input() readonly sortHeaderTitles: Record<string, string> = {};

  @Input() set optionalDisplayedColumns(value: string[]) {
    this.additionalDisplayedColumns = value;
    this.allDisplayedColumns = [...this.basicDisplayedColumns, ...value];
  }

  @Input() set currentSymbol(id: number) {
    if (!this.isActive && this.selectedRow) {
      this.selectedRow = null;
    }
  }

  @Output() triggerSmiley: EventEmitter<{ security_id: number, flag: Flags }> = new EventEmitter(null);
  @Output() symbolSelected: EventEmitter<T> = new EventEmitter(null);
  @Output() symbolRemoved: EventEmitter<T> = new EventEmitter(null);

  @ViewChild(MatSort) sort: MatSort;

  protected readonly smileyListTypes = SmileyListType;
  protected readonly flags = Flags;
  protected readonly defaultSortState: SortState = { ...DEFAULT_SCANNER_TABLE_SORT };

  protected dataSource = new TableVirtualScrollDataSource<T>([]);
  protected renderedData: T[] = [];

  protected readonly columns = ['flag', 'symbol', 'company'];
  protected readonly basicSortHeaderTitles = {
    flag: 'Flag',
    symbol: 'Symbol',
    company: 'Company',
  };

  protected sortState: SortState = { ...DEFAULT_SCANNER_TABLE_SORT };
  protected basicDisplayedColumns = ['flag', 'symbol', 'company'];
  protected additionalDisplayedColumns: string[] = [];
  protected allDisplayedColumns = ['flag', 'symbol', 'company'];

  protected selectedRow: T | null = null;
  protected timeoutUpDown: NodeJS.Timeout;

  protected isAbleToUpDown = true;
  protected isSmileyMenuOpened = false;
  protected showSmileyStatistic = false;

  protected readonly flagAscSortOrder = [Flags.Never, Flags.No, Flags.Maybe, Flags.Yes, Flags.None];
  protected subscriber = new Subscriber();

  constructor(
    private dialog: MatDialog,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private observableServiceV2: ObservableServiceV2,
    private userDataService: UserDataService,
    private smileyDataService: SmileyDataService,
  ) {
  }

  ngOnInit() {
    this.dataSource.sortingDataAccessor = (data, headerId) => {
      if (headerId === 'flag') {
        return this.flagAscSortOrder.indexOf(data.flag as Flags);
      }

      const isCleanNumber = !isNaN(Number(data[headerId]));

      const shortNumberRegExp = new RegExp(/\d+(\.\d+)?[KMBT]/g);
      const isShortFormatNumber = shortNumberRegExp.test(data[headerId]);

      // compare number-like strings
      if (typeof data[headerId] === 'string' && isCleanNumber) {
        return Number(data[headerId]);
      }

      // compare number-like strings (short format)
      if (typeof data[headerId] === 'string' && isShortFormatNumber) {
        return transformShortNumberToFullNumber(data[headerId]);
      }

      return data[headerId];
    };

    this.subscriber.add(
      this.dataSource.connect().subscribe((data) => {
        this.renderedData = data;
      })
    );

    this.subscriber.add(
      this.smileyDataService.showStatisticSettingAndHasAccess$
        .pipe(distinctUntilChanged())
        .subscribe((value) => {
          this.showSmileyStatistic = value;
        })
    );

    if (this.userSettingsSymbolKey && this.observableServiceV2[this.userSettingsSymbolKey]) {
      this.subscriber.add(
        this.observableServiceV2[this.userSettingsSymbolKey].subscribe(() => {
          if (!this.isActive && this.selectedRow) {
            this.selectedRow = null;
          }
        })
      );
    }

    if (this.saveSortStateKey && this.observableServiceV2[this.saveSortStateKey]) {
      // restore sort settings
      this.subscriber.add(
        this.observableServiceV2[this.saveSortStateKey]
          .pipe(take(1))
          .subscribe((storedSortState) => {
            // TODO: validate "sortState" object - fields and values
            if (storedSortState) {
              this.applySortState(storedSortState);
            }
          })
      );
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.data && changes.data.currentValue && !this.isSmileyMenuOpened) {
      this.data = changes.data.currentValue;
      this.dataSource.data = this.data;
      this.changeDetectorRef.detectChanges();
    }

    if (changes && changes.isActive && changes.isActive.currentValue) {
      // for virtual-scroll - without this table is empty after change tab
      window.dispatchEvent(new Event('resize'));
    }

    this.changeDetectorRef.markForCheck();
  }

  public onResize(): void {
    window.dispatchEvent(new Event('resize'));
  }

  protected async onSortChange(
    sortState: { active: string, direction: SortDirection },
  ): Promise<void> {
    this.sortState = { column: sortState.active, direction: sortState.direction };
    this.changeDetectorRef.detectChanges();

    if (this.selectedRow) {
      setTimeout(() => this.scrollToSelectedRow());
      this.changeDetectorRef.markForCheck();
    }

    if (this.saveSortStateKey) {
      // save state for current list
      await this.userDataService.set(this.saveSortStateKey, this.sortState);
    }
  }

  private applySortState(storedSortState: SortState): void {
    this.sortState = storedSortState;

    if (this.selectedRow) {
      setTimeout(() => this.scrollToSelectedRow());
    }

    this.changeDetectorRef.markForCheck();
  }

  protected onSmileyMenuClosed(): void {
    this.dataSource.data = this.data;
  }

  protected onSelectSymbol(element: T): void {
    clearTimeout(this.timeoutUpDown);
    this.timeoutUpDown = setTimeout(async () => {
      this.symbolSelected.emit(element);
    }, SELECT_SCANNER_SYMBOL_DEBOUNCE_TIME);
  }

  protected onSelectSmiley(securityId: number, flag: Flags): void {
    this.triggerSmiley.emit({ security_id: securityId, flag });
    this.changeDetectorRef.markForCheck();
  }

  protected onRemove(element: T): void {
    this.symbolRemoved.emit(element);
  }

  protected trackByFn(index: number, item: { id?: string | number }): string | number {
    return item.id ?? index;
  }

  private scrollToSelectedRow(): void {
    if (this.selectedRow && document.getElementById(`scanner-tr-${this.selectedRow.security_id}`)) {
      this.renderer
        .selectRootElement('#scanner-tr-' + (this.selectedRow.security_id), true)
        .scrollIntoView({ behavior: 'smooth', block: 'nearest' });

      this.changeDetectorRef.markForCheck();
    }
  }

  private arrowDownEvent(): void {
    const data = this.renderedData;
    const index = data.findIndex((el) => el.security_id === this.selectedRow.security_id);

    if (index < (data.length - 1)) {
      this.selectedRow = data[index + 1];
      this.changeDetectorRef.markForCheck();

      this.onSelectSymbol(data[index + 1]);
      this.scrollToSelectedRow();
    }
  }

  private arrowUpEvent(): void {
    const data = this.renderedData;
    const index = data.findIndex((el) => el.security_id === this.selectedRow.security_id);

    if (index > 0) {
      this.selectedRow = data[index - 1];
      this.onSelectSymbol(data[index - 1]);
      this.scrollToSelectedRow();
    }
  }

  // TODO: create service that takes opened dialogs, overlays, focused inputs, etc
  // and return observable that emits filtered up/down keys events
  // or up/down + additional details: focused inputs, opened dialogs, etc
  // return several observables for different cases, foe example navigate-lists up/down
  // could include on-key-hold functionality

  // TODO: implement on-key-hold functionality: when user hold arrow key move selection to next symbol every [DEFINED_TIME_INTERVAL]
  @HostListener('window:keydown', ['$event'])
  onHostKeyDown(event: KeyboardEvent) {
    const { code } = event;
    const dialogOpen = this.dialog.openDialogs.length;

    if (this.isActive
      && this.isAbleToUpDown
      && !isOverlayOpen()
      && dialogOpen === 0
      && ['ArrowUp', 'ArrowDown'].includes(code)
    ) {
      event.preventDefault();

      if (!this.selectedRow) {
        const firstEl = this.renderedData[0];
        this.onSelectSymbol(firstEl);
        this.selectedRow = firstEl;
        this.scrollToSelectedRow();
        return;
      }

      if (code === 'ArrowUp') {
        this.arrowUpEvent();
      } else if (code === 'ArrowDown') {
        this.arrowDownEvent();
      }
    }
  }
}
