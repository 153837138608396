import Player from '@vimeo/player';
import { Component, OnInit, input, Injector, effect, EventEmitter, Output } from '@angular/core';
import { VideoHubPlayer } from '@t/video-hub/video-hub-player.interface';

@Component({
  selector: 'app-vimeo-player',
  standalone: true,
  imports: [],
  templateUrl: './vimeo-player.component.html',
  styleUrl: './vimeo-player.component.scss'
})
export class VimeoPlayerComponent implements OnInit, VideoHubPlayer  {
  public videoUrl = input.required<string>();
  public videoStartTime = input<number>();
  @Output()
  public videoEnd = new EventEmitter<void>();
  private player: Player | undefined;

  constructor(private injector: Injector) { }

  ngOnInit(): void {
    effect(() => {
      if (this.videoUrl()) {
        this.player?.destroy();
        this.configurePlayer();
      }

    }, { injector: this.injector });
  }

  public async getVideoUrl(): Promise<string> {
    const timestamp = await this.player?.getCurrentTime();
    return this.videoUrl() + `#t=${timestamp ?? 0}s`;
  }

  public pause(): void {
    this.player?.pause();
  }

  public async getCurrentTime(): Promise<number> {
    return Math.trunc(await this.player?.getCurrentTime() ?? 0);
  }

  private onVideoEnd(): void {
    this.videoEnd.emit();
  }

  private configurePlayer(): void {
    this.player = new Player(`vimeo-player-${this.videoStartTime()}`, {
      url: this.videoUrl(),
      start_time: this.videoStartTime() ?? 0,
      responsive: true,
      pip: false,
      byline: false,
      cc: false,
      portrait: false,
      title: false,
      vimeo_logo: false,
      interactive_markers: false,
      autoplay: true,
      volume: false,
      transcript: false,
      quality_selector: false,
      speed: false,
    });

    this.player.on('ended', this.onVideoEnd.bind(this));
  }
}
