import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { environment } from '@env/environment';
import { UserDataService } from '@s/user-data.service';

interface ISubscriptionData {
  symbol: string,
  subscriptionIds: string[]
}

@Injectable({
  providedIn: 'root'
})
export class LiveWheelCalculatorStreamingService {
  private socket: any = null;
  private subscriptions: Map<string, ISubscriptionData> = new Map<string, ISubscriptionData>();
  private handlers: any = {};

  constructor(private userDataService: UserDataService) {
    const token = this.userDataService.getAuthToken();
    this.socket = io(environment.WsBaseUrl, {
      transports: ['websocket'],
      auth: { token }
    });

    this.socket.on('connect', () => {
      const subscribeData = [];

      for (const symbolData of Object.values(this.subscriptions)) {
        const { symbol, subscriptionIds } = symbolData;
        const handlers = [];
        for (const subscriptionId of subscriptionIds) {
          handlers.push({
            subscriptionId,
            handler: this.handlers[subscriptionId]
          });
        }

        subscribeData.push({
          symbol,
          handlers
        });
      }

      this.subscriptions = new Map<string, ISubscriptionData>();
      for (const subscribe of subscribeData) {
        const { symbol, handlers } = subscribe;

        for (const handlerData of handlers) {
          const { subscriptionId, handler } = handlerData;
          this.subscribe(subscriptionId, symbol, handler);
        }
      }
    });
  }

  subscribe(subscriptionId: string, symbol: string, handler: any) {
    const needSubscribe = !this.subscriptions[symbol];

    if (needSubscribe) {
      this.subscriptions[symbol] = { symbol, subscriptionIds: [subscriptionId] };
    }

    if (!this.subscriptions[symbol].subscriptionIds.some((x: string) => x === subscriptionId)) {
      this.subscriptions[symbol].subscriptionIds.push(subscriptionId);
    }

    this.handlers[subscriptionId] = handler;

    this.socket.on('pxo_live_wheel_calculator_update', (data: any) => {
      if (!data.symbol || !this.subscriptions[data.symbol]) {
        return;
      }

      for (const subscriptionId of this.subscriptions[data.symbol].subscriptionIds) {
        if (this.handlers[subscriptionId]) {
          this.handlers[subscriptionId](data);
        }
      }
    });

    this.socket.emit('pxo_live_wheel_calculator_subscribe', symbol);
  }

  unsubscribe(subscriptionId: string, symbol: string) {
    if (this.subscriptions[symbol]) {
      this.subscriptions[symbol].subscriptionIds = this.subscriptions[symbol].subscriptionIds.filter((x: string) => x !== subscriptionId);

      if (!this.subscriptions[symbol].subscriptionIds.length) {
        this.socket.emit('pxo_live_wheel_calculator_unsubscribe', symbol);
        delete this.subscriptions[symbol];
      }
    }

    if (this.handlers[subscriptionId]) {
      delete this.handlers[subscriptionId];
    }
  }
}
