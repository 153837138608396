import { Injectable } from '@angular/core';
import { TradingLogSymbolSummaryPanelStateType } from '@c/trading-log/shared';
import { ExchangeCountriesCodes, UserSettings } from '@const';
import { WheelService } from '@m1/wheel/wheel.service';
import {
  TradingLogGroupModel,
  TradingLogSymbolSummaryPanelStateDataItem,
  tradingLogSymbolSummaryPanelStateDataItemDefaultValue,
} from '@mod/trading-log';
import { ObservableService } from '@s/observable.service';
import { SymbolsService } from '@s/symbols.service';
import { UserDataService } from '@s/user-data.service';
import { TradingLogShowOnTheWheelChartResultType } from '@t/trading-log';

@Injectable()
export class TradingLogSummaryPanelService {
  private _state: TradingLogSymbolSummaryPanelStateType;

  constructor(
    private _symbolsService: SymbolsService,
    private _userDataService: UserDataService,
    private _observableService: ObservableService,
    private _wheelService: WheelService
  ) {
    this._state = _observableService.tradingLogSymbolSummaryPanelState.value;
  }

  public async showGroupOnTheWeelChart(group: TradingLogGroupModel): Promise<TradingLogShowOnTheWheelChartResultType> {
    if (!group.symbol) {
      return 'EmptySymbol';
    }

    const symbolObj = await this._symbolsService.getBySymbol(group.symbol, ExchangeCountriesCodes.US);
    if (!symbolObj?.symbol) {
      return 'NoChartData';
    }

    const state = this.getStateItem(symbolObj.symbol);
    state.selectedGroupId = group.id;
    if (state.selectedGroupId) {
      state.isDoNotShowTrades = false;
    }

    await this._wheelService.setSymbolForWheelPage({ symbol: symbolObj.symbol, security_id: symbolObj.security_id });

    return 'Ok';
  }

  public getStateItem(symbol: string): TradingLogSymbolSummaryPanelStateDataItem {
    return this._state[symbol] ?? { ...tradingLogSymbolSummaryPanelStateDataItemDefaultValue };
  }

  public async saveState(symbol: string, state: TradingLogSymbolSummaryPanelStateDataItem): Promise<void> {
    this._state[symbol] = state;
    await this._userDataService.set(UserSettings.TradingLogSymbolSummaryPanelState, this._state);
  }
}
