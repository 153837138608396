import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { Response } from '@core1/interface';
import { RestRequestorService } from './rest-requestor.service';
import { StorageKeys } from '@const';

export interface ITradingStrategy {
  id: number;
  is_default: number;
  name: string;
  reward_ratio: number;
  risk_ratio: number;
  workflow_code: string;
  past_performance_months_window: number;
  scanner_settings: string; // JSON
}

interface ITradingStrategiesService {
  getAll: () => Promise<ITradingStrategy[]>;
  getById: (id: number) => Promise<ITradingStrategy | undefined>;
}

@Injectable({
  providedIn: 'root'
})
export class TradingStrategiesService implements ITradingStrategiesService {
  static readonly PowerXWorkflowCode = 'w1';
  static readonly WtfStrategyName = 'WTF';

  static readonly DividendsStrategyWorkflowCode = 'dividends';
  static readonly DividendsStrategyNames = {
    Dividends1year: 'Dividends 1yr',
    Dividends2years: 'Dividends 2yr',
    Dividends3years: 'Dividends 3yr',
    Dividends4years: 'Dividends 4yr',
    Dividends5years: 'Dividends 5yr',
  };

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private restRequesterService: RestRequestorService
  ) { }

  _get = async () => {
    let tradingStrategies: ITradingStrategy[] = this.localStorageService.getFromMemory(StorageKeys.TradingStrategies);

    if (!tradingStrategies) {
      const { result } = await this.restRequesterService.makeRequest(StorageKeys.TradingStrategies,
        () => this.http.get<Response>('/v2/tradingStrategies').toPromise());

      tradingStrategies = result;
      this.localStorageService.setToMemory(StorageKeys.TradingStrategies, tradingStrategies);
    }

    return tradingStrategies;
  }

  getAll = async () => {
    const tradingStrategies = await this._get();

    return tradingStrategies.filter(({ workflow_code }) => workflow_code === TradingStrategiesService.PowerXWorkflowCode);
  }

  getDefault = async () => {
    const strategies = await this.getAll();

    const defaultStrategy = strategies.find((s) => s.is_default);

    if (defaultStrategy) {
      return defaultStrategy;
    }

    return strategies.length ? strategies[0] : null;
  };

  getById = async (strategyId: number) => {
    const tradingStrategies = await this.getAll();

    return tradingStrategies.find((s) => s.id === strategyId);
  };

  getWtfStrategy = async () => {
    const tradingStrategies = await this._get();

    return tradingStrategies.find((s) => s.name === TradingStrategiesService.WtfStrategyName);
  };

  public getDividendsStrategies = async (): Promise<ITradingStrategy[]> => {
    const tradingStrategies = await this._get();

    return tradingStrategies.filter(({ workflow_code }) => {
      return workflow_code === TradingStrategiesService.DividendsStrategyWorkflowCode;
    });
  };
}
