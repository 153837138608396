import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

import { MomentDateTimeFormats, TradingLogInputDateFormats } from '@const';
import { TradingLogGroupModel, TradingLogGroupSummaryModel, TradingLogTransactionModel } from '@mod/trading-log';
import { TradingLogGroupStatus, TradingLogGroupType, TradingLogTransactionStatus, TradingLogTransactionType } from '@t/trading-log';
import { isDateAfterToday } from '@u/helpers';
import { isNumberEmpty } from '@u/helpers/is-number-empty.helper';
import { TransactionDisplayType } from '@mod/trading-log/metric-details';

@Injectable()
export class TradingLogLogicService {

  public readonly errorTransactionHint = 'There are transactions with invalid or missing data. Please check fields with a red triangle to identify issues.';

  constructor(
  ) { }
  public isOptionsType(type: TradingLogTransactionType): boolean {
    return type === TradingLogTransactionType.BuyCall
      || type === TradingLogTransactionType.SellCall
      || type === TradingLogTransactionType.BuyPut
      || type === TradingLogTransactionType.SellPut;
  }

  public isStockType(type: TradingLogTransactionType): boolean {
    return type === TradingLogTransactionType.BuyStock
      || type === TradingLogTransactionType.SellStock;
  }

  public isDividendsType(type: TradingLogTransactionType): boolean {
    return type === TradingLogTransactionType.Dividends;
  }

  public getOpositeTransactionType(type: TradingLogTransactionType): TradingLogTransactionType {
    switch (type) {
      case TradingLogTransactionType.BuyStock:
        return TradingLogTransactionType.SellStock;
      case TradingLogTransactionType.SellStock:
        return TradingLogTransactionType.BuyStock;
      case TradingLogTransactionType.BuyCall:
        return TradingLogTransactionType.SellCall;
      case TradingLogTransactionType.SellCall:
        return TradingLogTransactionType.BuyCall;
      case TradingLogTransactionType.BuyPut:
        return TradingLogTransactionType.SellPut;
      case TradingLogTransactionType.SellPut:
        return TradingLogTransactionType.BuyPut;
      default:
        throw new Error(`The type '${type}' is not supported to get opposite`);
    }
  }

  public getTransactionDisplayName(type: TradingLogTransactionType): string {
    switch (type) {
      case TradingLogTransactionType.BuyStock:
        return 'Buy Stock';
      case TradingLogTransactionType.SellStock:
        return 'Sell Stock';
      case TradingLogTransactionType.BuyCall:
        return 'Buy Call';
      case TradingLogTransactionType.SellCall:
        return 'Sell Call';
      case TradingLogTransactionType.BuyPut:
        return 'Buy Put';
      case TradingLogTransactionType.SellPut:
        return 'Sell Put';
      case TradingLogTransactionType.Dividends:
        return 'Dividends';
      default:
        throw new Error(`The type '${type}' is not supported to get opposite`);
    }
  }

  public getExpiredMessageByType(type: TradingLogTransactionType): string {
    switch (type) {
      case TradingLogTransactionType.BuyCall:
      case TradingLogTransactionType.BuyPut:
        return 'NOTE: adjust if some contracts were sold back';
      case TradingLogTransactionType.SellCall:
      case TradingLogTransactionType.SellPut:
        return 'NOTE: adjust if some contracts were bought back';
      default:
        return '';
    }
  }

  public getGroupTypeDisplayName(type: TradingLogGroupType): string {
    switch (type) {
      case TradingLogGroupType.Active:
        return 'Active';
      case TradingLogGroupType.Archived:
        return 'Archived';
      default:
        return '';
    }
  }

  public getGroupStatusBySummary(group: Partial<TradingLogGroupModel>, summary: TradingLogGroupSummaryModel): TradingLogGroupStatus {
    if (group?.has_error || group?.isLocalError) {
      return TradingLogGroupStatus.Error;
    }

    if (summary?.min_date) {
      if (summary?.shares === 0 && summary?.buy_contracts === 0 && summary?.sell_contracts === 0) {
        return TradingLogGroupStatus.Closed;
      }

      if (summary?.shares !== 0 || summary?.buy_contracts > 0 || summary?.sell_contracts < 0) {
        return TradingLogGroupStatus.Open;
      }
    }
    return TradingLogGroupStatus.Undefined;
  }

  public getGroupStatusText(status: TradingLogGroupStatus): string {
    switch (status) {
      case TradingLogGroupStatus.Open:
        return 'Open - it includes open transactions';
      case TradingLogGroupStatus.Closed:
        return 'Closed - all transactions are closed';
      case TradingLogGroupStatus.Error:
        return this.errorTransactionHint;
      case TradingLogGroupStatus.Undefined:
        return '';
      default:
        throw new Error('Unknown status');
    }
  }

  public getProcessedInputDate(inputValue: string, selectedFormat: TradingLogInputDateFormats): Moment | null {
    let date: Moment | null = null;
    switch (selectedFormat) {
      case TradingLogInputDateFormats.MonthDayYear:
        date = moment(inputValue, MomentDateTimeFormats.ReadableDate, true);
        date = date.isValid() ? date : moment(inputValue, MomentDateTimeFormats.ReadableNoYearDate, true);
        date = date.isValid() ? date : moment(inputValue, MomentDateTimeFormats.NoYearDate, true);
        date = date.isValid() ? date : moment(inputValue, MomentDateTimeFormats.InputFullYearDate);
        date = date.isValid() ? date : moment(inputValue, MomentDateTimeFormats.InputDate);
        return date.isValid() ? date : null;
      case TradingLogInputDateFormats.FullYearMonthDay:
        date = moment(inputValue, MomentDateTimeFormats.ReadableDate, true);
        date = date.isValid() ? date : moment(inputValue, MomentDateTimeFormats.ReadableNoYearDate, true);
        date = date.isValid() ? date : moment(inputValue, MomentDateTimeFormats.NoYearDate, true);
        date = date.isValid() ? date : moment(inputValue, MomentDateTimeFormats.InputDateFullYearMonthDay);
        return date.isValid() ? date : null;
      case TradingLogInputDateFormats.FullYearDayMonth:
        date = moment(inputValue, MomentDateTimeFormats.ReadableDate, true);
        date = date.isValid() ? date : moment(inputValue, MomentDateTimeFormats.ReadableNoYearDate, true);
        date = date.isValid() ? date : moment(inputValue, MomentDateTimeFormats.NoYearDateDaysFirst, true);
        date = date.isValid() ? date : moment(inputValue, MomentDateTimeFormats.InputDateFullYearDayMonth);
        return date.isValid() ? date : null;
      case TradingLogInputDateFormats.DayMonthYear:
        date = moment(inputValue, MomentDateTimeFormats.ReadableDate, true);
        date = date.isValid() ? date : moment(inputValue, MomentDateTimeFormats.ReadableNoYearDate, true);
        date = date.isValid() ? date : moment(inputValue, MomentDateTimeFormats.NoYearDateDaysFirst, true);
        date = date.isValid() ? date : moment(inputValue, MomentDateTimeFormats.InputDateDayMonthFullYear);
        date = date.isValid() ? date : moment(inputValue, MomentDateTimeFormats.InputDateDayMonthYear);
        return date.isValid() ? date : null;

      default:
        throw new Error('Unknown TradingLogInputDateFormat');
    }
  }

  public getTransactionErrorStatus(transaction: Readonly<Partial<TradingLogTransactionModel>>,
    parentStatus: TradingLogTransactionStatus = TradingLogTransactionStatus.None): boolean {
    // If this is parent transaction
    if (!transaction.parent_id) {
      // check errors for dividends type transaction
      if (transaction.type === TradingLogTransactionType.Dividends) {
        return !transaction.date || isDateAfterToday(moment(transaction.date, MomentDateTimeFormats.ServerDate))
          || isNumberEmpty(transaction.qty) || isNumberEmpty(transaction.price);
      }
      // check errors for option type transaction
      else if (this.isOptionsType(transaction.type)) {
        const regularError = !transaction.date || isDateAfterToday(moment(transaction.date, MomentDateTimeFormats.ServerDate))
          || !transaction.expiration || isNumberEmpty(transaction.strike) || isNumberEmpty(transaction.qty)
          || isNumberEmpty(transaction.price);

        let statusError = false;

        if (transaction.status === TradingLogTransactionStatus.Expired) {
          statusError = !transaction.close_date || isDateAfterToday(moment(transaction.close_date, MomentDateTimeFormats.ServerDate))
            || isNumberEmpty(transaction.expired_cnt);
        }

        if (transaction.status === TradingLogTransactionStatus.Assigned && transaction.expired_cnt !== null) {
          statusError = !transaction.close_date || isDateAfterToday(moment(transaction.close_date, MomentDateTimeFormats.ServerDate));
        }
        return regularError || statusError;
      }
      // check errors for stock type transaction
      else if (this.isStockType(transaction.type)) {
        return !transaction.date || isDateAfterToday(moment(transaction.date, MomentDateTimeFormats.ServerDate))
          || isNumberEmpty(transaction.qty) || isNumberEmpty(transaction.price);
      }
      else {
        throw new Error('Unknown transaction type');
      }
    }
    // If it's a child transaction the
    else {
      switch (parentStatus) {
        case TradingLogTransactionStatus.Exercised:
          return !transaction.date || isDateAfterToday(moment(transaction.date, MomentDateTimeFormats.ServerDate))
            || isNumberEmpty(transaction.qty);
        case TradingLogTransactionStatus.Closed:
          return !transaction.date || isDateAfterToday(moment(transaction.date, MomentDateTimeFormats.ServerDate))
            || isNumberEmpty(transaction.qty) || isNumberEmpty(transaction.price);
        case TradingLogTransactionStatus.Assigned:
          return !transaction.date || isDateAfterToday(moment(transaction.date, MomentDateTimeFormats.ServerDate))
            || isNumberEmpty(transaction.qty);
        case TradingLogTransactionStatus.CalledAway:
          return !transaction.date || isDateAfterToday(moment(transaction.date, MomentDateTimeFormats.ServerDate))
            || isNumberEmpty(transaction.qty) || (transaction.type === TradingLogTransactionType.BuyStock
              ? isNumberEmpty(transaction.price) : false);
        default:
          console.error('Parent transaction type cannot have sub transactions or Unknown', { transaction, parentStatus })
          return false;
      }
    }
  }

  public isTratnsactionEmpty(transaction: Readonly<Partial<TradingLogTransactionModel>>): boolean {
    if (transaction.type === TradingLogTransactionType.Dividends) {
      return !transaction.date && !transaction.time && isNumberEmpty(transaction.qty)
        && isNumberEmpty(transaction.price) && !transaction.note;
    }
    else if (this.isOptionsType(transaction.type)) {
      return !transaction.date && !transaction.time && !transaction.expiration && isNumberEmpty(transaction.strike)
        && isNumberEmpty(transaction.qty) && isNumberEmpty(transaction.price) && isNumberEmpty(transaction.fees)
        && isNumberEmpty(transaction.commission) && !transaction.note
        && !transaction.confidence && !transaction.decision && transaction.status === TradingLogTransactionStatus.None;
    }
    else if (this.isStockType(transaction.type)) {
      return !transaction.date && !transaction.time && isNumberEmpty(transaction.qty) && isNumberEmpty(transaction.price)
        && isNumberEmpty(transaction.fees) && isNumberEmpty(transaction.commission) && !transaction.note
        && !transaction.confidence && !transaction.decision && transaction.status === TradingLogTransactionStatus.None;
    }
    else {
      throw new Error('Unknown transaction type');
    }
  }

  public getTransactionDisplayType(type: TradingLogTransactionType): TransactionDisplayType {
    switch (type) {
      case TradingLogTransactionType.BuyStock:
        return {
          method: 'Buy',
          assetType: 'Stock'
        };
      case TradingLogTransactionType.SellStock:
        return {
          method: 'Sell',
          assetType: 'Stock'
        };
      case TradingLogTransactionType.BuyPut:
        return {
          method: 'Buy',
          assetType: 'Put'
        };
      case TradingLogTransactionType.SellPut:
        return {
          method: 'Sell',
          assetType: 'Put'
        };
      case TradingLogTransactionType.BuyCall:
        return {
          method: 'Buy',
          assetType: 'Call'
        };
      case TradingLogTransactionType.SellCall:
        return {
          method: 'Sell',
          assetType: 'Call'
        };
      case TradingLogTransactionType.Dividends:
        return {
          method: '',
          assetType: 'Dividends'
        };
      default:
        return {
          method: '',
          assetType: ''
        };
    }
  }

  public getTransactionStatusText(status: TradingLogTransactionStatus): string {
    switch (status) {
      case TradingLogTransactionStatus.Assigned:
        return 'Assigned';
      case TradingLogTransactionStatus.Closed:
        return 'Closed';
      case TradingLogTransactionStatus.CalledAway:
        return 'Called Away';
      case TradingLogTransactionStatus.Expired:
        return 'Expired';
      case TradingLogTransactionStatus.Exercised:
        return 'Exercised';
      case TradingLogTransactionStatus.None:
        return '';
      default:
        return '';
    }
  }
}
