import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ILiveSubscription, IOption } from '../../wheel-calculator.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() subscription: ILiveSubscription;
  @Input() roiLowerBound: number;
  @Input() roiUpperBound: number;
  @Input() data: IOption[];
  @Input() maxRoi: number;
  @Output() itemClickEvent = new EventEmitter<IOption>();

  displayedColumns: string[] = ['strike', 'bid', 'ask', 'delta', 'roi'];

  constructor() { }

  ngOnInit(): void {
  }

  getRoiClass(roi: number) {
    let className = '';

    if (roi === null) {
      return className;
    }

    if (roi > this.roiUpperBound) {
      className = 'warning';
    } else if (roi < this.roiLowerBound) {
      className = 'danger';
    } else if (roi >= this.roiLowerBound && roi <= this.roiUpperBound) {
      className = 'successful';
    }

    return className;
  }

  getRoiWidth(roi: number) {
    return this.maxRoi
      ? (roi * 100) / this.maxRoi
      : 0;
  }

  rowClick(row: IOption) {
    this.itemClickEvent.emit(row);
  }
}
