import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import * as moment from 'moment';

import { StorageKeys } from '@const';
import { Response } from '@core1/interface';
import { GlobalIdentificator } from '@t/common';
import { LocalStorageService } from './local-storage.service';
import { RestRequestorService } from './rest-requestor.service';

export interface ISymbol {
  description: string;
  exchange_code: string;
  exchange_id: GlobalIdentificator;
  exchange_name: string;
  last_updated: Date;
  security_id: GlobalIdentificator;
  symbol: string;
  type: 'stock'; // it will be enum in future
  country_code: string;
  sector_code?: number;
  industry_code?: number;
  is_dow_jones?: boolean;
  is_nasdaq?: boolean;
  is_sp_500?: boolean;
  is_sp_100?: boolean;
}

export interface IOption {
  underlyingSymbol: string;
  symbol: string;
  expirationDate: string;
  type: string;
  strike: number;
}

@Injectable({
  providedIn: 'root',
})
export class SymbolsService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private restRequestorService: RestRequestorService
  ) {}

  _getAll: () => Promise<object> = async () => {
    let symbolsMapObject = this.localStorageService.getFromMemory(StorageKeys.Symbols);

    if (!symbolsMapObject || moment(symbolsMapObject.validUntil).unix() < moment().unix()) {
      const { result } = await this.restRequestorService.makeRequest(StorageKeys.Symbols, () =>
        firstValueFrom(this.http.get<Response>(`/v2/symbols`))
      );

      const symbolsMap = result.symbols.reduce((acc, curr) => {
        acc[curr.security_id] = curr;

        return acc;
      }, {});

      symbolsMapObject = {
        symbols: symbolsMap,
        validUntil: result.validUntil
      };

      this.localStorageService.setToMemory(StorageKeys.Symbols, symbolsMapObject);
    }

    return symbolsMapObject.symbols;
  };

  getAll: () => Promise<ISymbol[]> = async () => {
    const symbolsMap = await this._getAll();

    return Object.values(symbolsMap);
  };

  getById: (security_id: number) => Promise<ISymbol> = async (security_id) => {
    const symbols = await this._getAll();

    const symbol = symbols[security_id];

    return symbol;
  };

  public getManyByIDs: (securityIDs: number[]) => Promise<ISymbol[]> = async (securityIDs) => {
    const allSymbols = await this._getAll();

    return securityIDs.reduce((acc, item) => {
      const symbol = allSymbols[item];

      if (symbol) {
        acc.push(symbol);
      }

      return acc;
    }, []);
  };

  getBySymbol: (symbol: string, country: string) => Promise<ISymbol> = async (symbol, country) => {
    const symbols = await this.getAll();

    return symbols.find((s) => s.country_code === country && s.symbol === symbol);
  };
}
