import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { LoginService } from 'src/app/components/login/login.service';
import { ObservableService } from 'src/app/core/directives/observable.service';
import { CompareDataWindow } from 'src/app/core/business/compareDatawinow';
import { Users } from 'src/app/core/model';
import { Router } from '@angular/router';
import { AppLoaderService as AppLoaderServiceV2 } from '../../services/app-loader.service';
import { AuthService } from '@s/common/auth.service';
import { UserDataService } from '@s/user-data.service';
import { ObservableService as ObservableServiceV2 } from '@s/observable.service';
import { environment } from '@env/environment';
import { v4 as uuidv4 } from 'uuid';
import { TabNames, UserSettingsLocal } from '@const';
import { LocalStorageService } from '@s/local-storage.service';
import { NavigationService } from '@s/navigation.service';
import { UsersService } from '@s/users.service';

@Component({
  selector: 'app-error',
  templateUrl: './user-aggrement.component.html',
  styleUrls: ['./user-aggrement.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserAggrementComponent implements OnInit, OnDestroy {
  agreementCheckbox = false;
  public user: Users;
  data = { userEmail: null, userId: null, userPassword: null, ghlUserId: null };
  isLoading = false;
  constructor(
    private loginService: LoginService,
    private observableService: ObservableService,
    private router: Router,
    private navigationService: NavigationService,
    private appLoaderServiceV2: AppLoaderServiceV2,
    private authService: AuthService,
    private userDataService: UserDataService,
    private observableServiceV2: ObservableServiceV2,
    private usersService: UsersService,
    private _localStorageService: LocalStorageService,
  ) { }

  ngOnInit() {
    const userData = JSON.parse(localStorage.getItem('userLoginDetails'));
    this.data.userEmail = userData?.email;
    this.data.userId = userData?.userid;
    this.data.userPassword = userData?.password;
    this.data.ghlUserId = userData?.ghlUserId;
  }

  ngOnDestroy() {
    localStorage.removeItem('userLoginDetails');
  }

  licenceclosed() {
    this.router.navigate(['/login']);
  }

  licenceAgreed() {
    this.isLoading = true;

    if (this.data.ghlUserId) {
      this.usersService.addLicenceAgreement().subscribe((res) => {
        if (res) {
          this.loginAfterLicenceAgreement();
        }
      });
    } else {
      this.loginService.addLicenceAgreementTag(this.data.userId).subscribe((res) => {
        if (res) {
          this.loginAfterLicenceAgreement();
        }
      });
    }
  }

  async loginAfterLicenceAgreement() {
    const userEmail = this.data.userEmail;
    const userPassword = this.data.userPassword;
    const loginId = uuidv4();
    const res = await this.loginService.login(userEmail, userPassword, loginId);
    this.user = res.result;
    this._localStorageService.set(UserSettingsLocal.LoginId, loginId);
    delete this.user.accessType; // To prevent access type in local storage
    if (res.type === false) {
      this.authService.watchUserActivity(environment.UpdateTokenByUserActivityDelaySec);
      this.userDataService.setAuthToken(this.user.token);
      this.userDataService.setAuthTokenExpirationDate(this.user.tokenExpirationDate || '');
      this.observableServiceV2.showMaintenance.next(this.observableServiceV2.showMaintenance.value);

      localStorage.setItem('user', JSON.stringify(this.user));
      this.observableService.userSetting.next(this.user);
      const plChartOption: any = {
        firstSchema: new CompareDataWindow().setProfitLossValue(this.user.leftVariant).schema,
        firstSchemaName: new CompareDataWindow().setProfitLossValue(this.user.leftVariant).schemaName,
        secondSchema: new CompareDataWindow().setProfitLossValue(this.user.rightVariant).schema,
        secondSchemaName: new CompareDataWindow().setProfitLossValue(this.user.rightVariant).schemaName,
      };

      localStorage.setItem('theme', this.user.theme == 0 ? "dark" : "light");
      this.observableService.setTheme(this.user.theme == 0 ? "dark" : "light");
      this.observableService.theme.next(this.user.theme == 0 ? "dark" : "light");
      localStorage.setItem('exchange', 'us');
      localStorage.setItem('profitloss', JSON.stringify(plChartOption));
      localStorage.setItem('comparePosition', JSON.stringify({
        first: this.user.leftVariant,
        second: this.user.rightVariant
      }));

      this.observableService.comparePostion.next({
        first: this.user.leftVariant,
        second: this.user.rightVariant
      });
      localStorage.setItem('selectedTrade', 'none');
      localStorage.setItem('selectedEntryExit', 'none');

      // initialize V2 application
      await this.appLoaderServiceV2.initializeApplication();

      await this.navigationService.redirectToTab(TabNames.PowerX);
    } else {
      console.log('Please enter valid email and password');
    }
    this.isLoading = false;
  }
}
