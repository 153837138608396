import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { WeekdayTradesTabs } from '@c/trading-log/dashboard/tables/weekday-trades-table/weekday-trades-table.model';
import { TradingLogDashboardTabs } from '@c/trading-log/dashboard/trading-log-dashboard-container/trading-log-dashboard-container.model';
import { defaultTLTradesFilterValue } from '@c/trading-log/trades/trading-log-trades-container/trading-log-trades-filter-form/models';
import { TradingLogContainerTab } from '@c/trading-log/trading-log-container/trading-log-container.model';
import {
  ChartTabs,
  Countries,
  DefaultOptionsSymbolsTableSortState,
  DefaultPrintOptions,
  DefaultStocksSymbolsTableSortState,
  ChartIntervals,
  defaultSymbolName,
  ExchangeCountriesCodes,
  LowestCloseLineIndicatorOptions,
  LowestHighestCloseLineIndicatorOptions,
  MomentDateTimeFormats,
  ServerSettings,
  ShowEntryAndExitOptions,
  ShowTradeOptions,
  TabNames,
  TechnicalIndicators,
  Themes,
  ThemesNumValues,
  TLDashboardMetricDetailsModalPageSizeOptions,
  TradePositions,
  TradingHubTabs,
  TradingLogInputDateFormats,
  UserSettings,
  wheelChartSectionDefaultHeight,
  WheelROIDefaultRange,
  DefaultRockyAlwaysVisible,
} from '@const';
import { ApplicationVersionModel } from '@mod/admin/application-version.model';
import { IRawHoliday } from '@mod/data/holidays.model';
import { IWorkingHours } from '@mod/data/working-hours.model';
import { OrderFilterStatus } from '@mod/trading-panel/trading-panel.model';
import { BrokerAuthenticationService } from '@s/broker-authentication.service';
import { AuthService } from '@s/common/auth.service';
import { HolidaysService } from '@s/holidays.service';
import { LocalStorageService } from '@s/local-storage.service';
import { ObservableService } from '@s/observable.service';
import { ServerDataService } from './server-data.service';
import { ScheduledEventsService } from '@s/scheduled-events.service';
import { SymbolsService } from '@s/symbols.service';
import { ThemeService } from '@s/theme.service';
import { TradingHubService } from '@s/trading-hub.service';
import { TradingStrategiesService } from '@s/trading-strategies.service';
import { UserDataService } from '@s/user-data.service';
import { IMySettings, UserSettingsService } from '@s/user-settings.service';
import { WorkingHoursService } from '@s/working-hours.service';
import { ServerNotificationsService } from '@s/server-notifications/server-notifications.service';
import { ServerNotificationsSettingsService } from '@s/server-notifications/server-notifications-settings.service';
import { TradingLogGroupType } from '@t/trading-log/trading-log-group-type.enum';
import { DataWindowUpdateSource, WheelViewModes } from '@t/wheel/wheel.types';
import {
  convertToEasternTime,
  getTabNameByRoute,
  getUrlSegmentsByRoute,
} from '@u/utils';
import * as moment from 'moment';

import { MarketTimeService } from './market-time.service';
import { DEFAULT_IS_FILTERS_HIDDEN, DEFAULT_WTF_FILTERS_STATE } from '@c/wtf-content/wtf-scanner-filter/wtf-scanner-filter.data';
import { DEFAULT_WTF_SCANNER_TAB_INDEX } from '@c/wtf-content/wtf-scanner-watchlist-panel/wtf-scanner-watchlist-panel.data';
import { getDefaultDateRangeFilterValue } from '@c/shared/date-contols/components/filters/daterange-filter/daterange-filter.model';
import { DEFAULT_SCANNER_TABLE_SORT } from '@c/shared/scanner-symbols-list/scanner-symbols-list.data';
import { DEFAULT_STOCK_SCREENER_FILTERS_STATE } from '@c/stock-screener-content/stock-screener-filter/stock-screener-filter.data';
import { tradesListFilterFormDefault, tradesListFilterFormNoImportedTradesDefault } from '@c/import-trades/trades-list-filter-form/models';
import { importTradesListSortDefault } from '@c/import-trades/import-trades-list-table/models/import-trades-list-sort.default';
import { DEFAULT_STOCK_SCREENER_DETAILS_TAB_INDEX } from '@c/stock-screener-content/stock-screener-details-panel/stock-screener-details-panel.data';
import {
  DEFAULT_DIVIDENDS_FILTERS_STATE,
  DEFAULT_IS_DIVIDENDS_FILTERS_HIDDEN
} from '@c/dividends-content/dividends-scanner-filter/dividends-scanner-filter.data';
import { LoginNavigationControlValueEnum } from '@c/my-settings-modal/my-settings-modal.model';
import {
  DEFAULT_IS_SHORT_SELLING_STOCKS_FILTERS_HIDDEN,
  DEFAULT_SHORT_SELLING_STOCKS_FILTERS_STATE
} from '@c/short-selling-stocks-content/short-selling-stocks-filter/short-selling-stocks-filter.data';
import { EntryStopIndicatorsOptions } from '@c/wtf-content/wtf-chart/wtf-chart.model';
import {
  DEFAULT_IS_SHORTING_STOCKS_SCANNER_FILTERS_HIDDEN,
  INITIAL_SHORTING_STOCKS_SCANNER_FILTERS_STATE
} from '@c/shorting-stocks-scanner-content/shorting-stocks-scanner-filter/shorting-stocks-scanner-filter.data';
import {
  DEFAULT_DIVIDENDS_STRATEGY_FILTERS_STATE,
  DEFAULT_IS_DIVIDENDS_STRATEGY_FILTERS_HIDDEN
} from '@c/dividends-strategy-content/dividends-strategy-scanner-filter/dividends-strategy-scanner-filter.data';
import {
  entryIndicatorsOptions
} from '@c/dividends-strategy-content/dividends-strategy-chart/dividends-strategy-chart.data';

@Injectable({
  providedIn: 'root',
})
export class AppLoaderService {
  constructor(
    private tradingHubService: TradingHubService,
    private tradingStrategiesService: TradingStrategiesService,
    private userDataService: UserDataService,
    private symbolsService: SymbolsService,
    private userSettingsService: UserSettingsService,
    private observableService: ObservableService,
    private themeService: ThemeService,
    private localStorageService: LocalStorageService,
    private marketTimeService: MarketTimeService,
    private brokerAuthenticationService: BrokerAuthenticationService,
    private authService: AuthService,
    private workingHoursService: WorkingHoursService,
    private holidaysService: HolidaysService,
    private serverDataService: ServerDataService,
    private scheduledEventsService: ScheduledEventsService,
    private serverNotificationsSettingsService: ServerNotificationsSettingsService,
    private serverEventsService: ServerNotificationsService,
    private location: Location,
  ) {
  }

  async initializeApplication(): Promise<void> {
    // no user or token is expired - don't do anything
    if (!this.userDataService.getUser() || !this.authService.isTokenValid()) {
      return;
    }

    try {
      const [
        userData,
        userSettings,
        allSymbols,
        tradingStrategies,
        workingHours,
        holidayData,
        heatmapApiVersion,
        showDividendsAnalysis,
        showStockScreener,
        showImportTrades,
        showTradierImportTrades,
        showVideoHub,
        showRockyDataWindow,
        showRockyEconomicCalendar,
        showHeatmapDetailsDataWindow,
        showDividendsStrategy,
      ] = await Promise.all([
        this.userDataService.getMultiple([
          UserSettings.IsDeveloperAccount,
          UserSettings.ActiveTab,
          UserSettings.TradePosition,
          UserSettings.ChartTab,
          UserSettings.RunScannerTradingStrategyId,
          UserSettings.ShowEntryAndExitOption,
          UserSettings.ShowTradeOption,
          UserSettings.ShowTechnicalIndicators,
          UserSettings.Symbol,
          UserSettings.AccountSize,
          UserSettings.AccountRiskPercent,
          UserSettings.AccountRiskAmount,
          UserSettings.JoinCommunityBtnVisible,
          UserSettings.WatchPxoTutorialsBtnVisible,
          UserSettings.FirstTradingStrategyId,
          UserSettings.SecondTradingStrategyId,
          UserSettings.TradeReportTradingStrategyId,
          UserSettings.SelectScannerTab,
          UserSettings.WatchlistSort,
          UserSettings.ScannerResultSort,
          UserSettings.WheelSymbol,
          UserSettings.IsWheelCalculator,
          UserSettings.IsWheelPremiums,
          UserSettings.WheelTopTab,
          UserSettings.WheelScannerTab,
          UserSettings.IsWheelFiltersHidden,
          UserSettings.WheelFiltersState,
          UserSettings.WheelScannerSortAggressive,
          UserSettings.WheelScannerSortConservative,
          UserSettings.WheelScannerSortAll,
          UserSettings.WheelWatchlistSort,
          UserSettings.PrintOptions,
          UserSettings.HolidaysCountry,
          UserSettings.TradingLogContainerTab,
          UserSettings.TradingLogTradesTab,
          UserSettings.TradingLogTradesFilterValue,
          UserSettings.TradingLogTradesSortingState,
          UserSettings.TradingLogTradesInfoColumnsStateValue,
          UserSettings.TradingLogTradesInfoColumnsShowDevFieldsValue,
          UserSettings.TradingLogTradesShowTimeColumn,
          UserSettings.TradingLogFirstTimeGroupAdded,
          UserSettings.TradingLogDashboardDatesFilterValue,
          UserSettings.TradingLogDashboardSymbolFilterValue,
          UserSettings.TradingLogDashboardAccountsFilterValues,
          UserSettings.TradingLogDashboardStrategiesFilterValues,
          UserSettings.TradingLogDashboardOpenPositionsExpandedValues,
          UserSettings.TradingLogDashboardTab,
          UserSettings.TradingLogDashboardWeekdayTradesTab,
          UserSettings.TradingLogDashboardOptionsSymbolsTableSortState,
          UserSettings.TradingLogDashboardStocksSymbolsTableSortState,
          UserSettings.TradingLogDashboardMetricDetailsModalPageSize,
          UserSettings.TradingLogSymbolSummaryPanelState,
          UserSettings.TradingLogSelectedInputDateFormat,

          /*-- Import page --*/
          UserSettings.ImportPageTradesListFilterValue,
          UserSettings.ImportPageTradesListSortValue,
          /*-----------------*/

          UserSettings.WheelFiltersWidth,
          UserSettings.WheelFiltersHeight,
          UserSettings.WheelDataWindowUpdateSource,
          UserSettings.WheelViewMode,
          UserSettings.WheelChartSectionHeight,
          UserSettings.LowestHighestCloseLineIndicatorWheel,
          UserSettings.LowestCloseLineIndicatorWheel,
          UserSettings.LowestHighestCloseLineIndicatorBullCallSpread,
          UserSettings.LowestCloseLineIndicatorBullCallSpread,
          UserSettings.EarningsCalendarSymbol,
          UserSettings.EarningsCalendarTab,
          UserSettings.EarningsFilterValue,
          UserSettings.WheelROILowerBound,
          UserSettings.WheelROIUpperBound,
          UserSettings.BullCallSpreadSymbol,
          UserSettings.BullCallSpreadRecentSymbols,
          UserSettings.BullCallSpreadTab,
          UserSettings.BullCallSpreadIsFiltersRolledUp,
          UserSettings.BullCallSpreadSelectedDropPercentOptions,
          UserSettings.BullCallSpreadDetailsSectionWidth,
          UserSettings.TradingHubTab,
          UserSettings.TradingHubLastOpenDate,
          UserSettings.PreTradingChecklistTasks,
          UserSettings.PreTradingChecklistOpenOnAppStart,
          UserSettings.TradingHubPlans,
          UserSettings.TradingHubPlansTab,
          UserSettings.TradingHubQuotesExpandedState,
          UserSettings.TradingHubQuotesLastExpandedDate,
          UserSettings.ShowExpectedMoveOneOnWheel,
          UserSettings.ShowExpectedMoveTwoOnWheel,
          UserSettings.ValuesInPercentExpectedMove,
          UserSettings.ShowPowerXStrategyIndicatorWheel,
          UserSettings.ShowPowerXStrategyIndicatorBullCallSpread,
          UserSettings.TradierSelectedAccount,
          UserSettings.TradingPanelSelectedTab,
          UserSettings.TradingPanelOrdersTabStatusFilter,
          UserSettings.TradingPanelOrdersHistoryTabStatusFilter,
          UserSettings.TradingPanelPositionsTabSorting,
          UserSettings.TradingPanelOrdersTabSorting,
          UserSettings.TradingPanelOrdersHistoryTabSorting,
          UserSettings.TradingPanelOrderModalPinned,
          UserSettings.TradingPanelImportTradesListFilterValue,
          UserSettings.TradingPanelImportTradesListSortValue,
          UserSettings.ShowVolumeIndicatorOnStartForPowerX,
          UserSettings.ShowVolumeIndicatorOnStartForWheel,
          UserSettings.ShowVolumeIndicatorOnStartForBCS,
          UserSettings.PlayOpenMarketSound,
          UserSettings.PlayCloseMarketSound,
          UserSettings.ShowSmileyStatistics,
          UserSettings.AccessLevel,
          UserSettings.QuickLinks,
          UserSettings.WtfSymbol,
          UserSettings.WtfChartTab,
          UserSettings.WtfAccountSize,
          UserSettings.WtfNumberOfPositions,
          UserSettings.WtfScannerTab,
          UserSettings.IsWtfFiltersHidden,
          UserSettings.WtfScannerFilters,
          UserSettings.WtfScannerNewTableSort,
          UserSettings.WtfScannerOpenTableSort,
          UserSettings.WtfScannerOpenBelowEntryTableSort,
          UserSettings.WtfWatchlistTableSort,
          UserSettings.WtfDataWindowHeightPercent,
          UserSettings.WtfShowTradesOnChart,
          UserSettings.LoginNavigationSettings,
          UserSettings.HasEconomicPulseAccess,
          UserSettings.WtfShowEntryStopIndicators,
          UserSettings.ShowExpectedMoveOneOnWtf,
          UserSettings.ShowExpectedMoveTwoOnWtf,
          UserSettings.ValuesInPercentExpectedMoveKeyOnWtf,
          UserSettings.WtfShowProfitLoss,

          UserSettings.DividendsSymbol,
          UserSettings.DividendsScannerTab,
          UserSettings.DividendsChartTab,
          UserSettings.DividendsScannerFilters,
          UserSettings.DividendsScannerTableSort,
          UserSettings.IsDividendsFiltersHidden,
          UserSettings.ShowPowerXStrategyIndicatorDividends,
          UserSettings.LowestHighestCloseLineIndicatorDividends,
          UserSettings.LowestCloseLineIndicatorDividends,
          UserSettings.DividendsDataWindowHeightPercent,
          UserSettings.DividendsDataTableHeightPercent,

          UserSettings.DividendsStrategyChartTab,
          UserSettings.DividendsStrategySymbol,
          UserSettings.DividendsStrategyBuyingPower,
          UserSettings.DividendsStrategyNumberOfPositions,
          UserSettings.DividendsStrategyScannerFilters,
          UserSettings.IsDividendsStrategyFiltersHidden,
          UserSettings.DividendsStrategyDataWindowHeightPercent,

          UserSettings.DividendsStrategyShowTradesOnChart,
          UserSettings.DividendsStrategyShowEntryIndicator,
          UserSettings.DividendsStrategyShowProfitLoss,

          UserSettings.StockScreenerDetailsTab,
          UserSettings.StockScreenerSymbol,
          UserSettings.StockScreenerDetailsPanelWidth,
          UserSettings.StockScreenerSymbolDetailsPanelHeight,
          UserSettings.IsStockScreenerFiltersHidden,
          UserSettings.StockScreenerFilters,
          UserSettings.StockScreenerTableSizes,
          UserSettings.LowestHighestCloseLineIndicatorStockScreener,
          UserSettings.LowestCloseLineIndicatorStockScreener,
          UserSettings.ShowPowerXStrategyIndicatorStockScreener,
          UserSettings.ShowVolumeIndicatorOnStartForStockScreener,
          UserSettings.ShowExpectedMoveOneOnStockScreener,
          UserSettings.ShowExpectedMoveTwoOnStockScreener,
          UserSettings.ValuesInPercentExpectedMoveKeyOnStockScreener,

          UserSettings.ShortSellingStocksSymbol,
          UserSettings.ShortSellingStocksChartInterval,
          UserSettings.ShortSellingStocksDataWindowHeightPercent,
          UserSettings.ShortSellingStocksScannerTab,
          UserSettings.ShortSellingStocksScannerNewTableSort,
          UserSettings.ShortSellingStocksScannerOpenTableSort,
          UserSettings.ShortSellingStocksScannerAboveEntryTableSort,
          UserSettings.ShortSellingStocksScannerExitTableSort,
          UserSettings.ShortSellingStocksWatchlistTableSort,
          UserSettings.ShortSellingStocksScannerFilters,
          UserSettings.IsShortSellingStocksScannerHidden,
          UserSettings.ShortSellingStocksBuyingPower,
          UserSettings.ShortSellingStocksNumberOfPositions,
          UserSettings.ShortSellingStocksChartTab,
          UserSettings.ShowRobIndicatorsShortSellingStocks,

          UserSettings.ShortingScannerSymbol,
          UserSettings.ShortingScannerChartInterval,
          UserSettings.ShortingScannerDataWindowHeightPercent,
          UserSettings.ShortingScannerScannerTab,
          UserSettings.ShortingScannerScannerNewTableSort,
          UserSettings.ShortingScannerScannerOpenTableSort,
          UserSettings.ShortingScannerScannerAboveEntryTableSort,
          UserSettings.ShortingScannerScannerExitTableSort,
          UserSettings.ShortingScannerWatchlistTableSort,
          UserSettings.ShortingScannerScannerFilters,
          UserSettings.ShortingScannerBuyingPower,
          UserSettings.ShortingScannerNumberOfPositions,
          UserSettings.ShortingScannerChartTab,
          UserSettings.IsShortingScannerFilterHidden,
          UserSettings.ShowRobIndicatorsShortingScanner,
          UserSettings.ShowRockyAlways,
          UserSettings.ShortingScannerFiltersWidth,
          UserSettings.ShortingScannerDisplayHalts,
        ]),
        this.userSettingsService.get(),
        this.symbolsService.getAll(),
        this.tradingStrategiesService.getAll(),
        this.workingHoursService.get(),
        this.holidaysService.getAsync({
          from: moment.utc().add(-2, 'month').format(MomentDateTimeFormats.ServerDate),
          to: moment.utc().add(1, 'year').format(MomentDateTimeFormats.ServerDate)
        }),
        this.serverDataService.getAsInt(ServerSettings.HeatmapApiVersion),
        this.serverDataService.getAsInt(ServerSettings.ShowDividendsAnalysis),
        this.serverDataService.getAsInt(ServerSettings.ShowStockScreener),
        this.serverDataService.getAsInt(ServerSettings.ShowImportTrades),
        this.serverDataService.getAsInt(ServerSettings.ShowTradierImportTrades),
        this.serverDataService.getAsInt(ServerSettings.ShowVideoHub),
        this.serverDataService.getAsInt(ServerSettings.ShowRockyDataWindow),
        this.serverDataService.getAsInt(ServerSettings.ShowRockyEconomicCalendar),
        this.serverDataService.getAsInt(ServerSettings.ShowHeatmapDetailsDataWindow),
        this.serverDataService.getAsInt(ServerSettings.ShowDividendsStrategy),
        this.serverNotificationsSettingsService.getServerNotificationsSettings(),
      ]);

      // check isOpenOnStart setting and was it opened this day for trading-hub-modal
      // and open if it's true
      this.tradingHubService.checkAndOpenTradingHubModal();

      // get and set user`s available plugins
      const user = this.userDataService.getUser();
      this.observableService.availablePlugins.next(user?.addons ?? []);

      const defaultSymbol = allSymbols.find((s) => s.country_code === ExchangeCountriesCodes.US && s.symbol === defaultSymbolName);
      const defaultSecurityId = defaultSymbol ? defaultSymbol.security_id : allSymbols[0].security_id;

      const userDataMap = {};
      for (const setting of userData) {
        userDataMap[setting.key] = setting.value;
      }

      const userSettingsDefaults = [];
      const setDefaults = [];

      // Restore URL and activeTab - START
      const urlSegments = getUrlSegmentsByRoute(this.location.path(false))
      const tabFromLocation = getTabNameByRoute(urlSegments);

      const loginNavigationSettings = this.getJsonOrDefault(userDataMap[UserSettings.LoginNavigationSettings], null);
      const tabFromLoginSettings = loginNavigationSettings
        && loginNavigationSettings.controlValue === LoginNavigationControlValueEnum.SelectedPage
          ? loginNavigationSettings.selectControlValue.tabName
          : null;

      // can be taken from loginNavigationSettings if there is no relevant path
      const defaultInitialPage = TabNames.Wheel;

      // take tab based on current-location
      // if no tab from location - use priority: [defined tab from loginNavigationSettings] > [saved activeTab] > [default value]
      const activeTab = tabFromLocation ?? tabFromLoginSettings ?? userDataMap[UserSettings.ActiveTab] ?? defaultInitialPage;
      // Restore URL and activeTab - END

      let tradePosition = userDataMap[UserSettings.TradePosition];
      if (!tradePosition) {
        tradePosition = TradePositions.LongAndShort;
        userSettingsDefaults.push({ key: UserSettings.TradePosition, value: tradePosition });
      }

      let chartTab = userDataMap[UserSettings.ChartTab];
      if (!chartTab) {
        chartTab = ChartTabs.Chart;
        userSettingsDefaults.push({ key: UserSettings.ChartTab, value: chartTab });
      }

      let runScannerTradingStrategyId = parseInt(userDataMap[UserSettings.RunScannerTradingStrategyId], 10);
      if (isNaN(runScannerTradingStrategyId)) {
        runScannerTradingStrategyId = (await this.tradingStrategiesService.getDefault()).id;
        userSettingsDefaults.push({ key: UserSettings.RunScannerTradingStrategyId, value: runScannerTradingStrategyId });
      }

      let showEntryAndExitOption = userDataMap[UserSettings.ShowEntryAndExitOption];
      if (!showEntryAndExitOption) {
        showEntryAndExitOption = ShowEntryAndExitOptions.None;
        userSettingsDefaults.push({ key: UserSettings.ShowEntryAndExitOption, value: showEntryAndExitOption });
      }

      let showTradeOption = userDataMap[UserSettings.ShowTradeOption];
      if (!showTradeOption) {
        showTradeOption = ShowTradeOptions.None;
        userSettingsDefaults.push({ key: UserSettings.ShowTradeOption, value: showTradeOption });
      }

      let showTechnicalIndicators = userDataMap[UserSettings.ShowTechnicalIndicators];
      if (!showTechnicalIndicators) {
        showTechnicalIndicators = TechnicalIndicators.Show;
        userSettingsDefaults.push({ key: UserSettings.ShowTechnicalIndicators, value: showTechnicalIndicators });
      }

      let symbol = parseInt(userDataMap[UserSettings.Symbol], 10);
      if ((isNaN(symbol) || !(await this.symbolsService.getById(symbol))) && allSymbols.length) {
        symbol = defaultSecurityId;
        userSettingsDefaults.push({ key: UserSettings.Symbol, value: symbol });
      }

      let accountSize = parseFloat(userDataMap[UserSettings.AccountSize]);
      if (isNaN(accountSize)) {
        accountSize = 10000;
        userSettingsDefaults.push({ key: UserSettings.AccountSize, value: accountSize });
      }

      let accountRiskPercent = parseFloat(userDataMap[UserSettings.AccountRiskPercent]);
      if (isNaN(accountRiskPercent)) {
        accountRiskPercent = 2.0;
        userSettingsDefaults.push({ key: UserSettings.AccountRiskPercent, value: accountRiskPercent });
      }

      let accountRiskAmount = parseFloat(userDataMap[UserSettings.AccountRiskAmount]);
      if (isNaN(accountRiskAmount)) {
        accountRiskAmount = (accountSize * accountRiskPercent) / 100.0;
        userSettingsDefaults.push({ key: UserSettings.AccountRiskAmount, value: accountRiskAmount });
      }

      let joinCommunityBtnVisible = parseInt(userDataMap[UserSettings.JoinCommunityBtnVisible], 10);
      if (isNaN(joinCommunityBtnVisible)) {
        joinCommunityBtnVisible = 1;
        userSettingsDefaults.push({ key: UserSettings.JoinCommunityBtnVisible, value: joinCommunityBtnVisible });
      }

      let watchPxoTutorialsBtnVisible = parseInt(userDataMap[UserSettings.WatchPxoTutorialsBtnVisible], 10);
      if (isNaN(watchPxoTutorialsBtnVisible)) {
        watchPxoTutorialsBtnVisible = 1;
        userSettingsDefaults.push({ key: UserSettings.WatchPxoTutorialsBtnVisible, value: watchPxoTutorialsBtnVisible });
      }

      let selectedScannerTab = parseInt(userDataMap[UserSettings.SelectScannerTab], 10);
      if (isNaN(selectedScannerTab)) {
        selectedScannerTab = 1;
        userSettingsDefaults.push({ key: UserSettings.SelectScannerTab, value: selectedScannerTab });
      }

      let firstTradingStrategyId = parseInt(userDataMap[UserSettings.FirstTradingStrategyId], 10);
      let secondTradingStrategyId = parseInt(userDataMap[UserSettings.SecondTradingStrategyId], 10);
      let tradeReportTradingStrategyId = parseInt(userDataMap[UserSettings.TradeReportTradingStrategyId], 10);
      const initTradingStrategyResult = this.initializeTradingStrategies(tradingStrategies, userDataMap);

      if (firstTradingStrategyId !== initTradingStrategyResult.firstTradingStrategyId) {
        firstTradingStrategyId = initTradingStrategyResult.firstTradingStrategyId;
        userSettingsDefaults.push({ key: UserSettings.FirstTradingStrategyId, value: firstTradingStrategyId });
      }

      if (secondTradingStrategyId !== initTradingStrategyResult.secondTradingStrategyId) {
        secondTradingStrategyId = initTradingStrategyResult.secondTradingStrategyId;
        userSettingsDefaults.push({ key: UserSettings.SecondTradingStrategyId, value: secondTradingStrategyId });
      }

      if (tradeReportTradingStrategyId !== initTradingStrategyResult.tradeReportTradingStrategyId) {
        tradeReportTradingStrategyId = initTradingStrategyResult.tradeReportTradingStrategyId;
        userSettingsDefaults.push({ key: UserSettings.TradeReportTradingStrategyId, value: tradeReportTradingStrategyId });
      }

      const initializeThemePromise = this.initializeTheme(userSettings);
      if (initializeThemePromise) {
        setDefaults.push(initializeThemePromise);
      }

      let printOptions = this.getJsonOrDefault(userDataMap[UserSettings.PrintOptions]);
      if (!printOptions || !printOptions.length) {
        printOptions = DefaultPrintOptions;
        userSettingsDefaults.push({ key: UserSettings.PrintOptions, value: JSON.stringify(printOptions) });
      }

      let stockScreenerSymbolId = this.getNumberOrDefault(userDataMap[UserSettings.StockScreenerSymbol], null);
      const stockScreenerSymbol = stockScreenerSymbolId && await this.symbolsService.getById(stockScreenerSymbolId);
      const isStockScreenerSymbolValid = stockScreenerSymbol && stockScreenerSymbol.country_code === ExchangeCountriesCodes.US;
      if (!stockScreenerSymbol || !isStockScreenerSymbolValid) {
        const defaultStockScreenerSymbol = allSymbols.find((s) => {
          return s.country_code === ExchangeCountriesCodes.US && s.symbol === defaultSymbolName;
        });
        stockScreenerSymbolId = defaultStockScreenerSymbol ? defaultStockScreenerSymbol.security_id : allSymbols[0].security_id;
      }

      let showExpectedMoveOneOnStockScreener = parseInt(userDataMap[UserSettings.ShowExpectedMoveOneOnStockScreener], 10);
      if (isNaN(showExpectedMoveOneOnStockScreener)) {
        showExpectedMoveOneOnStockScreener = 1;
      }

      let showExpectedMoveTwoOnStockScreener = parseInt(userDataMap[UserSettings.ShowExpectedMoveTwoOnStockScreener], 10);
      if (isNaN(showExpectedMoveTwoOnStockScreener)) {
        showExpectedMoveTwoOnStockScreener = 0;
      }

      let valuesInPercentExpectedMoveKeyOnStockScreener = parseInt(
        userDataMap[UserSettings.ValuesInPercentExpectedMoveKeyOnStockScreener],
        10
      );
      if (isNaN(valuesInPercentExpectedMoveKeyOnStockScreener)) {
        valuesInPercentExpectedMoveKeyOnStockScreener = 0;
      }

      let wtfSymbolId = this.getNumberOrDefault(userDataMap[UserSettings.WtfSymbol], null);
      const wtfSymbol = wtfSymbolId && await this.symbolsService.getById(wtfSymbolId);
      const isWtfSymbolValid = wtfSymbol && wtfSymbol.country_code === ExchangeCountriesCodes.US;
      if (!wtfSymbol || !isWtfSymbolValid) {
        const defaultWtfSymbol = allSymbols.find((s) => s.country_code === ExchangeCountriesCodes.US && s.symbol === defaultSymbolName);
        wtfSymbolId = defaultWtfSymbol ? defaultWtfSymbol.security_id : allSymbols[0].security_id;
      }

      let wtfAccountSize = parseFloat(userDataMap[UserSettings.WtfAccountSize]);
      if (isNaN(wtfAccountSize)) {
        wtfAccountSize = 100000;
        userSettingsDefaults.push({ key: UserSettings.WtfAccountSize, value: wtfAccountSize });
      }

      let wtfNumberOfPositions = parseFloat(userDataMap[UserSettings.WtfNumberOfPositions]);
      if (isNaN(wtfNumberOfPositions)) {
        wtfNumberOfPositions = 5;
        userSettingsDefaults.push({ key: UserSettings.WtfNumberOfPositions, value: wtfNumberOfPositions });
      }

      let showExpectedMoveOneOnWtf = parseInt(userDataMap[UserSettings.ShowExpectedMoveOneOnWtf], 10);
      if (isNaN(showExpectedMoveOneOnWtf)) {
        showExpectedMoveOneOnWtf = 1;
      }

      let showExpectedMoveTwoOnWtf = parseInt(userDataMap[UserSettings.ShowExpectedMoveTwoOnWtf], 10);
      if (isNaN(showExpectedMoveTwoOnWtf)) {
        showExpectedMoveTwoOnWtf = 0;
      }

      let valuesInPercentExpectedMoveKeyOnWtf = parseInt(
        userDataMap[UserSettings.ValuesInPercentExpectedMoveKeyOnWtf],
        10
      );
      if (isNaN(valuesInPercentExpectedMoveKeyOnWtf)) {
        valuesInPercentExpectedMoveKeyOnWtf = 1;
      }

      let shortSellingStocksBuyingPower = parseFloat(userDataMap[UserSettings.ShortSellingStocksBuyingPower]);
      if (isNaN(shortSellingStocksBuyingPower)) {
        shortSellingStocksBuyingPower = 100000;
        userSettingsDefaults.push({ key: UserSettings.ShortSellingStocksBuyingPower, value: shortSellingStocksBuyingPower });
      }

      let shortSellingStocksNumberOfPositions = parseFloat(userDataMap[UserSettings.ShortSellingStocksNumberOfPositions]);
      if (isNaN(shortSellingStocksNumberOfPositions)) {
        shortSellingStocksNumberOfPositions = 5;
        userSettingsDefaults.push({ key: UserSettings.ShortSellingStocksNumberOfPositions, value: shortSellingStocksNumberOfPositions });
      }

      let shortingScannerBuyingPower = parseFloat(userDataMap[UserSettings.ShortingScannerBuyingPower]);
      if (isNaN(shortingScannerBuyingPower)) {
        shortingScannerBuyingPower = 100000;
        userSettingsDefaults.push({ key: UserSettings.ShortingScannerBuyingPower, value: shortingScannerBuyingPower });
      }

      let shortingScannerNumberOfPositions = parseFloat(userDataMap[UserSettings.ShortingScannerNumberOfPositions]);
      if (isNaN(shortingScannerNumberOfPositions)) {
        shortingScannerNumberOfPositions = 5;
        userSettingsDefaults.push({ key: UserSettings.ShortingScannerNumberOfPositions, value: shortingScannerNumberOfPositions });
      }

      if (userSettingsDefaults.length) {
        setDefaults.push(this.userDataService.setMultiple(userSettingsDefaults));
      }

      const watchlistSort = this.getJsonOrDefault(userDataMap[UserSettings.WatchlistSort]);
      this.observableService.watchlistSort.next(watchlistSort);
      this.localStorageService.set(UserSettings.WatchlistSort, watchlistSort);

      const scannerResultSort = this.getJsonOrDefault(userDataMap[UserSettings.ScannerResultSort], null);
      this.observableService.scannerResultSort.next(scannerResultSort);
      this.localStorageService.set(UserSettings.ScannerResultSort, scannerResultSort);

      // Wheel settings
      let wheelSymbol = parseInt(userDataMap[UserSettings.WheelSymbol], 10);
      if ((isNaN(wheelSymbol) || !(await this.symbolsService.getById(wheelSymbol))) && allSymbols.length) {
        const defaultWheelSymbol = allSymbols.find((s) => s.country_code === ExchangeCountriesCodes.US && s.symbol === defaultSymbolName);
        wheelSymbol = defaultWheelSymbol ? defaultWheelSymbol.security_id : allSymbols[0].security_id;
        userSettingsDefaults.push({ key: UserSettings.WheelSymbol, value: wheelSymbol });
      }

      let isWheelCalculator = userDataMap[UserSettings.IsWheelCalculator];
      if (isWheelCalculator === undefined) {
        isWheelCalculator = false;
        userSettingsDefaults.push({ key: UserSettings.IsWheelCalculator, value: isWheelCalculator });
      } else {
        isWheelCalculator = isWheelCalculator === '1';
      }

      let isWheelPremiums = userDataMap[UserSettings.IsWheelPremiums];
      if (isWheelPremiums === undefined) {
        isWheelPremiums = true;
        userSettingsDefaults.push({ key: UserSettings.IsWheelPremiums, value: isWheelPremiums });
      } else {
        isWheelPremiums = isWheelPremiums === '1';
      }

      let isWheelFiltersHidden = parseInt(userDataMap[UserSettings.IsWheelFiltersHidden], 10);
      if (isNaN(isWheelFiltersHidden)) {
        isWheelFiltersHidden = 0;
        userSettingsDefaults.push({ key: UserSettings.IsWheelFiltersHidden, value: isWheelFiltersHidden });
      }

      const wheelFiltersState = this.getJsonOrDefault(userDataMap[UserSettings.WheelFiltersState], {});
      userSettingsDefaults.push({ key: UserSettings.WheelFiltersState, value: JSON.stringify(wheelFiltersState) });

      const wheelFiltersWidth = this.getJsonOrDefault(userDataMap[UserSettings.WheelFiltersWidth]);
      userSettingsDefaults.push({ key: UserSettings.WheelFiltersWidth, value: JSON.stringify(wheelFiltersWidth) });

      let wheelFiltersHeight = parseFloat(userDataMap[UserSettings.WheelFiltersHeight]);

      if (isNaN(wheelFiltersHeight)) {
        wheelFiltersHeight = 50;
        userSettingsDefaults.push({ key: UserSettings.WheelFiltersHeight, value: wheelFiltersHeight });
      }

      this.observableService.wheelFiltersHeight.next(wheelFiltersHeight);

      let wheelScannerTab = parseInt(userDataMap[UserSettings.WheelScannerTab], 10);

      if (isNaN(wheelScannerTab) || (wheelScannerTab !== 0 && wheelScannerTab !== 1)) {
        wheelScannerTab = 0;
        userSettingsDefaults.push({ key: UserSettings.WheelScannerTab, value: wheelScannerTab });
      }

      let wheelTopTab = parseInt(userDataMap[UserSettings.WheelTopTab], 10);

      if (isNaN(wheelTopTab)) {
        wheelTopTab = 0;
        userSettingsDefaults.push({ key: UserSettings.WheelTopTab, value: wheelTopTab });
      }

      let wheelDataWindowSource = userDataMap[UserSettings.WheelDataWindowUpdateSource];
      if (!wheelDataWindowSource) {
        wheelDataWindowSource = DataWindowUpdateSource.ChartSearchBar;
        userSettingsDefaults.push({ key: UserSettings.WheelDataWindowUpdateSource, value: wheelDataWindowSource });
      }

      let wheelViewMode = parseInt(userDataMap[UserSettings.WheelViewMode], 10);
      if (isNaN(wheelViewMode)) {
        wheelViewMode = WheelViewModes.Tabs;
        userSettingsDefaults.push({ key: UserSettings.WheelViewMode, value: wheelViewMode });
      }

      let wheelChartSectionHeight = parseFloat(userDataMap[UserSettings.WheelChartSectionHeight]);
      if (isNaN(wheelChartSectionHeight)) {
        wheelChartSectionHeight = wheelChartSectionDefaultHeight;
        userSettingsDefaults.push({ key: UserSettings.WheelChartSectionHeight, value: wheelChartSectionHeight });
      }

      let lowestHighestCloseLineIndicatorWheel = userDataMap[UserSettings.LowestHighestCloseLineIndicatorWheel];
      if (!lowestHighestCloseLineIndicatorWheel) {
        lowestHighestCloseLineIndicatorWheel = LowestHighestCloseLineIndicatorOptions.Both;
        userSettingsDefaults.push({ key: UserSettings.LowestHighestCloseLineIndicatorWheel, value: lowestHighestCloseLineIndicatorWheel });
      }

      let lowestCloseLineIndicatorWheel = userDataMap[UserSettings.LowestCloseLineIndicatorWheel];
      if (!lowestCloseLineIndicatorWheel) {
        lowestCloseLineIndicatorWheel = LowestCloseLineIndicatorOptions.LowestLine;
        userSettingsDefaults.push({ key: UserSettings.LowestCloseLineIndicatorWheel, value: lowestCloseLineIndicatorWheel });
      }

      let lowestHighestCloseLineIndicatorBullCallSpread = userDataMap[UserSettings.LowestHighestCloseLineIndicatorBullCallSpread];
      if (!lowestHighestCloseLineIndicatorBullCallSpread) {
        lowestHighestCloseLineIndicatorBullCallSpread = LowestHighestCloseLineIndicatorOptions.Both;
        userSettingsDefaults.push({
          key: UserSettings.LowestHighestCloseLineIndicatorBullCallSpread,
          value: lowestHighestCloseLineIndicatorBullCallSpread
        });
      }

      let lowestCloseLineIndicatorBullCallSpread = userDataMap[UserSettings.LowestCloseLineIndicatorBullCallSpread];
      if (!lowestCloseLineIndicatorBullCallSpread) {
        lowestCloseLineIndicatorBullCallSpread = LowestCloseLineIndicatorOptions.LowestLine;
        userSettingsDefaults.push({
          key: UserSettings.LowestCloseLineIndicatorBullCallSpread,
          value: lowestCloseLineIndicatorBullCallSpread
        });
      }

      let lowestHighestCloseLineIndicatorStockScreener = userDataMap[UserSettings.LowestHighestCloseLineIndicatorStockScreener];
      if (!lowestHighestCloseLineIndicatorStockScreener) {
        lowestHighestCloseLineIndicatorStockScreener = LowestHighestCloseLineIndicatorOptions.Both;
        userSettingsDefaults.push({
          key: UserSettings.LowestHighestCloseLineIndicatorStockScreener,
          value: lowestHighestCloseLineIndicatorStockScreener
        });
      }

      let lowestCloseLineIndicatorStockScreener = userDataMap[UserSettings.LowestCloseLineIndicatorStockScreener];
      if (!lowestCloseLineIndicatorStockScreener) {
        lowestCloseLineIndicatorStockScreener = LowestCloseLineIndicatorOptions.LowestLine;
        userSettingsDefaults.push({
          key: UserSettings.LowestCloseLineIndicatorStockScreener,
          value: lowestCloseLineIndicatorStockScreener
        });
      }

      let showExpectedMoveOneOnWheel = parseInt(userDataMap[UserSettings.ShowExpectedMoveOneOnWheel], 10);
      if (isNaN(showExpectedMoveOneOnWheel)) {
        showExpectedMoveOneOnWheel = 1;
        userSettingsDefaults.push({ key: UserSettings.ShowExpectedMoveOneOnWheel, value: showExpectedMoveOneOnWheel });
      }

      let showExpectedMoveTwoOnWheel = parseInt(userDataMap[UserSettings.ShowExpectedMoveTwoOnWheel], 10);
      if (isNaN(showExpectedMoveTwoOnWheel)) {
        showExpectedMoveTwoOnWheel = 0;
        userSettingsDefaults.push({ key: UserSettings.ShowExpectedMoveTwoOnWheel, value: showExpectedMoveTwoOnWheel });
      }

      let valuesInPercentExpectedMove = userDataMap[UserSettings.ValuesInPercentExpectedMove];
      if (isNaN(valuesInPercentExpectedMove)) {
        valuesInPercentExpectedMove = 1;
        userSettingsDefaults.push({ key: UserSettings.ValuesInPercentExpectedMove, value: valuesInPercentExpectedMove });
      }

      let tradingPanelSelectedTab = parseInt(userDataMap[UserSettings.TradingPanelSelectedTab], 10);
      if (isNaN(tradingPanelSelectedTab)) {
        tradingPanelSelectedTab = 0;
        userSettingsDefaults.push({ key: UserSettings.TradingPanelSelectedTab, value: tradingPanelSelectedTab });
      }

      const wheelScannerSortAggressive = this.getJsonOrDefault(userDataMap[UserSettings.WheelScannerSortAggressive]);
      const wheelScannerSortConservative = this.getJsonOrDefault(userDataMap[UserSettings.WheelScannerSortConservative]);
      const wheelScannerSortAll = this.getJsonOrDefault(userDataMap[UserSettings.WheelScannerSortAll]);

      if (userDataMap[UserSettings.WheelWatchlistSort]) {
        try {
          const wheelWatchlistSort = JSON.parse(userDataMap[UserSettings.WheelWatchlistSort]);

          this.observableService.wheelWatchlistSort.next(wheelWatchlistSort);
          this.localStorageService.set(UserSettings.WheelWatchlistSort, wheelWatchlistSort);
        } catch (error) {
          this.observableService.wheelWatchlistSort.next(null);
        }
      }

      let wheelROILowerBound = parseInt(userDataMap[UserSettings.WheelROILowerBound], 10);
      if (isNaN(wheelROILowerBound)) {
        wheelROILowerBound = WheelROIDefaultRange.Lower;
        userSettingsDefaults.push({ key: UserSettings.WheelROILowerBound, value: wheelROILowerBound });
      }

      let wheelROIUpperBound = parseInt(userDataMap[UserSettings.WheelROIUpperBound], 10);
      if (isNaN(wheelROIUpperBound)) {
        wheelROIUpperBound = WheelROIDefaultRange.Upper;
        userSettingsDefaults.push({ key: UserSettings.WheelROIUpperBound, value: wheelROIUpperBound });
      }

      let holidaysCountry = userDataMap[UserSettings.HolidaysCountry];
      if (holidaysCountry === undefined) {
        holidaysCountry = Countries.USA; // default value
      }

      // Earnings Calendar settings
      let earningsCalendarSymbol = parseInt(userDataMap[UserSettings.EarningsCalendarSymbol], 10);
      if ((isNaN(earningsCalendarSymbol) || !(await this.symbolsService.getById(earningsCalendarSymbol))) && allSymbols.length) {
        const defaultSymbol = allSymbols.find((s) => s.country_code === ExchangeCountriesCodes.US && s.symbol === defaultSymbolName);
        earningsCalendarSymbol = defaultSymbol ? defaultSymbol.security_id : allSymbols[0].security_id;
        userSettingsDefaults.push({ key: UserSettings.EarningsCalendarSymbol, value: earningsCalendarSymbol });
      }

      let earningsCalendarTab = parseInt(userDataMap[UserSettings.EarningsCalendarTab], 10);
      if (isNaN(earningsCalendarTab) || (earningsCalendarTab !== 0 && earningsCalendarTab !== 1)) {
        earningsCalendarTab = 0;
        userSettingsDefaults.push({ key: UserSettings.EarningsCalendarTab, value: earningsCalendarTab });
      }

      const earningsFilterValue = userDataMap[UserSettings.EarningsFilterValue];

      let bullCallSpreadSymbol = parseInt(userDataMap[UserSettings.BullCallSpreadSymbol], 10);
      if ((isNaN(bullCallSpreadSymbol) || !(await this.symbolsService.getById(bullCallSpreadSymbol))) && allSymbols.length) {
        const defaultSymbol = allSymbols.find((s) => s.country_code === ExchangeCountriesCodes.US && s.symbol === defaultSymbolName);
        bullCallSpreadSymbol = defaultSymbol ? defaultSymbol.security_id : allSymbols[0].security_id;
        userSettingsDefaults.push({ key: UserSettings.BullCallSpreadSymbol, value: bullCallSpreadSymbol });
      }

      this.observableService.bullCallSpreadSymbol.next(bullCallSpreadSymbol);
      this.localStorageService.set(UserSettings.BullCallSpreadSymbol, bullCallSpreadSymbol);

      const bullCallSpreadRecentSymbols = this.getJsonOrDefault(userDataMap[UserSettings.BullCallSpreadRecentSymbols], []);
      this.observableService.bullCallSpreadSymbols.next(bullCallSpreadRecentSymbols);
      this.localStorageService.set(UserSettings.BullCallSpreadRecentSymbols, bullCallSpreadRecentSymbols);

      let bullCallSpreadActiveTab = parseInt(userDataMap[UserSettings.BullCallSpreadTab], 10);
      if (isNaN(bullCallSpreadActiveTab) || (bullCallSpreadActiveTab !== 0 && bullCallSpreadActiveTab !== 1)) {
        bullCallSpreadActiveTab = 0;
        userSettingsDefaults.push({ key: UserSettings.BullCallSpreadTab, value: bullCallSpreadActiveTab });
      }
      this.localStorageService.set(UserSettings.BullCallSpreadTab, bullCallSpreadActiveTab);
      this.observableService.bullCallSpreadTab.next(bullCallSpreadActiveTab);

      // it returns "0" or "1" instead of boolean
      let bullCallSpreadIsFiltersRolledUp = Boolean(parseInt(userDataMap[UserSettings.BullCallSpreadIsFiltersRolledUp], 10));

      if (userDataMap[UserSettings.BullCallSpreadIsFiltersRolledUp] === null
        || userDataMap[UserSettings.BullCallSpreadIsFiltersRolledUp] === undefined
      ) {
        bullCallSpreadIsFiltersRolledUp = false;
        userSettingsDefaults.push({ key: UserSettings.BullCallSpreadIsFiltersRolledUp, value: bullCallSpreadIsFiltersRolledUp });
      }
      this.localStorageService.set(UserSettings.BullCallSpreadIsFiltersRolledUp, bullCallSpreadIsFiltersRolledUp);
      this.observableService.bullCallSpreadIsFiltersRolledUp.next(bullCallSpreadIsFiltersRolledUp);

      this.observableService.bullCallSpreadSelectedDropPercentOptions.next(
        this.getJsonOrDefault(userDataMap[UserSettings.BullCallSpreadSelectedDropPercentOptions], [])
      );

      let bullCallSpreadDetailsSectionWidth = parseFloat(userDataMap[UserSettings.BullCallSpreadDetailsSectionWidth]);
      if (isNaN(bullCallSpreadDetailsSectionWidth)) {
        bullCallSpreadDetailsSectionWidth = null;
        userSettingsDefaults.push({ key: UserSettings.BullCallSpreadTab, value: bullCallSpreadActiveTab });
      }
      this.localStorageService.set(UserSettings.BullCallSpreadDetailsSectionWidth, bullCallSpreadDetailsSectionWidth);
      this.observableService.bullCallSpreadDetailsSectionWidth.next(bullCallSpreadDetailsSectionWidth);

      // Trading Hub
      let tradingHubLastOpenDate = parseInt(userDataMap[UserSettings.TradingHubLastOpenDate], 10);
      if (isNaN(earningsCalendarSymbol)) {
        tradingHubLastOpenDate = null;
      }
      this.localStorageService.set(UserSettings.TradingHubLastOpenDate, tradingHubLastOpenDate);
      this.observableService.tradingHubLastOpenDate.next(tradingHubLastOpenDate);

      // it returns "0" or "1" instead of boolean
      let preTradingChecklistOpenOnAppStart = Boolean(parseInt(userDataMap[UserSettings.PreTradingChecklistOpenOnAppStart], 10));

      if (userDataMap[UserSettings.PreTradingChecklistOpenOnAppStart] === null
        || userDataMap[UserSettings.PreTradingChecklistOpenOnAppStart] === undefined
      ) {
        preTradingChecklistOpenOnAppStart = true;
        userSettingsDefaults.push({ key: UserSettings.PreTradingChecklistOpenOnAppStart, value: preTradingChecklistOpenOnAppStart });
      }
      this.localStorageService.set(UserSettings.PreTradingChecklistOpenOnAppStart, preTradingChecklistOpenOnAppStart);
      this.observableService.preTradingChecklistOpenOnAppStart.next(preTradingChecklistOpenOnAppStart);

      const preTradingChecklistTasks = this.getJsonOrDefault(userDataMap[UserSettings.PreTradingChecklistTasks], null);
      this.observableService.preTradingChecklistTasks.next(preTradingChecklistTasks);
      this.localStorageService.set(UserSettings.PreTradingChecklistTasks, preTradingChecklistTasks);

      let tradingHubTab = userDataMap[UserSettings.TradingHubTab];
      if (!tradingHubTab) {
        tradingHubTab = TradingHubTabs.Checklist;
        userSettingsDefaults.push({ key: UserSettings.TradingHubTab, value: tradingHubTab });
      }
      this.localStorageService.set(UserSettings.TradingHubTab, tradingHubTab);
      this.observableService.tradingHubTab.next(tradingHubTab);

      const tradingHubPlans = this.getJsonOrDefault(userDataMap[UserSettings.TradingHubPlans], []);
      this.observableService.tradingHubPlans.next(tradingHubPlans);
      this.localStorageService.set(UserSettings.TradingHubPlans, tradingHubPlans);

      let tradingHubPlansTab = parseInt(userDataMap[UserSettings.TradingHubPlansTab], 10);
      if (isNaN(tradingHubPlansTab)) {
        tradingHubPlansTab = 0;
        userSettingsDefaults.push({ key: UserSettings.TradingHubPlansTab, value: tradingHubPlansTab });
      }
      this.localStorageService.set(UserSettings.TradingHubPlansTab, tradingHubPlansTab);
      this.observableService.tradingHubPlansTab.next(tradingHubPlansTab);

      this.observableService.tradingHubQuotesExpandedState.next(
        this.getJsonOrDefault(userDataMap[UserSettings.TradingHubQuotesExpandedState], true)
      );

      this.observableService.tradingHubQuotesLastExpandedDate.next(
        this.getJsonOrDefault(userDataMap[UserSettings.TradingHubQuotesLastExpandedDate], null)
      );

      const showVolumeIndicatorOnStartForPowerX = this.parseAndTransformToBoolean(
        userDataMap[UserSettings.ShowVolumeIndicatorOnStartForPowerX],
        true,
      );
      const showVolumeIndicatorOnStartForWheel = this.parseAndTransformToBoolean(
        userDataMap[UserSettings.ShowVolumeIndicatorOnStartForWheel],
        true,
      );
      const showVolumeIndicatorOnStartForBCS = this.parseAndTransformToBoolean(
        userDataMap[UserSettings.ShowVolumeIndicatorOnStartForBCS],
        true,
      );
      const showVolumeIndicatorOnStartForStockScreener = this.parseAndTransformToBoolean(
        userDataMap[UserSettings.ShowVolumeIndicatorOnStartForStockScreener],
        true,
      );

      const playOpenMarketSound = this.parseAndTransformToBoolean(
        userDataMap[UserSettings.PlayOpenMarketSound],
        false,
      );
      const playCloseMarketSound = this.parseAndTransformToBoolean(
        userDataMap[UserSettings.PlayCloseMarketSound],
        false,
      );

      // Dividends - START

      let dividendsSymbolId = this.getNumberOrDefault(userDataMap[UserSettings.DividendsSymbol], null);
      const dividendsSymbol = dividendsSymbolId && await this.symbolsService.getById(dividendsSymbolId);
      const isDividendsSymbolValid = dividendsSymbol && dividendsSymbol.country_code === ExchangeCountriesCodes.US;
      if (!dividendsSymbol || !isDividendsSymbolValid) {
        const defaultDividendsSymbol = allSymbols.find((s) => (
          s.country_code === ExchangeCountriesCodes.US && s.symbol === defaultSymbolName
        ));
        dividendsSymbolId = defaultDividendsSymbol ? defaultDividendsSymbol.security_id : allSymbols[0].security_id;
      }

      // Dividends - END

      // Short selling stocks
      let shortSellingStocksSymbolId = this.getNumberOrDefault(userDataMap[UserSettings.ShortSellingStocksSymbol], null);
      const shortSellingStocksSymbol = shortSellingStocksSymbolId && await this.symbolsService.getById(shortSellingStocksSymbolId);
      const isShortSellingStocksSymbolValid = shortSellingStocksSymbol
        && shortSellingStocksSymbol?.country_code === ExchangeCountriesCodes.US;

      if (!shortSellingStocksSymbol || !isShortSellingStocksSymbolValid) {
        const defaultShortSellingStocksSymbol = allSymbols.find((s) => {
          return s.country_code === ExchangeCountriesCodes.US && s.symbol === defaultSymbolName;
        });
        shortSellingStocksSymbolId = defaultShortSellingStocksSymbol
          ? defaultShortSellingStocksSymbol.security_id
          : allSymbols[0].security_id;
      }

      const shortSellingStocksChartInterval = userDataMap[UserSettings.ShortSellingStocksChartInterval] || ChartIntervals.Daily;

      // Shorting Scanner
      let shortingScannerSymbolId = this.getNumberOrDefault(userDataMap[UserSettings.ShortingScannerSymbol], null);
      const shortingScannerSymbol = shortingScannerSymbolId && await this.symbolsService.getById(shortingScannerSymbolId);
      const isShortingScannerSymbolValid = shortingScannerSymbol?.country_code === ExchangeCountriesCodes.US;

      if (!shortingScannerSymbol || !isShortingScannerSymbolValid) {
        const defaultShortingScannerSymbol = allSymbols.find((s) => {
          return s.country_code === ExchangeCountriesCodes.US && s.symbol === defaultSymbolName;
        });
        shortingScannerSymbolId = defaultShortingScannerSymbol
          ? defaultShortingScannerSymbol.security_id
          : allSymbols[0].security_id;
      }

      const shortingScannerChartInterval = userDataMap[UserSettings.ShortingScannerChartInterval] || ChartIntervals.Daily;


      if (setDefaults.length) {
        await Promise.all(setDefaults);
      }

      await this.initializeApplicationVersion();

      if (userDataMap[UserSettings.AccessLevel]) {
        this.observableService.accessLevel.next(userDataMap[UserSettings.AccessLevel]);
      }

      this.observableService.isDeveloperAccount.next(this.parseAndTransformToBoolean(userDataMap[UserSettings.IsDeveloperAccount], false));
      this.observableService.activeTab.next(activeTab);
      this.observableService.tradePosition.next(tradePosition);
      this.observableService.chartTab.next(chartTab);
      this.observableService.runScannerTradingStrategyId.next(runScannerTradingStrategyId);
      this.observableService.showEntryAndExitOption.next(showEntryAndExitOption);
      this.observableService.showTradeOption.next(showTradeOption);
      this.observableService.showTechnicalIndicators.next(showTechnicalIndicators);
      this.observableService.symbol.next(symbol);
      this.observableService.accountSize.next(accountSize);
      this.observableService.accountRiskPercent.next(accountRiskPercent);
      this.observableService.accountRiskAmount.next(accountRiskAmount);
      this.observableService.joinCommunityBtnVisible.next(joinCommunityBtnVisible);
      this.observableService.watchPxoTutorialsBtnVisible.next(watchPxoTutorialsBtnVisible);
      this.observableService.firstTradingStrategyId.next(firstTradingStrategyId);
      this.observableService.secondTradingStrategyId.next(secondTradingStrategyId);
      this.observableService.tradeReportTradingStrategyId.next(tradeReportTradingStrategyId);
      this.observableService.printOptions$.next(printOptions);
      this.observableService.quickLinks.next(this.getJsonOrDefault(userDataMap[UserSettings.QuickLinks], []));
      this.observableService.loginNavigationSettings
        .next(this.getJsonOrDefault(userDataMap[UserSettings.LoginNavigationSettings], {}));

      this.observableService.selectScannerTab.next(selectedScannerTab);
      this.observableService.wheelSymbol.next(wheelSymbol);
      this.observableService.isWheelCalculator.next(isWheelCalculator);
      this.observableService.isWheelPremiums.next(isWheelPremiums);
      this.observableService.wheelFiltersWidth.next(wheelFiltersWidth);
      this.observableService.wheelFiltersHeight.next(wheelFiltersHeight);

      this.observableService.wheelScannerTab.next(wheelScannerTab);
      this.observableService.wheelScannerSortAggressive.next(wheelScannerSortAggressive);
      this.observableService.wheelScannerSortConservative.next(wheelScannerSortConservative);
      this.observableService.wheelScannerSortAll.next(wheelScannerSortAll);
      this.observableService.wheelTopTab.next(wheelTopTab);
      this.observableService.isWheelFiltersHidden.next(isWheelFiltersHidden);
      this.observableService.wheelFiltersState.next(wheelFiltersState);
      this.observableService.wheelDataWindowUpdateSource.next(wheelDataWindowSource);
      this.observableService.wheelViewMode.next(wheelViewMode);
      this.observableService.wheelChartSectionHeight.next(wheelChartSectionHeight);
      this.observableService.lowestHighestCloseLineIndicatorWheel.next(lowestHighestCloseLineIndicatorWheel);
      this.observableService.lowestCloseLineIndicatorWheel.next(lowestCloseLineIndicatorWheel);
      this.observableService.lowestHighestCloseLineIndicatorBullCallSpread.next(lowestHighestCloseLineIndicatorBullCallSpread);
      this.observableService.lowestCloseLineIndicatorBullCallSpread.next(lowestCloseLineIndicatorBullCallSpread);
      this.observableService.lowestHighestCloseLineIndicatorStockScreener.next(lowestHighestCloseLineIndicatorStockScreener);
      this.observableService.lowestCloseLineIndicatorStockScreener.next(lowestCloseLineIndicatorStockScreener);
      this.observableService.showExpectedMoveOneOnWheel.next(showExpectedMoveOneOnWheel);
      this.observableService.showExpectedMoveTwoOnWheel.next(showExpectedMoveTwoOnWheel);
      this.observableService.valuesInPercentExpectedMove.next(valuesInPercentExpectedMove);
      this.observableService.showPowerXStrategyIndicatorWheel.next(this.parseAndTransformToBoolean(userDataMap[UserSettings.ShowPowerXStrategyIndicatorWheel], true));
      this.observableService.showPowerXStrategyIndicatorBullCallSpread.next(this.parseAndTransformToBoolean(userDataMap[UserSettings.ShowPowerXStrategyIndicatorBullCallSpread], true));
      this.observableService.showPowerXStrategyIndicatorStockScreener.next(this.parseAndTransformToBoolean(userDataMap[UserSettings.ShowPowerXStrategyIndicatorStockScreener], true));

      this.observableService.tradierSelectedAccount.next(userDataMap[UserSettings.TradierSelectedAccount] || '');
      this.observableService.tradingPanelSelectedTab.next(tradingPanelSelectedTab);
      this.observableService.tradingPanelOrdersTabStatusFilter.next(userDataMap[UserSettings.TradingPanelOrdersTabStatusFilter] || OrderFilterStatus.All);
      this.observableService.tradingPanelOrdersHistoryTabStatusFilter.next(userDataMap[UserSettings.TradingPanelOrdersHistoryTabStatusFilter] || OrderFilterStatus.All);
      this.observableService.tradingPanelPositionsTabSorting.next(userDataMap[UserSettings.TradingPanelPositionsTabSorting]
        ? JSON.parse(userDataMap[UserSettings.TradingPanelPositionsTabSorting])
        : null);
      this.observableService.tradingPanelOrdersTabSorting.next(userDataMap[UserSettings.TradingPanelOrdersTabSorting]
        ? JSON.parse(userDataMap[UserSettings.TradingPanelOrdersTabSorting])
        : null);
      this.observableService.tradingPanelOrdersHistoryTabSorting.next(userDataMap[UserSettings.TradingPanelOrdersHistoryTabSorting]
        ? JSON.parse(userDataMap[UserSettings.TradingPanelOrdersHistoryTabSorting])
        : null);
      this.observableService.tradingPanelOrderModalPinned.next(this.parseAndTransformToBoolean(userDataMap[UserSettings.TradingPanelOrderModalPinned], false));
      this.observableService.tradingPanelImportTradesListFilterValue
        .next(this.getJsonOrDefault(userDataMap[UserSettings.TradingPanelImportTradesListFilterValue], tradesListFilterFormDefault));
      this.observableService.tradingPanelImportTradesListSortValue
        .next(this.getJsonOrDefault(userDataMap[UserSettings.TradingPanelImportTradesListSortValue], importTradesListSortDefault));

      this.observableService.holidaysCountry.next(holidaysCountry);
      this.observableService.tradingLogContainerTab.next(userDataMap[UserSettings.TradingLogContainerTab] || TradingLogContainerTab.Trades);
      this.observableService.tradingLogTradesTab.next(userDataMap[UserSettings.TradingLogTradesTab] || TradingLogGroupType.Active);
      this.observableService.tradingLogTradesFilterValue
        .next(this.getJsonOrDefault(userDataMap[UserSettings.TradingLogTradesFilterValue], defaultTLTradesFilterValue));
      this.observableService.tradingLogTradesSortingState
        .next(this.getJsonOrDefault(userDataMap[UserSettings.TradingLogTradesSortingState], null));
      this.observableService.tradingLogFirstTimeGroupAdded.next(!!userDataMap[UserSettings.TradingLogFirstTimeGroupAdded]);
      this.observableService.tradingLogTradesInfoColumnsStateValue
        .next(this.getJsonOrDefault(userDataMap[UserSettings.TradingLogTradesInfoColumnsStateValue], []));
      this.observableService.tradingLogTradesInfoColumnsShowDevFieldsValue
        .next(userDataMap[UserSettings.TradingLogTradesInfoColumnsShowDevFieldsValue] === '1');

      const tradingLogTradesShowTimeColumnData = userDataMap[UserSettings.TradingLogTradesShowTimeColumn];
      this.observableService.tradingLogTradesShowTimeColumn.next(!tradingLogTradesShowTimeColumnData || tradingLogTradesShowTimeColumnData === '1'); // default value is true

      this.observableService.tradingLogDashboardDatesFilterValue
        .next(this.getJsonOrDefault(userDataMap[UserSettings.TradingLogDashboardDatesFilterValue], getDefaultDateRangeFilterValue()));
      this.observableService.tradingLogDashboardSymbolFilterValue
        .next(userDataMap[UserSettings.TradingLogDashboardSymbolFilterValue] || null);
      this.observableService.tradingLogDashboardAccountsFilterValues
        .next(userDataMap[UserSettings.TradingLogDashboardAccountsFilterValues] || null);
      this.observableService.tradingLogDashboardStrategiesFilterValues
        .next(userDataMap[UserSettings.TradingLogDashboardStrategiesFilterValues] || null);
      this.observableService.tradingLogDashboardOpenPositionsExpandedValue
        .next(userDataMap[UserSettings.TradingLogDashboardOpenPositionsExpandedValues] === '1');
      this.observableService.tradingLogDashboardTab
        .next(userDataMap[UserSettings.TradingLogDashboardTab] || TradingLogDashboardTabs.Summary);
      this.observableService.tradingLogDashboardWeekdayTradesTab
        .next(userDataMap[UserSettings.TradingLogDashboardWeekdayTradesTab] || WeekdayTradesTabs.Graph);
      this.observableService.tradingLogSymbolSummaryPanelState
        .next(this.getJsonOrDefault(userDataMap[UserSettings.TradingLogSymbolSummaryPanelState], {}));
      this.observableService.tradingLogSelectedInputDateFormat
        .next(userDataMap[UserSettings.TradingLogSelectedInputDateFormat] || TradingLogInputDateFormats.MonthDayYear);
      this.observableService.tradingLogDashboardOptionsSymbolsTableSortState
        .next(this.getJsonOrDefault(userDataMap[UserSettings.TradingLogDashboardOptionsSymbolsTableSortState], DefaultOptionsSymbolsTableSortState));
      this.observableService.tradingLogDashboardStocksSymbolsTableSortState
        .next(this.getJsonOrDefault(userDataMap[UserSettings.TradingLogDashboardStocksSymbolsTableSortState], DefaultStocksSymbolsTableSortState));
      this.observableService.tradingLogDashboardMetricDetailsModalPageSize
        .next(this.getJsonOrDefault(userDataMap[UserSettings.TradingLogDashboardMetricDetailsModalPageSize], TLDashboardMetricDetailsModalPageSizeOptions[0]));

      /* Import trades page*/
      this.observableService.importPageTradesListFilterValue
        .next(this.getJsonOrDefault(userDataMap[UserSettings.ImportPageTradesListFilterValue], tradesListFilterFormNoImportedTradesDefault));
      this.observableService.importPageTradesListSortValue
        .next(this.getJsonOrDefault(userDataMap[UserSettings.ImportPageTradesListSortValue], importTradesListSortDefault));

      this.observableService.earningsCalendarSymbol.next(earningsCalendarSymbol);
      this.observableService.earningsCalendarTab.next(earningsCalendarTab);
      this.observableService.earningsFilterValue.next(earningsFilterValue);

      this.observableService.showVolumeIndicatorOnStartForPowerX.next(showVolumeIndicatorOnStartForPowerX);
      this.observableService.showVolumeIndicatorOnStartForWheel.next(showVolumeIndicatorOnStartForWheel);
      this.observableService.showVolumeIndicatorOnStartForBCS.next(showVolumeIndicatorOnStartForBCS);
      this.observableService.showVolumeIndicatorOnStartForStockScreener.next(showVolumeIndicatorOnStartForStockScreener);

      this.observableService.playOpenMarketSound.next(playOpenMarketSound);
      this.observableService.playCloseMarketSound.next(playCloseMarketSound);
      this.observableService.showSmileyStatistics.next(
        this.parseAndTransformToBoolean(userDataMap[UserSettings.ShowSmileyStatistics], true)
      );

      this.observableService.stockScreenerSymbol.next(stockScreenerSymbolId);
      this.observableService.stockScreenerChartTab.next(
        this.getNumberOrDefault(userDataMap[UserSettings.StockScreenerChartTab], 0)
      );
      this.observableService.stockScreenerDetailsTab.next(
        this.getNumberOrDefault(userDataMap[UserSettings.StockScreenerDetailsTab], DEFAULT_STOCK_SCREENER_DETAILS_TAB_INDEX)
      );
      this.observableService.stockScreenerDetailsPanelWidth.next(
        this.getNumberOrDefault(userDataMap[UserSettings.StockScreenerDetailsPanelWidth], null)
      );
      this.observableService.stockScreenerSymbolDetailsPanelHeight.next(
        this.getNumberOrDefault(userDataMap[UserSettings.StockScreenerSymbolDetailsPanelHeight], null)
      );
      this.observableService.isStockScreenerFiltersHidden.next(
        this.parseAndTransformToBoolean(userDataMap[UserSettings.IsStockScreenerFiltersHidden], DEFAULT_IS_FILTERS_HIDDEN)
      );
      this.observableService.stockScreenerFilters.next(
        this.getJsonOrDefault(userDataMap[UserSettings.StockScreenerFilters], DEFAULT_STOCK_SCREENER_FILTERS_STATE)
      );
      this.observableService.stockScreenerTableSizes.next(
        this.getJsonOrDefault(userDataMap[UserSettings.StockScreenerTableSizes])
      );

      this.observableService.showExpectedMoveOneOnStockScreener.next(showExpectedMoveOneOnStockScreener);
      this.observableService.showExpectedMoveTwoOnStockScreener.next(showExpectedMoveTwoOnStockScreener);
      this.observableService.valuesInPercentExpectedMoveKeyOnStockScreener.next(valuesInPercentExpectedMoveKeyOnStockScreener);

      this.observableService.wtfSymbol.next(wtfSymbolId);
      this.observableService.wtfChartTab.next(userDataMap[UserSettings.WtfChartTab] || ChartTabs.Chart);
      this.observableService.wtfAccountSize.next(wtfAccountSize);
      this.observableService.wtfNumberOfPositions.next(wtfNumberOfPositions);
      this.observableService.wtfScannerTab.next(
        this.getNumberOrDefault(userDataMap[UserSettings.WtfScannerTab], DEFAULT_WTF_SCANNER_TAB_INDEX)
      );
      this.observableService.isWtfFiltersHidden.next(
        this.parseAndTransformToBoolean(userDataMap[UserSettings.IsWtfFiltersHidden], DEFAULT_IS_FILTERS_HIDDEN)
      );
      this.observableService.wtfScannerFilters.next(
        this.getJsonOrDefault(userDataMap[UserSettings.WtfScannerFilters], DEFAULT_WTF_FILTERS_STATE)
      );
      this.observableService.wtfScannerNewTableSort.next(
        this.getJsonOrDefault(userDataMap[UserSettings.WtfScannerNewTableSort], DEFAULT_SCANNER_TABLE_SORT)
      );
      this.observableService.wtfScannerOpenTableSort.next(
        this.getJsonOrDefault(userDataMap[UserSettings.WtfScannerOpenTableSort], DEFAULT_SCANNER_TABLE_SORT)
      );
      this.observableService.wtfScannerOpenBelowEntryTableSort.next(
        this.getJsonOrDefault(userDataMap[UserSettings.WtfScannerOpenBelowEntryTableSort], DEFAULT_SCANNER_TABLE_SORT)
      );
      this.observableService.wtfWatchlistTableSort.next(
        this.getJsonOrDefault(userDataMap[UserSettings.WtfWatchlistTableSort], DEFAULT_SCANNER_TABLE_SORT)
      );

      this.observableService.wtfDataWindowHeightPercent.next(
        this.getNumberOrDefault(userDataMap[UserSettings.WtfDataWindowHeightPercent], null)
      );

      this.observableService.wtfShowTradesOnChart.next(
        this.parseAndTransformToBoolean(userDataMap[UserSettings.WtfShowTradesOnChart], true)
      );

      this.observableService.wtfShowEntryStopIndicators.next(
        userDataMap[UserSettings.WtfShowEntryStopIndicators] ?? EntryStopIndicatorsOptions.Both
      );

      this.observableService.showExpectedMoveOneOnWtf.next(showExpectedMoveOneOnWtf);
      this.observableService.showExpectedMoveTwoOnWtf.next(showExpectedMoveTwoOnWtf);
      this.observableService.valuesInPercentExpectedMoveKeyOnWtf.next(valuesInPercentExpectedMoveKeyOnWtf);

      this.observableService.hasEconomicPulseAccess.next(
        this.parseAndTransformToBoolean(userDataMap[UserSettings.HasEconomicPulseAccess], false)
      );

      let wtfShowProfitLoss = parseInt(userDataMap[UserSettings.WtfShowProfitLoss], 10);
      if (isNaN(wtfShowProfitLoss)) {
        wtfShowProfitLoss = 1;
      }
      this.observableService.wtfShowProfitLoss.next(
        !!wtfShowProfitLoss
      );

      // Dividends - START

      this.observableService.dividendsSymbol.next(dividendsSymbolId);
      this.observableService.dividendsScannerTab.next(this.getNumberOrDefault(userDataMap[UserSettings.DividendsScannerTab], 0));
      this.observableService.dividendsChartTab.next(this.getNumberOrDefault(userDataMap[UserSettings.DividendsChartTab], 1));
      this.observableService.dividendsScannerFilters.next(
        this.getJsonOrDefault(userDataMap[UserSettings.DividendsScannerFilters], DEFAULT_DIVIDENDS_FILTERS_STATE)
      );
      this.observableService.dividendsScannerTableSort.next(
        this.getJsonOrDefault(userDataMap[UserSettings.DividendsScannerTableSort], DEFAULT_SCANNER_TABLE_SORT)
      );
      this.observableService.isDividendsFiltersHidden.next(
        this.parseAndTransformToBoolean(userDataMap[UserSettings.IsDividendsFiltersHidden], DEFAULT_IS_DIVIDENDS_FILTERS_HIDDEN)
      );
      this.observableService.showPowerXStrategyIndicatorDividends.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShowPowerXStrategyIndicatorDividends], false)
      );
      this.observableService.lowestHighestCloseLineIndicatorDividends.next(
        this.getJsonOrDefault(
          userDataMap[UserSettings.LowestHighestCloseLineIndicatorDividends],
          LowestHighestCloseLineIndicatorOptions.LowestLine
        )
      );
      this.observableService.lowestCloseLineIndicatorDividends.next(
        this.getJsonOrDefault(
          userDataMap[UserSettings.LowestCloseLineIndicatorDividends],
          LowestCloseLineIndicatorOptions.LowestLine
        )
      );

      this.observableService.dividendsDataWindowHeightPercent.next(
        this.getNumberOrDefault(userDataMap[UserSettings.DividendsDataWindowHeightPercent], null)
      );
      this.observableService.dividendsDataTableHeightPercent.next(
        this.getNumberOrDefault(userDataMap[UserSettings.DividendsDataTableHeightPercent], null)
      );

      // Dividends - END

      // Dividends Strategy - START

      let dividendsStrategySymbolId = this.getNumberOrDefault(userDataMap[UserSettings.DividendsStrategySymbol], null);
      const dividendsStrategySymbol = dividendsStrategySymbolId && await this.symbolsService.getById(dividendsStrategySymbolId);
      const isDividendsStrategySymbolValid = dividendsStrategySymbol && dividendsStrategySymbol.country_code === ExchangeCountriesCodes.US;
      if (!dividendsStrategySymbol || !isDividendsStrategySymbolValid) {
        const defaultDividendsStrategySymbol = allSymbols.find((s) => (
          s.country_code === ExchangeCountriesCodes.US && s.symbol === defaultSymbolName
        ));

        dividendsStrategySymbolId = defaultDividendsStrategySymbol
          ? defaultDividendsStrategySymbol.security_id
          : allSymbols[0].security_id;
      }

      this.observableService.dividendsStrategySymbol.next(dividendsStrategySymbolId);

      let dividendsStrategyNumberOfPositions = parseFloat(userDataMap[UserSettings.DividendsStrategyNumberOfPositions]);
      if (isNaN(dividendsStrategyNumberOfPositions)) {
        dividendsStrategyNumberOfPositions = 5;
        userSettingsDefaults.push({ key: UserSettings.DividendsStrategyNumberOfPositions, value: dividendsStrategyNumberOfPositions });
      }

      let dividendsStrategyBuyingPower = parseFloat(userDataMap[UserSettings.DividendsStrategyBuyingPower]);
      if (isNaN(dividendsStrategyBuyingPower)) {
        dividendsStrategyBuyingPower = 100000;
        userSettingsDefaults.push({ key: UserSettings.DividendsStrategyBuyingPower, value: dividendsStrategyBuyingPower });
      }

      this.observableService.dividendsStrategyBuyingPower.next(dividendsStrategyBuyingPower);
      this.observableService.dividendsStrategyNumberOfPositions.next(dividendsStrategyNumberOfPositions);

      this.observableService.dividendsStrategyScannerFilters.next(
        this.getJsonOrDefault(userDataMap[UserSettings.DividendsStrategyScannerFilters], DEFAULT_DIVIDENDS_STRATEGY_FILTERS_STATE)
      );
      this.observableService.isShortSellingStocksScannerHidden.next(
        this.parseAndTransformToBoolean(
          userDataMap[UserSettings.IsDividendsStrategyFiltersHidden],
          DEFAULT_IS_DIVIDENDS_STRATEGY_FILTERS_HIDDEN
        )
      );
      this.observableService.dividendsStrategyChartTab.next(userDataMap[UserSettings.DividendsStrategyChartTab] || ChartTabs.Chart);
      this.observableService.dividendsStrategyDataWindowHeightPercent.next(
        this.getNumberOrDefault(userDataMap[UserSettings.DividendsStrategyDataWindowHeightPercent], null)
      );

      let dividendsStrategyShowProfitLoss = parseInt(userDataMap[UserSettings.DividendsStrategyShowProfitLoss], 10);
      if (isNaN(dividendsStrategyShowProfitLoss)) {
        dividendsStrategyShowProfitLoss = 1;
      }
      this.observableService.dividendsStrategyShowProfitLoss.next(Boolean(dividendsStrategyShowProfitLoss));

      let dividendsStrategyShowEntryOption = userDataMap[UserSettings.DividendsStrategyShowEntryIndicator];
      if (!dividendsStrategyShowEntryOption
        || !Object.values(entryIndicatorsOptions).includes(dividendsStrategyShowEntryOption)
      ) {
        dividendsStrategyShowEntryOption = entryIndicatorsOptions.Show;
      }
      this.observableService.dividendsStrategyShowEntryIndicator.next(dividendsStrategyShowEntryOption);

      let dividendsStrategyShowTradeOption = parseInt(userDataMap[UserSettings.DividendsStrategyShowTradesOnChart], 10);
      if (isNaN(dividendsStrategyShowTradeOption)) {
        dividendsStrategyShowTradeOption = 1;
      }
      this.observableService.dividendsStrategyShowTradesOnChart.next(Boolean(dividendsStrategyShowTradeOption));

      // Dividends Strategy - END

      // Short selling stocks
      this.observableService.shortSellingStocksSymbol.next(shortSellingStocksSymbolId);
      this.observableService.shortSellingStocksChartInterval.next(shortSellingStocksChartInterval);
      this.observableService.shortSellingStocksDataWindowHeightPercent.next(
        this.getNumberOrDefault(userDataMap[UserSettings.ShortSellingStocksDataWindowHeightPercent], null)
      );
      this.observableService.shortSellingStocksScannerTab.next(
        this.getNumberOrDefault(userDataMap[UserSettings.ShortSellingStocksScannerTab], 0)
      );
      this.observableService.shortSellingStocksScannerNewTableSort.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShortSellingStocksScannerNewTableSort], DEFAULT_SCANNER_TABLE_SORT)
      );
      this.observableService.shortSellingStocksScannerOpenTableSort.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShortSellingStocksScannerOpenTableSort], DEFAULT_SCANNER_TABLE_SORT)
      );
      this.observableService.shortSellingStocksScannerAboveEntryTableSort.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShortSellingStocksScannerAboveEntryTableSort], DEFAULT_SCANNER_TABLE_SORT)
      );
      this.observableService.shortSellingStocksScannerExitTableSort.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShortSellingStocksScannerExitTableSort], DEFAULT_SCANNER_TABLE_SORT)
      );
      this.observableService.shortSellingStocksWatchlistTableSort.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShortSellingStocksWatchlistTableSort], DEFAULT_SCANNER_TABLE_SORT)
      );
      this.observableService.shortSellingStocksScannerFilters.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShortSellingStocksScannerFilters], DEFAULT_SHORT_SELLING_STOCKS_FILTERS_STATE)
      );
      this.observableService.isShortSellingStocksScannerHidden.next(
        this.parseAndTransformToBoolean(
          userDataMap[UserSettings.IsShortSellingStocksScannerHidden],
          DEFAULT_IS_SHORT_SELLING_STOCKS_FILTERS_HIDDEN
        )
      );
      this.observableService.shortSellingStocksBuyingPower.next(shortSellingStocksBuyingPower);
      this.observableService.shortSellingStocksNumberOfPositions.next(shortSellingStocksNumberOfPositions);
      this.observableService.shortSellingStocksChartTab.next(
        this.getNumberOrDefault(userDataMap[UserSettings.ShortSellingStocksChartTab], 1)
      );
      this.observableService.showRobIndicatorsShortSellingStocks.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShowRobIndicatorsShortSellingStocks], true)
      );

      // Short selling stocks
      this.observableService.shortingScannerSymbol.next(shortingScannerSymbolId);
      this.observableService.shortingScannerChartInterval.next(shortingScannerChartInterval);
      this.observableService.shortingScannerDataWindowHeightPercent.next(
        this.getNumberOrDefault(userDataMap[UserSettings.ShortingScannerDataWindowHeightPercent], null)
      );
      this.observableService.shortingScannerScannerTab.next(
        this.getNumberOrDefault(userDataMap[UserSettings.ShortingScannerScannerTab], 0)
      );
      this.observableService.shortingScannerScannerNewTableSort.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShortingScannerScannerNewTableSort], DEFAULT_SCANNER_TABLE_SORT)
      );
      this.observableService.shortingScannerScannerOpenTableSort.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShortingScannerScannerOpenTableSort], DEFAULT_SCANNER_TABLE_SORT)
      );
      this.observableService.shortingScannerScannerAboveEntryTableSort.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShortingScannerScannerAboveEntryTableSort], DEFAULT_SCANNER_TABLE_SORT)
      );
      this.observableService.shortingScannerScannerExitTableSort.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShortingScannerScannerExitTableSort], DEFAULT_SCANNER_TABLE_SORT)
      );
      this.observableService.shortingScannerWatchlistTableSort.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShortingScannerWatchlistTableSort], DEFAULT_SCANNER_TABLE_SORT)
      );
      this.observableService.shortingScannerScannerFilters.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShortingScannerScannerFilters], INITIAL_SHORTING_STOCKS_SCANNER_FILTERS_STATE)
      );
      this.observableService.isShortingScannerFilterHidden.next(
        this.parseAndTransformToBoolean(
          userDataMap[UserSettings.IsShortingScannerFilterHidden],
          DEFAULT_IS_SHORTING_STOCKS_SCANNER_FILTERS_HIDDEN
        )
      );
      this.observableService.shortingScannerBuyingPower.next(shortSellingStocksBuyingPower);
      this.observableService.shortingScannerNumberOfPositions.next(shortSellingStocksNumberOfPositions);
      this.observableService.shortingScannerChartTab.next(
        this.getNumberOrDefault(userDataMap[UserSettings.ShortingScannerChartTab], 1)
      );
      this.observableService.showRobIndicatorsShortingScanner.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShowRobIndicatorsShortingScanner], true)
      );
      this.observableService.shortingScannerDisplayHalts.next(
        this.parseAndTransformToBoolean(
          userDataMap[UserSettings.ShortingScannerDisplayHalts],
          false
        )
      );

      const shortingScannerFiltersWidth = this.getJsonOrDefault(userDataMap[UserSettings.ShortingScannerFiltersWidth]);
      this.observableService.shortingScannerFiltersWidth.next(
        shortingScannerFiltersWidth
      );

      this.observableService.showRockyAlways.next(
        this.getJsonOrDefault(userDataMap[UserSettings.ShowRockyAlways], DefaultRockyAlwaysVisible)
      );

      // Some settings are used from local storage in technical indicators,
      // so let's keep all user settings in local storage for consistency as well
      this.localStorageService.set(UserSettings.ActiveTab, activeTab);
      this.localStorageService.set(UserSettings.TradePosition, tradePosition);
      this.localStorageService.set(UserSettings.ChartTab, chartTab);
      this.localStorageService.set(UserSettings.RunScannerTradingStrategyId, runScannerTradingStrategyId);
      this.localStorageService.set(UserSettings.ShowEntryAndExitOption, showEntryAndExitOption);
      this.localStorageService.set(UserSettings.ShowTradeOption, showTradeOption);
      this.localStorageService.set(UserSettings.ShowTechnicalIndicators, showTechnicalIndicators);
      this.localStorageService.set(UserSettings.Symbol, symbol);
      this.localStorageService.set(UserSettings.AccountSize, accountSize);
      this.localStorageService.set(UserSettings.AccountRiskPercent, accountRiskPercent);
      this.localStorageService.set(UserSettings.AccountRiskAmount, accountRiskAmount);
      this.localStorageService.set(UserSettings.JoinCommunityBtnVisible, joinCommunityBtnVisible);
      this.localStorageService.set(UserSettings.WatchPxoTutorialsBtnVisible, watchPxoTutorialsBtnVisible);
      this.localStorageService.set(UserSettings.FirstTradingStrategyId, firstTradingStrategyId);
      this.localStorageService.set(UserSettings.SecondTradingStrategyId, secondTradingStrategyId);
      this.localStorageService.set(UserSettings.TradeReportTradingStrategyId, tradeReportTradingStrategyId);
      this.localStorageService.set(UserSettings.PrintOptions, printOptions);

      this.localStorageService.set(UserSettings.SelectScannerTab, selectedScannerTab);

      this.localStorageService.set(UserSettings.WheelSymbol, wheelSymbol);
      this.localStorageService.set(UserSettings.IsWheelCalculator, isWheelCalculator);
      this.localStorageService.set(UserSettings.IsWheelPremiums, isWheelPremiums);

      this.localStorageService.set(UserSettings.WheelScannerTab, wheelScannerTab);
      this.localStorageService.set(UserSettings.WheelTopTab, wheelTopTab);
      this.localStorageService.set(UserSettings.IsWheelFiltersHidden, isWheelFiltersHidden);
      this.localStorageService.set(UserSettings.WheelFiltersState, wheelFiltersState);
      this.localStorageService.set(UserSettings.WheelDataWindowUpdateSource, wheelDataWindowSource);
      this.localStorageService.set(UserSettings.WheelViewMode, wheelViewMode);
      this.localStorageService.set(UserSettings.WheelChartSectionHeight, wheelChartSectionHeight);
      this.localStorageService.set(UserSettings.LowestHighestCloseLineIndicatorWheel, lowestHighestCloseLineIndicatorWheel);
      this.localStorageService.set(UserSettings.LowestCloseLineIndicatorWheel, lowestCloseLineIndicatorWheel);
      this.localStorageService.set(UserSettings.LowestHighestCloseLineIndicatorBullCallSpread, lowestHighestCloseLineIndicatorBullCallSpread);
      this.localStorageService.set(UserSettings.LowestCloseLineIndicatorBullCallSpread, lowestCloseLineIndicatorBullCallSpread);
      this.localStorageService.set(UserSettings.LowestHighestCloseLineIndicatorStockScreener, lowestHighestCloseLineIndicatorStockScreener);
      this.localStorageService.set(UserSettings.LowestCloseLineIndicatorStockScreener, lowestCloseLineIndicatorStockScreener);

      this.localStorageService.set(UserSettings.HolidaysCountry, holidaysCountry);

      this.localStorageService.set(UserSettings.EarningsCalendarSymbol, earningsCalendarSymbol);
      this.localStorageService.set(UserSettings.EarningsCalendarTab, earningsCalendarTab);
      this.localStorageService.set(UserSettings.EarningsFilterValue, earningsFilterValue);

      this.observableService.mySettings.next(userSettings);
      this.observableService.wheelRoiRange.next({ lower: wheelROILowerBound, upper: wheelROIUpperBound });
      this.observableService.powerXInitialized.next(new Date().getTime());

      this.observableService.heatmapApiVersion.next(heatmapApiVersion ?? 1);
      this.observableService.showDividendsAnalysis.next(showDividendsAnalysis ?? 0);
      this.observableService.showStockScreener.next(showStockScreener ?? 1);
      this.observableService.showImportTrades.next(showImportTrades === 1);
      this.observableService.showTradierImportTrades.next(showTradierImportTrades === 1);
      this.observableService.showVideoHub.next(showVideoHub === 1);
      this.observableService.showRockyDataWindow.next(showRockyDataWindow === 1);
      this.observableService.showRockyEconomicCalendar.next(showRockyEconomicCalendar === 1);
      this.observableService.showHeatmapDetailsDataWindow.next(showHeatmapDetailsDataWindow === 1);
      this.observableService.showDividendsStrategy.next(showDividendsStrategy === 1);

      this.initializeMarketTimeService(workingHours, holidayData);

      this.scheduledEventsService.init();
      this.serverEventsService.init();

      await this.brokerAuthenticationService.initialize();
    } catch (error) {
      console.log('app init error:', error);
    }
  }

  initializeTradingStrategies(tradingStrategies: Array<any>, userDataMap) {
    let firstTradingStrategyId = parseInt(userDataMap[UserSettings.FirstTradingStrategyId], 10);
    let secondTradingStrategyId = parseInt(userDataMap[UserSettings.SecondTradingStrategyId], 10);
    let tradeReportTradingStrategyId = parseInt(userDataMap[UserSettings.TradeReportTradingStrategyId], 10);

    if (!firstTradingStrategyId
      || !secondTradingStrategyId
      || !tradeReportTradingStrategyId
      || (tradeReportTradingStrategyId !== firstTradingStrategyId && tradeReportTradingStrategyId !== secondTradingStrategyId)
    ) {
      if (!firstTradingStrategyId) {
        const firstTradingStrategy = secondTradingStrategyId
          ? tradingStrategies.find((s) => s.id !== secondTradingStrategyId)
          : tradingStrategies[0];

        firstTradingStrategyId = firstTradingStrategy.id;
      }

      if (!secondTradingStrategyId) {
        const secondTradingStrategy = tradingStrategies.find((s) => s.id !== firstTradingStrategyId);
        secondTradingStrategyId = secondTradingStrategy.id;
      }

      if (tradeReportTradingStrategyId !== firstTradingStrategyId && tradeReportTradingStrategyId !== secondTradingStrategyId) {
        tradeReportTradingStrategyId = firstTradingStrategyId;
      }
    }

    firstTradingStrategyId = tradingStrategies.find((s) => s.id === firstTradingStrategyId)
      ? firstTradingStrategyId
      : tradingStrategies.find((s) => s.id !== secondTradingStrategyId)?.id;

    secondTradingStrategyId = tradingStrategies.find((s) => s.id === secondTradingStrategyId)
      ? secondTradingStrategyId
      : tradingStrategies.find((s) => s.id !== firstTradingStrategyId)?.id;

    return {
      firstTradingStrategyId,
      secondTradingStrategyId,
      tradeReportTradingStrategyId,
    };
  }

  initializeTheme(settings: IMySettings) {
    const nextTheme = settings ? (settings.theme ? Themes.Light : Themes.Dark) : Themes.Light;

    if (nextTheme === 'dark') {
      this.observableService.theme.next(nextTheme);
      return this.themeService.toggleTheme(ThemesNumValues.Dark, false);
    }

    return null;
  }

  async initializeApplicationVersion() {
    const applicationVersion = await this.serverDataService.getAsObject<ApplicationVersionModel>(ServerSettings.ApplicationVersion, true);

    this.observableService.applicationVersion.next(applicationVersion);
  }

  initializeMarketTimeService(workingHours: IWorkingHours[], holidayData: IRawHoliday[]) {
    const workingHoursMap = new Map(workingHours.map((w) => [w.date, w]));

    const holidaysMap = new Map<Countries, Map<string, string>>();
    for (const holiday of holidayData) {
      if (!holidaysMap.get(holiday.country)) {
        holidaysMap.set(holiday.country, new Map<string, string>());
      }

      holidaysMap.get(holiday.country).set(convertToEasternTime(holiday.date).format(MomentDateTimeFormats.ServerDate), holiday.holiday);
    }

    this.marketTimeService.initialize(workingHoursMap, holidaysMap);
  }

  // transforms saved in DB "0" and "1" values to boolean
  private parseAndTransformToBoolean(jsonValue: string, defaultValue: any = null): boolean {
    if (jsonValue === null || jsonValue === undefined) {
      return defaultValue;
    }

    try {
      const parsedValue = JSON.parse(jsonValue);

      // Example: "1" => 1 => true
      return Boolean(Number(parsedValue));
    } catch {
      return defaultValue;
    }
  }

  private getJsonOrDefault(jsonValue: string, defaultValue: any = null) {
    try {
      const json = JSON.parse(jsonValue);

      return json;
    } catch {
    }

    return defaultValue;
  }

  private getNumberOrDefault(value: string, defaultValue: number): number {
    const parsed = parseInt(value, 10);

    if (isNaN(parsed)) {
      return defaultValue;
    }

    return parsed;
  }
}
