<div class="real-data-window" [ngClass]="{'data-window-tradier-flag': tradierFlags.tradier_flag_pxo}">
    <div *ngIf="!!putSubscription" class="data-table put-row" [ngClass]="{'collapsed-put-row': collapsedPutRow, 'collapsed-call-row-marker': collapsedCallRow}">
        <div class="blur-overlay" *ngIf="isPutUpdateOverlay && !isPutLoading"></div>
        <h3 class="header overflow-ellipsis">Select PUT premium for {{ putSubscription.symbol?.symbol | uppercase }} - {{ putSubscription.expiration.format('MMM D') }}</h3>
        <div *ngIf="!!putOptionsChain?.updatedAt && !isNoPutOptions && !isPutTimeout" class="warning-wrapper" >
            <div class="alert-container">
                <p>Results as of {{getUpdatedDateTime(true, 'MMM D')}} <span class="dot"></span> {{getUpdatedDateTime(true, 'h:mm:ss A')}} ET</p>
            </div>
            <div class="updated-message">
                <span>Automatically updated.</span>
                <mat-icon
                [svgIcon]="'hint_icon'"
                class="extra-small-icon"
                matTooltip="Prices are updated automatically every 2min when the market is open."
                [matTooltipHideDelay]="0"
                ></mat-icon>
            </div>
        </div>
        <div class="table-container scroll-style" *ngIf="!isPutLoading && !isNoPutOptions && !isPutTimeout" >
            <app-table
                [subscription]="putSubscription"
                [roiLowerBound]="roiLowerBound"
                [roiUpperBound]="roiUpperBound"
                [data]='putDataSource'
                [maxRoi]="putMaxRoi"
                (itemClickEvent)="onPutClick($event)">
            </app-table>
        </div>
        <mat-spinner *ngIf="isPutLoading && !isPutTimeout" class="spinner" [diameter]="50"></mat-spinner>
        <app-no-data-message *ngIf="isNoPutOptions"></app-no-data-message>
        <app-request-result-message *ngIf="isPutTimeout" (retryRequest)='retryPutRequest()'></app-request-result-message>

    </div>
    <div *ngIf="!!callSubscription" class="data-table call-data-table" [ngClass]="{'single-call-table':!putSubscription, 'collapsed-put-row': collapsedPutRow, 'collapsed-call-row': collapsedCallRow, 'colapsed-rows': collapsedCallRow && collapsedPutRow}">
        <div class="blur-overlay" *ngIf="isCallUpdateOverlay && !isCallLoading"></div>
        <h3 class="header overflow-ellipsis">Select CALL premium for {{ callSubscription.symbol?.symbol | uppercase }} - {{ callSubscription.expiration.format('MMM D') }}</h3>
        <div *ngIf="!!callOptionsChain?.updatedAt && !isNoCallOptions && !isCallTimeout"  class="warning-wrapper">
            <div class="alert-container">
                <p>Results as of {{getUpdatedDateTime(false, 'MMM D')}} <span class="dot"></span> {{getUpdatedDateTime(false, 'h:mm:ss A')}} ET</p>
            </div>
            <div class="updated-message">
                <span>Automatically updated.</span>
                <mat-icon
                [svgIcon]="'hint_icon'"
                class="extra-small-icon"
                matTooltip="Prices are updated automatically every 2min when the market is open."
                [matTooltipHideDelay]="0"
                ></mat-icon>
            </div>
        </div>
        <div class="table-container scroll-style" *ngIf="!isCallLoading && !isNoCallOptions && !isCallTimeout" >
            <app-table
                [subscription]="callSubscription"
                [roiLowerBound]="roiLowerBound"
                [roiUpperBound]="roiUpperBound"
                [data]='callDataSource'
                [maxRoi]="callMaxRoi"
                (itemClickEvent)="onCallClick($event)">
            </app-table>
        </div>
        <mat-spinner *ngIf="isCallLoading && !isCallTimeout" class="spinner" [diameter]="50"></mat-spinner>
        <app-no-data-message *ngIf="isNoCallOptions"></app-no-data-message>
        <app-request-result-message *ngIf="isCallTimeout" (retryRequest)='retryCallRequest()'></app-request-result-message>

    </div>
</div>
