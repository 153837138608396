import { VideoHubCacheService } from '@s/video-hub/video-hub-cache.service';
import { UserDataService } from '@s/user-data.service';
import { Injectable } from '@angular/core';
import { VideoHubVideoStopPositionModel } from '@mod/video-hub/video-hub.model';

@Injectable({
  providedIn: 'root'
})
export class VideoHubVideoPositionService {
  public readonly sendUpdateUserStopTimeSeconds = 15;
  private readonly userDataKey = 'video-hub-position';
  private previousSecondsValue: number | null = null;

  constructor(private userDataService: UserDataService, private videoHubCacheService: VideoHubCacheService) {
    this.downloadVideoStopPositions();
  }

  public async getVideosStopPositions(): Promise<VideoHubVideoStopPositionModel[]> {
    const cachedData = this.videoHubCacheService.get(this.userDataKey) as VideoHubVideoStopPositionModel[];

    if (cachedData && Array.isArray(cachedData)) {
      return Promise.resolve(cachedData);
    }

    const videoStopPositions = await this.downloadVideoStopPositions();

    if (videoStopPositions && Array.isArray(videoStopPositions)) {
      return videoStopPositions;
    }

    return [];
  }

  public async updateVideoStopPositions(newData: VideoHubVideoStopPositionModel): Promise<void> {
    if (this.previousSecondsValue === newData.seconds) {
      return;
    }

    const videosStopPositions = await this.getVideosStopPositions();
    const updatedVideoIndex = videosStopPositions.findIndex((videoStopPosition) => videoStopPosition.videoId === newData.videoId);

    if (updatedVideoIndex >= 0) {
      videosStopPositions[updatedVideoIndex] = newData;
    } else {
      videosStopPositions.push(newData);
    }

    await this.userDataService.set(this.userDataKey, videosStopPositions);
    this.videoHubCacheService.set(this.userDataKey, videosStopPositions);
    this.previousSecondsValue = newData.seconds;
  }


  private async downloadVideoStopPositions(): Promise<VideoHubVideoStopPositionModel[]> {
    const allVideoStopPositions = await this.userDataService.getAsJSON(this.userDataKey);
    this.videoHubCacheService.set(this.userDataKey, allVideoStopPositions);
    return allVideoStopPositions;
  }
}
