import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExchangeCountriesCodes, UserSettings } from '@const';
import { ISearchDialogData, SymbolSearchModalComponent } from '@m/common/search-bar/symbol-search-modal/symbol-search-modal.component';
import { TypedCallback } from '@t/common/calback.types';

import { ISymbol } from './symbols.service';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root',
})
export class SearchPopupService {
  constructor(
    private dialog: MatDialog,
    private userDataService: UserDataService,
  ) {}

  public openPopup(
    searchString: string,
    isPowerX: boolean,
    selectInput: boolean = false,
    showAddWatchlist: boolean = false,
    symbolSources: ReadonlyArray<ExchangeCountriesCodes> = [ExchangeCountriesCodes.US],
    cb?: (symbol: ISymbol) => void,
    updateChartSymbol: boolean = true,
  ) {
    if (!this.dialog.openDialogs.length) {
      const dialogRef = this.openSymbolSearchDialog(searchString, isPowerX, selectInput, showAddWatchlist, symbolSources);
      dialogRef.afterClosed().subscribe(async (symbol) => {
        if (!symbol) {
          return;
        }

        if (!isPowerX && cb) {
          cb(symbol);
        }

        if (updateChartSymbol) {
          await this.userDataService.set(isPowerX ? UserSettings.Symbol : UserSettings.WheelSymbol, symbol.security_id);
        }
      });
    }
  }

  public openSimplePopup(
    searchString: string,
    symbolSources: ReadonlyArray<ExchangeCountriesCodes>,
    stickTo?: HTMLElement,
    scrollElement?: HTMLElement,
    callbackInsteadOfClose?: TypedCallback<ISymbol>,
    customClasses?: Array<string>,
    customHeader?: string
  ): Promise<ISymbol> {
    return this.openSymbolSearchDialog(
      searchString,
      false,
      false,
      false,
      symbolSources,
      stickTo,
      scrollElement,
      callbackInsteadOfClose,
      customClasses,
      customHeader
    )
      .afterClosed()
      .toPromise();
  }

  private openSymbolSearchDialog(
    searchString: string,
    isPowerX: boolean,
    selectInput: boolean,
    showAddWatchlist: boolean,
    symbolSources: ReadonlyArray<ExchangeCountriesCodes>,
    stickTo?: HTMLElement,
    scrollElement?: HTMLElement,

    callbackInsteadOfClose?: TypedCallback<ISymbol>,
    customClasses?: Array<string>,
    customHeader?: string
  ) {
    const dialogConfig = new MatDialogConfig<ISearchDialogData>();
    dialogConfig.panelClass = ['symbol-search', ...(customClasses ?? [])];
    dialogConfig.data = {
      searchString,
      isPowerX,
      selectInput,
      showAddWatchlistButton: showAddWatchlist,
      symbolSources,
      callbackInsteadOfClose,
      stickTo,
      scrollElement,
      customHeader,
    };
    if (stickTo) {
      dialogConfig.scrollStrategy;
      dialogConfig.disableClose = true;
      dialogConfig.disableClose = false;
      dialogConfig.panelClass = ['symbol-search', 'nowidth'];
      dialogConfig.data.clearSearchStringAfterSelection = true;
    }

    const dialogRef = this.dialog.open<SymbolSearchModalComponent, ISearchDialogData, ISymbol>(SymbolSearchModalComponent, dialogConfig);
    return dialogRef;
  }
}
