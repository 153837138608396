export enum ServerNotificationTypes {
  SOL = 'SS',
}

export interface SolServerNotificationDataModel {
  security_id: number;
  symbol: string;
  message: string;
  description: string;
  strategy: ServerNotificationTypes;
  type: 'new' | 'open';
  time: string;
}

// extend this data-model with other notification types (use union type)
// should be different required "strategy" property - ServerNotificationTypes value
export type ServerNotificationDataModel = SolServerNotificationDataModel;

export interface ServerNotificationModel {
  id: number;
  reference_id: number;
  data: ServerNotificationDataModel;
  type: ServerNotificationTypes;
  created_date: string;
  scheduled_date: string;
}
