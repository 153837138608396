import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';
import * as moment from 'moment';

import { convertArrayToRecord } from '@u/utils';
import { MomentDateTimeFormats } from '@const';
import { CalendarWeek } from '@c/shared/weeks-selector/weeks-selector.model';

@Component({
  selector: 'app-weeks-selector',
  templateUrl: './weeks-selector.component.html',
  styleUrls: ['./weeks-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeeksSelectorComponent implements OnInit, OnDestroy {
  protected selectorWidth = 380;
  protected currentTime: string | null = null;

  protected currentWeeksArray: CalendarWeek[] = [];
  protected currentWeeksRecord: Record<number, CalendarWeek> = {};

  private defaultStringLength = 30;
  private subscriber = new Subscription();

  @Input() set weeksArray(value: CalendarWeek[]) {
    const sortedWeeksArray = [...value].sort((a, b) => a.index - b.index);

    this.currentWeeksArray = sortedWeeksArray;
    this.currentWeeksRecord = convertArrayToRecord(sortedWeeksArray, 'index');
  }

  @Input() selectedWeekIndex = 0;
  @Input() showDescription = true;

  @Input() menuClosedOptionTemplate: TemplateRef<any>;
  @Input() menuOpenedOptionTemplate: TemplateRef<any>;

  @Output() changeValue = new EventEmitter<CalendarWeek>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    const maxLength = Math.max(
      ...this.currentWeeksArray.map((i) => (i.weekName + i.weekValue).length)
    );
    this.selectorWidth = maxLength > this.defaultStringLength
      ? this.selectorWidth + maxLength
      : this.selectorWidth;

    this.updateCurrentTime();
    const updateTimeInterval = interval(1000);
    this.subscriber.add(updateTimeInterval.subscribe(() => this.updateCurrentTime()));

    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  protected submitNext(): void {
    const nextWeek = this.currentWeeksRecord[this.selectedWeekIndex + 1];

    if (nextWeek) {
      this.changeValue.emit(nextWeek);
    }
  }

  protected submitPrev(): void {
    const previousWeek = this.currentWeeksRecord[this.selectedWeekIndex - 1];

    if (previousWeek) {
      this.changeValue.emit(previousWeek);
    }
  }

  protected valueChanged(event: MatSelectChange): void {
    this.changeValue.emit(this.currentWeeksRecord[event.value]);
    this.changeDetectorRef.markForCheck();
  }

  protected updateCurrentTime(): void {
    this.currentTime = moment().format(MomentDateTimeFormats.TimeAmPm);

    this.changeDetectorRef.markForCheck();
  }
}
