import { StockScreenerFilterModel } from '@c/stock-screener-content/stock-screener-filter/stock-screener-filter.model';

export const EMIT_STOCK_SCREENER_FILTER_CHANGE_DEBOUNCE_TIME_MS = 800;

export const DEFAULT_IS_FILTERS_HIDDEN = false;

export const DEFAULT_STOCK_SCREENER_FILTERS_STATE: StockScreenerFilterModel = {
  changePercentFrom: '',
  changePercentTo: '',
  stockPriceFrom: '',
  stockPriceTo: '',
  volumeFrom: {
    filterValue: '',
    visibleValue: ''
  },
  volumeTo: {
    filterValue: '',
    visibleValue: ''
  }
};
