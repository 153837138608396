@if (currentWeeksArray && currentWeeksRecord) {
  <div
    class="weeks-selector container"
    [style.width.px]="selectorWidth"
  >
    <button
      mat-icon-button
      type="button"
      class="icon-button content-center week-control-btn"
      (click)="submitPrev()"
      [disabled]="currentWeeksArray[0]?.index === selectedWeekIndex"
    >
      <mat-icon class="arrow-icon" [svgIcon]="'arrow-left_icon'"></mat-icon>
    </button>

    <div class="select-wrap">
      @if (!menuClosedOptionTemplate || !menuOpenedOptionTemplate) {
        <div class="form-group">
          <mat-select
            hideSingleSelectionIndicator="true"
            [value]="selectedWeekIndex"
            (selectionChange)="valueChanged($event)"
            panelClass="primary-active week-selector"
            class="select-style custom-size custom-arrow"
          >
            @for (value of currentWeeksArray; track value.index) {
              <mat-option [value]="value.index">
                <div class="title-wrapper">
                  <p class="title-section week-name">{{ value.weekName }}</p>

                  @if (value.weekDetails) {
                    <p class="title-section week-details">
                      <!-- additional spaces ("&nbsp;") are important for selected value (when options are hidden)  -->
                      &nbsp;{{ value.weekDetails }}&nbsp;
                    </p>
                  }

                  <p class="title-section week-value">{{ value.weekValue }}</p>
                </div>
              </mat-option>
            }
          </mat-select>
        </div>
      }

      @if (menuClosedOptionTemplate && menuOpenedOptionTemplate) {
        <!--  select with custom option templates  -->
        <div class="form-group">
          <mat-select
            #select
            [value]="selectedWeekIndex"
            (selectionChange)="valueChanged($event)"
            panelClass="primary-active week-selector custom-options"
            class="select-style custom-size custom-arrow week-selector"
            [class.menu-opened]="select.panelOpen"
          >
            <mat-select-trigger>
              <ng-container
                [ngTemplateOutlet]="menuClosedOptionTemplate"
                [ngTemplateOutletContext]="{ weekItemDetails: currentWeeksRecord[selectedWeekIndex] }"
              ></ng-container>
            </mat-select-trigger>

            @for (value of currentWeeksArray; track value.index) {
              <mat-option [value]="value.index" class="custom-template-option">
                <ng-container
                  [ngTemplateOutlet]="menuOpenedOptionTemplate"
                  [ngTemplateOutletContext]="{ weekItemDetails: value }"
                ></ng-container>
              </mat-option>
            }
          </mat-select>
        </div>
      }
    </div>

    <button
      mat-icon-button
      type="button"
      class="icon-button content-center week-control-btn"
      (click)="submitNext()"
      [disabled]="currentWeeksArray[currentWeeksArray.length-1]?.index === selectedWeekIndex"
    >
      <mat-icon class="arrow-icon" [svgIcon]="'arrow-left_icon'"></mat-icon>
    </button>
  </div>
}

@if (showDescription) {
  <div class="events-time">
    <span class="time-title">All events in Local Time</span>
    <span class="current-time-wrapper">
      <mat-icon class="small-icon primary-time-icon" [svgIcon]="'time-icon'"></mat-icon>
      <span class="current-time">{{ currentTime }}</span>
    </span>
  </div>
}
