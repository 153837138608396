import { IDividendsScannerFilter } from '@c/dividends-content/dividends-scanner-filter/dividends-scanner-filter.model';

export const EMIT_DIVIDENDS_FILTER_CHANGE_DEBOUNCE_TIME_MS = 800;
export const DEFAULT_IS_DIVIDENDS_FILTERS_HIDDEN = false;

export const DEFAULT_DIVIDENDS_FILTERS_STATE: IDividendsScannerFilter = {
  exDateDaysFrom: '',
  exDateDaysTo: '',
  divPerShareFrom: '',
  divPerShareTo: '',
  stockPriceFrom: '',
  stockPriceTo: '',
  recentDividendsYears: 3,
  divYieldPercentFrom: '',
  divYieldPercentTo: '',
  avgBreakEvenDaysFrom: '',
  avgBreakEvenDaysTo: '',
  avgExDatePercentFrom: '',
  avgExDatePercentTo: '',
};

export const RECOMMENDED_DIVIDENDS_FILTERS_STATE: IDividendsScannerFilter = {
  exDateDaysFrom: '',
  exDateDaysTo: '',
  divPerShareFrom: '',
  divPerShareTo: '',
  stockPriceFrom: '',
  stockPriceTo: '',
  recentDividendsYears: 3,
  divYieldPercentFrom: '',
  divYieldPercentTo: '',
  avgBreakEvenDaysFrom: '',
  avgBreakEvenDaysTo: '',
  avgExDatePercentFrom: '',
  avgExDatePercentTo: '',
};
