// TODO: move to core ??
import { SortDirection } from '@core/types';
import { Flags } from '@mod/symbol-smiley/symbol-smiley.model';

export enum Direction {
  ASC = 'asc',
  DESC = 'desc',
}

export enum TextInputKeys {
  StrikeFrom = 'strikeFrom',
  StrikeTo = 'strikeTo',
  RoiFrom = 'roiFrom',
  RoiTo = 'roiTo',
  PremiumFrom = 'premiumFrom',
  PremiumTo = 'premiumTo',
}

export enum MinStrikeToLowestClose {
  Below = 'below',
  AtLowestCloseInd = 'atLowestCloseInd',
  AllStrikes = 'allStrikes',
  AtOrBellowLowestInd = 'atOrBellowLowestInd',
}

export enum DividendsCount {
  All = 'all',
  AtLeastOne = 'yes',
  None = 'no'
}

export enum SectorsFilter {
  All = 'all',
  ExcludePortfolioSectors = 'excludePortfolioSectors',
}

export const allMinStrikeValues: ReadonlyArray<MinStrikeToLowestClose> = [
  MinStrikeToLowestClose.Below,
  MinStrikeToLowestClose.AtLowestCloseInd,
  MinStrikeToLowestClose.AllStrikes,
  MinStrikeToLowestClose.AtOrBellowLowestInd,
];

export interface IMarketCapValue {
  filterValue: number | string;
  visibleValue: string;
}

export interface IWheelFilter {
  flags: Flags[];
  expiration: string;
  strikeFrom: number | string;
  strikeTo: number | string;
  roiFrom: number | string;
  roiTo: number | string;
  premiumFrom: number | string;
  premiumTo: number | string;
  peRatioFrom: number | string;
  peRatioTo: number | string;
  changeInPercentFrom: number | string;
  changeInPercentTo: number | string;
  dropInPercentFrom: number | string;
  dropInPercentTo: number | string;
  marketCapFrom: IMarketCapValue;
  marketCapTo: IMarketCapValue;
  minStrike: MinStrikeToLowestClose;
  sectors: SectorsFilter;
  dividends: string;
  // TODO: move no enum and reuse
  risk?: 'all' | 'conservative' | 'aggressive';
}

export enum DataWindowUpdateSource {
  ChartSearchBar = 'chartSearchBar',
  Calculator = 'calculator',
  Watchlist = 'watchlist',
  ScannerConservative = 'scannerConservative',
  ScannerAggressive = 'scannerAggressive',
  ScannerAll = 'scannerAll',
  HeatmapTooltip = 'heatmap-tooltip',
}

export enum WheelViewModes {
  Tabs = 1,
  Split = 2,
}

export interface IDataWindowUpdateCommand {
  source: DataWindowUpdateSource;
  symbol: {
    id: number,
    name: string,
    options?: Array<IWheelStockOption>,
  };
}

export type StockOptionAttributeType = 'conservative' | 'aggressive' | 'all';

// response from server
export interface IWheelStockOptionRaw {
  security_id: number;
  annularized: number;
  ask_price: number;
  atr_type: StockOptionAttributeType;
  bid_price: number;
  close_40_min: number;
  company: string;
  created_date: string; // date-string: "2021-12-20T19:00:09.000Z"
  delta: number;
  drop_percentage: number;
  dte: number;
  exchange: string; // "NYSE"
  exchange_code: string; // "N"
  expiration: string; // date-string: "2021-12-23"
  id: number;
  last_traded_price: number;
  long_descriptions: string | null;
  min: number;
  min_premium: number;
  option_premium: number;
  option_type: string; // "put"
  industry?: string; // "Aluminum"
  sector?: string; // "Basic Materials"
  strike_price: number;
  symbol: string;
  updated_on: string; // date-string: // "2021-12-20 16:0:9"
  price_change_percentage: number;
  pe_ratio: number;
  market_cap: number;
  dividends_count: number;
  is_scanner?: 0 | 1 | boolean;
}

export interface IWheelStockOption extends IWheelStockOptionRaw {
  flag: Flags;
}

// for scannerV2 response
export interface IWheelStockOptionRawV2 {
  annularized: number;
  ask_price: number;
  atr_type: 'conservative' | 'aggressive';
  bid_price: number;
  delta: number;
  drop_percentage: number;
  dte: number;
  expiration: string;
  has_greeks: boolean;
  min: number;
  option_premium: number;
  option_type: string; // "put"
  security_id: number;
  strike_price: number;
  price_change_percentage: number;
}

// for scannerV2 response
export interface ISymbolDetailsV2 {
  atr: number;
  close: number;
  close_40_min: number;
  company: string;
  created_date: string;
  country_code: string;
  dividends_count: number;
  drop_ratio: number;
  exchange: string;
  exchange_code: string;
  industry: string;
  is_scanner: boolean;
  last_traded_price: number;
  long_description: string;
  market_cap: number;
  maxAggressivePrice: number;
  maxConservativePrice: number;
  pe_ratio: number;
  rsi: number;
  sector: string;
  security_id: number;
  symbol: string;
  earnings_share: number;
  dividend_yield: number;
  percent_insiders: number;
  percent_institutions: number;
}

export interface ISymbolDetailsV2WithSource extends ISymbolDetailsV2 {
  isFromScanner: boolean;
}

export interface IScannerResponse {
  scannerResults: IWheelStockOptionRaw[];
  expirationDates: string[];
}

export interface IScannerResponseV2 {
  expirationDates: string[];
  scannerResults: IWheelStockOptionRawV2[];
  securityDetails: Record<number, ISymbolDetailsV2>;
}

export interface SortState {
  column: string;
  direction: SortDirection;
}

export interface IWheelROIRange {
  lower: number;
  upper: number;
}

