import { VideoHubApiKeysType } from "@t/video-hub/video-hub-api-keys-type.enum";

export interface EnvironmentModel {
  production: boolean;
  name: EnvironmentNames;
  BaseUrl: string;
  // Websocket instance URL for trading log, live price updates
  WsBaseUrl: string;
  // Each instance has websockets to be used in most cases when it is not about trading log, live price updates
  WsMultiInstanceUrl: string;
  SwitchSiteUrl: string;
  TokenExpirationCheckIntervalMS: number;
  UpdateTokenByUserActivityDelaySec: number;
  TokenExpirationCheckSafeTimePeriodSec: number;
  SyncfusionKey: string;
  SentryDsn: string;
  SentryAllowedUrls: Array<(string | RegExp)>;
  VideoHubConfiguration: VideoHubConfigurationModel;
}

export enum EnvironmentNames {
  Local = 'Local',
  Dev = 'Development',
  Branch = 'Branch',
  Staging = 'Staging',
  PreProd = 'Pre Production',
  Prod = 'Production'
}

export interface VideoHubConfigurationModel {
  apiKeys: Record<VideoHubApiKeysType, string>;
  wordPressDomainName: string;
}
