import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { EasternTimeZoneName } from '@const';
import { ILiveSubscription, IOptionsChain, IOption } from '../../wheel-calculator.component';

@Component({
  selector: 'app-window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.scss']
})
export class WindowComponent implements OnInit {

  @Input() roiLowerBound: number;
  @Input() roiUpperBound: number;

  @Input() putSubscription: ILiveSubscription;
  @Input() putOptionsChain: IOptionsChain;

  @Input() callSubscription: ILiveSubscription;
  @Input() callOptionsChain: IOptionsChain;

  @Input() collapsedCallRow: boolean;
  @Input() collapsedPutRow: boolean;

  @Input() isPutTimeout: boolean;
  @Input() isCallTimeout: boolean;

  @Input() isPutUpdateOverlay: boolean;
  @Input() isCallUpdateOverlay: boolean;

  @Input() tradierFlags: { tradier_flag_pxo: number, tradier_flag_ifs: number };

  @Output() putClickEvent = new EventEmitter<IOption>();
  @Output() callClickEvent = new EventEmitter<IOption>();

  @Output() putRetryEvent = new EventEmitter<void>();
  @Output() callRetryEvent = new EventEmitter<void>();

  private CALL_PRICE_COEFFICIENT: number = 0.7;

  constructor() { }

  ngOnInit(): void { }

  onPutClick(item: IOption) {
    this.putClickEvent.emit(item);
  }

  onCallClick(item: IOption) {
    this.callClickEvent.emit(item);
  }

  get putDataSource(): IOption[] {
    const filteredOptions = this.putOptionsChain?.options?.filter((o: IOption) =>
      this.putSubscription.stockPrice ? o.strike <= this.putSubscription.stockPrice : true)
      || [];

    if (this.putOptionsChain?.options?.length && this.putSubscription.stockPrice) {
      let aboveOptionsAdded = 0;

      for (let i = this.putOptionsChain.options.length - 1; i >= 0; i--) {
        const option = this.putOptionsChain.options[i];
        if (option.strike > this.putSubscription.stockPrice) {
          aboveOptionsAdded += 1;
          filteredOptions.unshift(option);

          if (aboveOptionsAdded >= 2) {
            break;
          }
        }
      }
    }

    return filteredOptions;
  }

  get putMaxRoi(): number {
    return Math.max(...this.putDataSource.map(({ roi }) => roi || 0));
  }

  get isPutLoading(): boolean {
    return !!this.putSubscription && !this.putOptionsChain;
  }

  get isNoPutOptions(): Boolean {
    return this.putOptionsChain && this.putDataSource.length === 0;
  }

  get callDataSource(): IOption[] {
    const minStrikePrice = this.callSubscription.stockPrice
      ? this.callSubscription.stockPrice * this.CALL_PRICE_COEFFICIENT
      : 0;

    return this.callOptionsChain?.options.filter((o: IOption) => o.strike >= minStrikePrice) || [];
  }

  get callMaxRoi(): number {
    return Math.max(...this.callDataSource.map(({ roi }) => roi || 0));
  }

  get isCallLoading(): boolean {
    return !!this.callSubscription && !this.callOptionsChain;
  }

  get isNoCallOptions(): Boolean {
    return this.callOptionsChain && this.callDataSource.length === 0;
  }

  getUpdatedDateTime(isPut: boolean, format: string): string {
    const time = isPut
      ? this.putOptionsChain?.updatedAt
      : this.callOptionsChain?.updatedAt;

    const etTime = moment.unix(time).tz(EasternTimeZoneName);

    return etTime.isValid()
      ? etTime.format(format)
      : '';
  }

  retryPutRequest() {
    this.putRetryEvent.emit();
  }

  retryCallRequest() {
    this.callRetryEvent.emit();
  }
}
