import { Injectable, OnDestroy } from '@angular/core';
import { DialogsService } from '@s/common';
import {
  BehaviorSubject,
  combineLatest,
  fromEventPattern,
  Observable,
  ReplaySubject,
  Subject,
  switchMap
} from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';
import * as _ from 'lodash';

import { MorningStarIndustryService } from '@s/morning-star-industry.service';
import { MorningStarSectorService } from '@s/morning-star-sector.service';
import { DataChannelService } from '@s/data-channel.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { WheelService } from '@m1/wheel/wheel.service';
import { NavigationService } from '@s/navigation.service';
import { Unsubscriber } from '@u/unsubscriber';
import { getDomId, sortIndustries } from '@c/stock-heatmap/helpers';
import { getNumberComparerDesc } from '@u/comparers';
import {
  DEFAULT_HEATMAP_FILTERS,
  DataChannelCommands,
  ExchangeCountriesCodes,
  TabNames
} from '@const';
import { IHeatmapFilters, IHeatmapResponse } from '@mod/data/sp-500.model';
import { D3ChartNode, HeatmapElement, IndexOptionType, MarketTimeOptionType, SectorsTree } from '@c/stock-heatmap/stock-heatmap.model';
import { MorningStarIndustryMap, MorningStarSectorMap } from '@mod/data/morning-star.model';
import { IDataChannelCommand } from '@mod/data/data-channel.model';
import { DEFAULT_ONLY_WITH_WEEKLY_OPTIONS, indexOptions, marketTimeOptions } from '@c/stock-heatmap/stock-heatmap.data';

const CHANGE_DATA_CHANNEL_COMMAND_DEBOUNCE_TIME = 200;

@Injectable({
  providedIn: 'root'
})
export class StockHeatmapService extends Unsubscriber implements OnDestroy {
  public readonly lastUpdateDate$ = new BehaviorSubject<string | null>(null);

  private readonly loadingSource$ = new BehaviorSubject<boolean>(true);
  private readonly loadingDataForChart$ = new BehaviorSubject<boolean>(true);

  public readonly loading$ = combineLatest([
    this.loadingSource$,
    this.loadingDataForChart$,
  ])
    .pipe(
      map(([loadingSource, loadingDataForChart]) => {
        return loadingSource || loadingDataForChart;
      })
    );

  public readonly currentDataChannelCommand$ = new Subject<DataChannelCommands>();

  public readonly currentIndexOption$ = new ReplaySubject<IndexOptionType>(1);
  public readonly currentMarketTimeOption$ = new ReplaySubject<MarketTimeOptionType>(1);

  private dataChannelCommand: IDataChannelCommand | null = null;

  public heatmapFilters$ = new BehaviorSubject<IHeatmapFilters>(DEFAULT_HEATMAP_FILTERS);
  public onlyWithWeeklyOptions$ = new BehaviorSubject<boolean>(DEFAULT_ONLY_WITH_WEEKLY_OPTIONS);

  public readonly dataV1$: Observable<D3ChartNode<D3ChartNode<HeatmapElement>>[]>;
  public readonly dataV2$: Observable<D3ChartNode<D3ChartNode<HeatmapElement>>[]> = combineLatest([
    this.currentMarketTimeOption$
      .pipe(
        distinctUntilChanged(),
        tap(() => {
          this.loadingSource$.next(true);
          this.loadingDataForChart$.next(true);
        }),
        debounceTime(CHANGE_DATA_CHANNEL_COMMAND_DEBOUNCE_TIME),
        tap(() => {
          if (this.dataChannelCommand) {
            this.unsubscribeFromChartData();
          }
        }),
        switchMap((marketTimeOption) => {
          const dataForSubscription = {
            [marketTimeOptions.isPreMarket]: { isPreMarket: true },
            [marketTimeOptions.isMarket]: { isMarket: true },
            [marketTimeOptions.isPostMarket]: { isPostMarket: true },
          };

          return fromEventPattern<IHeatmapResponse>((handler) => {
            this.dataChannelCommand = {
              subscriptionId: uuidV4(),
              name: DataChannelCommands.CompositeIndexHeatmap2,
              data: dataForSubscription[marketTimeOption],
              handler
            };

            this.dataChannelService.subscribe(this.dataChannelCommand);
          });
        }),
        tap(() => this.loadingDataForChart$.next(false))
      ),
    this.industryService.get(),
    this.sectorService.get(),
    this.symbolsService.getAll(),
    this.heatmapFilters$.pipe(distinctUntilChanged(_.isEqual)),
    this.currentIndexOption$,
    this.onlyWithWeeklyOptions$.pipe(distinctUntilChanged()),
  ]).pipe(
    takeUntil(this._destroy$),
    map(([
      dataForChart,
      industries,
      sectors,
      symbols,
      filters,
      index,
      onlyWithWeeklyOptions
    ]) => {
      const symbolsMap = new Map<string, ISymbol>();
      symbols.forEach((symbol) => {
        if (symbol.country_code === ExchangeCountriesCodes.US) {
          symbolsMap.set(symbol.symbol, symbol);
        }
      });

      let filteredByIndexData = dataForChart.symbols;

      if (index === indexOptions.Sp500) {
        filteredByIndexData = dataForChart.symbols.filter((item) => Boolean(item.is_sp_500));
      } else if (index === indexOptions.DowJones) {
        filteredByIndexData = dataForChart.symbols.filter((item) => Boolean(item.is_dow_jones));
      } else if (index === indexOptions.Nasdaq) {
        filteredByIndexData = dataForChart.symbols.filter((item) => Boolean(item.is_nasdaq));
      }

      const heatmapElements: HeatmapElement[] = filteredByIndexData.map((item) => ({
        ...item,
        id: getDomId(item.symbol),
        symbolName: symbolsMap.get(item.symbol)?.description
      }));

      if ((filters.sector && !sectors.has(filters.sector.id))
        || (filters.industry && !industries.has(filters.industry.id))
      ) {
        this.heatmapFilters$.next(DEFAULT_HEATMAP_FILTERS);

        return this.createTree(heatmapElements, industries, sectors);
      }

      let filteredHeatmapElements = [...heatmapElements];

      if (filters.sector) {
        filteredHeatmapElements = [...filteredHeatmapElements]
          .filter((item) => item.sector === filters.sector.id);
      }

      if (filters.industry) {
        filteredHeatmapElements = [...filteredHeatmapElements]
          .filter((item) => item.industry === filters.industry.id);
      }

      if (onlyWithWeeklyOptions) {
        filteredHeatmapElements = this.filterByWeeklyOptions(filteredHeatmapElements);
      }

      if ((filters.sector || filters.industry) && filteredHeatmapElements.length === 0) {
        this.heatmapFilters$.next(DEFAULT_HEATMAP_FILTERS);

        return this.createTree(heatmapElements, industries, sectors);
      }

      this.lastUpdateDate$.next(dataForChart.maxUpdatedDate);
      return this.createTree(filteredHeatmapElements, industries, sectors);
    }),
    tap(() => this.loadingSource$.next(false))
  );

  constructor(
    private industryService: MorningStarIndustryService,
    private sectorService: MorningStarSectorService,
    private dataChannelService: DataChannelService,
    private wheelService: WheelService,
    private navigationService: NavigationService,
    private dialogsService: DialogsService,
    private symbolsService: SymbolsService
  ) {
    super();

    this.dataV1$ = combineLatest([
      this.currentDataChannelCommand$
        .pipe(
          tap(() => {
            this.loadingSource$.next(true);
            this.loadingDataForChart$.next(true);
          }),
          debounceTime(CHANGE_DATA_CHANNEL_COMMAND_DEBOUNCE_TIME),
          distinctUntilChanged(),
          tap(() => {
            if (this.dataChannelCommand) {
              this.unsubscribeFromChartData();
            }
          }),
          switchMap((command) => {
            return fromEventPattern<IHeatmapResponse>((handler) => {
              this.dataChannelCommand = {
                subscriptionId: uuidV4(),
                name: command,
                handler
              };

              this.dataChannelService.subscribe(this.dataChannelCommand);
            });
          }),
          tap(() => this.loadingDataForChart$.next(false))
        ),
      this.industryService.get(),
      this.sectorService.get(),
      this.symbolsService.getAll(),
      this.heatmapFilters$.pipe(distinctUntilChanged(_.isEqual)),
      this.onlyWithWeeklyOptions$.pipe(distinctUntilChanged()),
    ]).pipe(
      takeUntil(this._destroy$),
      map(([
        dataForChart,
        industries,
        sectors,
        symbols,
        filters,
        onlyWithWeeklyOptions,
      ]) => {
        const symbolsMap = new Map<string, ISymbol>();
        symbols.forEach((symbol) => {
          if (symbol.country_code === ExchangeCountriesCodes.US) {
            symbolsMap.set(symbol.symbol, symbol);
          }
        });

        const heatmapElements: HeatmapElement[] = dataForChart.symbols.map((item) => ({
          ...item,
          id: getDomId(item.symbol),
          symbolName: symbolsMap.get(item.symbol)?.description
        }));

        if ((filters.sector && !sectors.has(filters.sector.id))
          || (filters.industry && !industries.has(filters.industry.id))
        ) {
          this.heatmapFilters$.next(DEFAULT_HEATMAP_FILTERS);

          return this.createTree(heatmapElements, industries, sectors);
        }

        let filteredHeatmapElements = [...heatmapElements];

        if (filters.sector) {
          filteredHeatmapElements = [...filteredHeatmapElements]
            .filter((item) => item.sector === filters.sector.id);
        }

        if (filters.industry) {
          filteredHeatmapElements = [...filteredHeatmapElements]
            .filter((item) => item.industry === filters.industry.id);
        }

        if (onlyWithWeeklyOptions) {
          filteredHeatmapElements = this.filterByWeeklyOptions(filteredHeatmapElements);
        }

        if ((filters.sector || filters.industry) && filteredHeatmapElements.length === 0) {
          this.heatmapFilters$.next(DEFAULT_HEATMAP_FILTERS);

          return this.createTree(heatmapElements, industries, sectors);
        }

        this.lastUpdateDate$.next(dataForChart.maxUpdatedDate);
        return this.createTree(filteredHeatmapElements, industries, sectors);
      }),
      tap(() => this.loadingSource$.next(false))
    );
  }

  public setHeatmapFilters(filters: Partial<IHeatmapFilters>): void {
    this.heatmapFilters$.next({
      ...this.heatmapFilters$.getValue(),
      ...filters,
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribeFromChartData();
  }

  public unsubscribeFromChartData() {
    if (this.dataChannelCommand) {
      this.dataChannelService.unsubscribe(this.dataChannelCommand);
    }
  }

  public async showGroupOnTheWheelChart(symbol: string): Promise<void> {
    const symbolObj = await this.symbolsService.getBySymbol(symbol, ExchangeCountriesCodes.US);

    if (!symbolObj?.symbol) {
      await this.dialogsService.info('There is no chart data for this symbol. Please try another symbol.', 'Show on chart');
      return;
    }

    const symbolForRedirect = { symbol: symbolObj.symbol, security_id: symbolObj.security_id };

    await this.wheelService.setSymbolForWheelPage(symbolForRedirect);
    await this.navigationService.redirectToTab(
      TabNames.Wheel,
      { symbol: symbolForRedirect },
    );
  }

  private createTree(
    sp500Data: HeatmapElement[],
    industries: MorningStarIndustryMap,
    sectors: MorningStarSectorMap
  ): D3ChartNode<D3ChartNode<HeatmapElement>>[] {
    const tree: SectorsTree = sp500Data.reduce((acc, node) => {
      const sectorId = node.sector;

      if (!node.industry || !node.symbol || !node.sector) {
        console.warn('Warning, invalid symbol passed to heatmap: ', node);
        return acc;
      }

      if (!acc[sectorId]) {
        acc[sectorId] = {
          name: sectors.get(sectorId)?.toUpperCase(),
          id: sectorId,
          children: {
            [node.industry]: this.createIndustryChild(node, industries)
          }
        };
        return acc;
      } else {
        acc[node.sector] = {
          ...acc[node.sector],
          children: this.setSectorChildren(acc[node.sector].children, node, industries)
        };
        return acc;
      }
    }, {});

    return this.transformSectorsTreeAndSortChildren(tree);
  }

  private setSectorChildren(
    sectorChildren: Record<number, D3ChartNode<HeatmapElement>>,
    node: HeatmapElement,
    industries: Map<number, string>
  ) {
    const industryId = node.industry;
    return {
      ...sectorChildren,
      [industryId]: sectorChildren[industryId]
        ? {
          ...sectorChildren[industryId],
          children: [...sectorChildren[industryId].children, node]
        } : this.createIndustryChild(node, industries)
    };
  }

  private createIndustryChild(node: HeatmapElement, industries: Map<number, string>): D3ChartNode<HeatmapElement> {
    return {
      name: industries.get(node.industry)?.toUpperCase(),
      id: node.industry,
      children: [node]
    };
  }

  private transformSectorsTreeAndSortChildren(tree: SectorsTree): D3ChartNode<D3ChartNode<HeatmapElement>>[] {
    return Object.values(tree).map((sector) => {
      const sectorChildren = Object.values(sector.children).map((industry) => ({
        ...industry,
        children: Object.values(industry.children).sort(getNumberComparerDesc<HeatmapElement>((symbol) => symbol.market_cap))
      }));

      return {
        ...sector,
        children: sortIndustries(sectorChildren)
      };
    });
  }

  private filterByWeeklyOptions(data: HeatmapElement[]): HeatmapElement[] {
    return [...data].filter((item) => {
      // important: filter out DLR - is a temporary solution, need to be deleted
      return Boolean(item.has_weekly_options)
        && item.symbol !== 'DLR';
    });
  }
}
