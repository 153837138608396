import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectFeelingsModalComponent, SelectFeelingsModalInputModel, SelectFeelingsModalOutputModel } from '@mdl/select-feelings-modal';
import { DialogsService } from '@s/common';
import { EditionsService } from '@s/editions.service';

@Injectable()
export class TradingLogDialogsService extends DialogsService {

  constructor(
    dialog: MatDialog,
    editionsService: EditionsService,
  ) {
    super(dialog, editionsService);
  }

  public openSelectFeelingsModal(model: SelectFeelingsModalInputModel): Promise<string> {
    return this.openDialog<SelectFeelingsModalComponent,
      SelectFeelingsModalInputModel,
      SelectFeelingsModalOutputModel,
      string>(SelectFeelingsModalComponent, 'feelings', model, (m) => m?.selectedFeelingsId,
        400,
        100,
        '100vw',
        true,
        false,
      );
  }
}
