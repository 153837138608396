<mat-icon
  *appHideForUnavailableFeature="features.IBot"
  [class.always-visible]="showRockyIconAlways$ | async"
  [svgIcon]="'cody-chat'"
  class="ask-rocky-chat-button rocky-icon"
  role="button"
  (click)="onClick()"
  [matTooltip]="'Ask Rocky'"
  [matTooltipHideDelay]="0"
></mat-icon>
