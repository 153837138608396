<div class="backdrop"></div>

@if (currentPositionAndSizeData && currentPositionAndSizeData()) {
  <div
    class="wrapper"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".pxo-app"
    (cdkDragStarted)="onDragStarted()"
    (cdkDragEnded)="onDragEnded($event)"
    appElementResizable
    #drag="cdkDrag"
    (onResizing)="onResizing($event)"
    (onResizeStart)="onResizeStart()"
    (onResizeEnd)="onResizeEnd()"
    [cdkDragFreeDragPosition]="{
      x: currentPositionAndSizeData().x,
      y: currentPositionAndSizeData().y
    }"
    [elementResizeOptions]="elementResizeOptions"
  >
  <div
    #topMenu
    class="top-menu"
    [style.width]="currentPositionAndSizeData().height * elementResizeOptions.aspectRatio + 'px'"
  >
    <div
      class="move-section"
      cdkDragHandle
      [style.visibility]="videoMetadata()?.video ? 'visible' : 'hidden'"
    >
      <mat-icon [svgIcon]="'drag-handler'"></mat-icon>
    </div>
    <div class="video-title" cdkDragHandle>
      <span [innerHTML]="videoMetadata()?.video?.title | sanitizeHtml"></span>
    </div>
    <button
      mat-icon-button
      [matTooltip]="'Open in the Video Hub'"
      class="expand"
      (click)="expandVideo()"
      [style.visibility]="videoMetadata()?.video ? 'visible' : 'hidden'"
    >
      <mat-icon [svgIcon]="'expand-video-icon'"></mat-icon>
    </button>
    <button
      mat-icon-button
      [matTooltip]="'Open in separate window'"
      class="separate"
      (click)="openInSeparateWindow()"
      [style.visibility]="videoMetadata()?.video ? 'visible' : 'hidden'"
    >
      <mat-icon [svgIcon]="'separate-video-icon'"></mat-icon>
    </button>
    <button mat-icon-button class="close" (click)="close()">
      <mat-icon [svgIcon]="'close'"></mat-icon>
    </button>
  </div>

    @if (videoMetadata()?.video) {
      <div class="player resize"
        [style.height]="currentPositionAndSizeData().height + 'px'"
      >
        @if (isMaskVisible()) {
          <div class="mask"></div>
        }
        @if (videoMetadata().video.source == 'youtube') {
          <app-youtube-player
            #player
            [videoId]="videoMetadata().video.videoId"
            [videoStartTime]="videoMetadata().timestamp ?? 0"
          ></app-youtube-player>
        } @else {
          <app-vimeo-player
            #player
            [videoUrl]="videoMetadata().video.url"
            [videoStartTime]="videoMetadata().timestamp ?? 0"
          ></app-vimeo-player>
        }
      </div>
    } @else {
      <div class="loader-wrapper"
        [style.height]="currentPositionAndSizeData().height + 'px'">
        <mat-spinner class="blue-spinner" diameter="40"></mat-spinner>
      </div>
    }
  </div>
}
