export enum Flags {
  Yes = 'Y',
  No = 'N',
  Maybe = 'M',
  None = '',
  Never = 'B',
}

export enum SmileyListType {
  PowerX = 'P',
  Wheel = 'W',
  Wtf = 'F',
  Dividends = 'D',
  StockScreener = 'S',
  ShortSellingStocks = 'H',
  ShortingStocksScanner = 'Q',
  DividendsStrategy = 'G',
}

export interface ISymbolSmiley {
  id: number;
  security_id: number;
  flag: Flags;
}

export interface ISymbolStatisticItemRaw {
  flag: Flags;
  count: number;
}

export interface ISmileyStatisticSubscriptionResponse {
  security_id: number;
  statistics: ISymbolStatisticItemRaw[];
}
