import { ChangeDetectionStrategy, Component, output, ViewEncapsulation } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

import { EditionsSharedModule } from '@containers/editions-demo/editions-shared.module';
import { ObservableService } from '@s/observable.service';
import { Features } from '@const';

@Component({
  selector: 'app-ask-rocky-chat-button',
  templateUrl: './ask-rocky-chat-button.component.html',
  styleUrl: './ask-rocky-chat-button.component.scss',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatTooltip,
    MatIcon,
    AsyncPipe,
    EditionsSharedModule,
  ]
})
export class AskRockyChatButtonComponent {
  public openRockyChat = output<void>();

  protected readonly showRockyIconAlways$ = this.observableService.showRockyAlways;
  protected readonly features = Features;

  constructor(
    private observableService: ObservableService,
  ) {
  }

  protected onClick() {
    this.openRockyChat.emit();
  }
}
