@if ((heatmapVisibilityConfig$ | async).mode == 'Default') {
  <div class="symbol-heading">
    <h2 class="symbol-heading-title">{{ sector }} - {{ industryNode.name }}</h2>
  </div>
} @else {
  <ng-container [ngTemplateOutlet]="extendedModeHeading"></ng-container>
}

@if (showWeeklyOptionsLegend && (hasSymbolsWithWeeklyOptions$ | async)) {
  <div class="weekly-options-legend">
    <span class="weekly-options-icon">W</span>
    <span class="weekly-options-text">Weekly options</span>
  </div>
}

<div
  [class.extended]="(heatmapVisibilityConfig$ | async).mode == 'Extended'"
  class="symbols-container"
>
  <div class="table-container">
    <table>
      <tbody>
        @for (symbol of symbolsList | async; track $index) {
        <tr
          class="symbol-item"
          [class.first]="
            $first &&
            ((!tickerNode && (heatmapVisibilityConfig$ | async).mode == 'Default') ||
              (tickerNode && (heatmapVisibilityConfig$ | async).mode == 'Extended'))
          "
          [ngStyle]="{ '--symbol-item-bg': symbol.color }"
          (click)="close(false, symbol)"
        >
          <td class="text-left">
            <h3 class="symbol-title">
              @if (symbol.has_weekly_options) {
                <span class="weekly-options-icon">W</span>
              }
              {{ symbol.symbol }}
            </h3>
          </td>
          @if (symbol.price !== null) {
            <td class="text-right">
              <div class="price-value-container">
                {{ symbol.price | number : '1.2-2' }}
              </div>
            </td>
          }
          @if (symbol.diff !== null) {
            <td class="text-right">
              <div
              class="diff-value-container"
              [style.color]="
                (!$first &&
                  (heatmapVisibilityConfig$ | async).mode === 'Default') ||
                !$first ||
                  (!(hoveredTicker && tickerNode) &&
                    (heatmapVisibilityConfig$ | async).mode === 'Extended')
                  ? symbol.color
                  : ''
              "
            >
              {{ symbol.diffStr }}
            </div>
            </td>
          }
          @if ($first && ( (!tickerNode && (heatmapVisibilityConfig$ | async).mode == 'Default') || (tickerNode &&
              (heatmapVisibilityConfig$ | async).mode == 'Extended') )) {
            <p class="symbol-subtitle">{{ symbol.symbolName }}</p>
          }
        </tr>
        }
      </tbody>
    </table>
  </div>
</div>

<ng-template #extendedModeHeading>
  <div class="head-wrapper">
    @if (industryNode && sector) {
    <div class="symbol-heading extended">
      <div class="symbol-heading-text-wrapper">
        <div class="symbol-heading-title-wrapper" (click)="close(true)">
          <h2 class="symbol-heading-title">
            <span>{{ sector + ' - ' }}</span>
            @for (nameChunk of inndustryNameItems(); track $index) {
              <span [class.last]="$last">
                {{ nameChunk + ' ' }}
                @if ($last) {
                  <mat-icon [svgIcon]="'heatmap-share-icon'" class="share-icon"></mat-icon>
                }
              </span>
            }
          </h2>
        </div>
        @if (hoveredTicker && tickerNode) {
          <span class="symbol-heading-second-line"> Compare {{ tickerNode.symbol }} with symbols in the same industry on the heatmap. </span>
        } @else {
          <ng-container [ngTemplateOutlet]="tickerNotFound"></ng-container>
        }
        <span class="extended-hint">Daily performance is utilized for comparison</span>
      </div>
    </div>
    } @else {
      <ng-container [ngTemplateOutlet]="industryAndSectorNotFound"></ng-container>
    }
    <button mat-icon-button mat-dialog-close class="popup-close-btn" tabindex="-1" (click)="close()">
      <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon"></mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #industryAndSectorNotFound>
  <div class="not-found-wrapper">
    <div class="title-wrapper">
      <h2 class="symbol-heading-title">{{ hoveredTicker.sector }} - {{ hoveredTicker.industry }}</h2>
    </div>
    <div class="not-found industry">
      <mat-icon [svgIcon]="'attention_icon'"></mat-icon>
      <span
        >Unable to compare <b>{{ hoveredTicker.symbol }}</b> with symbols in the same industry on the heatmap because this industry is absent
        in the heatmap.</span
      >
    </div>
  </div>
</ng-template>

<ng-template #tickerNotFound>
  <div class="not-found">
    <mat-icon [svgIcon]="'attention_icon'"></mat-icon>
    <span
      >Symbol <b>{{ hoveredTicker.symbol }}</b> is absent in the heatmap.</span
    >
  </div>
</ng-template>
