import { catchError, filter, map, of, take } from 'rxjs';
import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  input,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';
import { VideoHubPlayer } from '@t/video-hub/video-hub-player.interface';

@Component({
  selector: 'app-youtube-player',
  standalone: true,
  imports: [YouTubePlayer],
  templateUrl: './youtube-player.component.html',
  styleUrl: './youtube-player.component.scss',
})
export class YoutubePlayerComponent implements AfterViewInit, VideoHubPlayer, OnDestroy {
  protected videoId = input.required<string>();
  protected videoStartTime = input<number>(0);
  protected youtubePlayerVars: YT.PlayerVars | null = {
    autoplay: 1,
  };
  @ViewChild('player')
  private player: YouTubePlayer | undefined;
  @Output()
  private videoEnd = new EventEmitter<void>();

  ngAfterViewInit(): void {
    this.player.stateChange.pipe(
      filter((state) => state.data === 1),
      take(1)
    ).subscribe(() => {
      this.player.seekTo(this.videoStartTime(), true);
    });
  }

  protected onStateChange(event): void {
    if (event.data === 0) {
      this.videoEnd.emit();
    }
  }

  public pause(): void {
    this.player?.pauseVideo();
  }

  public async getCurrentTime(): Promise<number> {
    return await of(this.player?.getCurrentTime())
      .pipe(
        map((value) => (value ? Math.trunc(value) : 0)),
        catchError(() => of(0))
      )
      .toPromise();
  }

  public async getVideoUrl(): Promise<string> {
    return await of(this.player?.getVideoUrl())
      .pipe(
        map((value) => value ?? ''),
        catchError(() => of(''))
      )
      .toPromise();
  }

  ngOnDestroy(): void {
    this.player = null;
  }
}
