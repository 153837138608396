import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { ExpectedMoveModule } from '@c/shared/expected-move/expected-move.module';
import { SymbolFlagModule } from '@c/shared/symbol-flag/symbol-flag.module';
import { EarningsCalendarService } from '@s/calendars/earnings-calendar.service';
import { SymbolDetailsPanelComponent } from './symbol-details-panel.component';
import { LabelModule } from '@c/common/label/label.module';
import { EditionsSharedModule } from '@containers/editions-demo/editions-shared.module';
import {
  SectorIndustryHeatmapDetailsComponent
} from '@c/shared/sector-industy-heatmap-details/sector-industry-heatmap-details.component';

@NgModule({
  declarations: [
    SymbolDetailsPanelComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    SymbolFlagModule,
    LabelModule,
    EditionsSharedModule,
    SectorIndustryHeatmapDetailsComponent
  ],
  providers: [EarningsCalendarService],
  exports: [
    SymbolDetailsPanelComponent,
  ]
})
export class SymbolDetailsPanelModule { }
