import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_SELECT_SCROLL_STRATEGY, MatSelectModule } from '@angular/material/select';
import { MomentDateTimeFormats, tradingLogMinAllowedDate } from '@const';
import {
  TradingLogSymbolSummaryPanelStateDataItem,
} from '@mod/trading-log';
import { TradingLogLogicService, TradingLogSummaryPanelService } from '@s/trading-log';
import { TradingLogStreamingService } from '@s/trading-log/trading-log-streaming.service';
import { TradingLogGroupStatus, TradingLogGroupType } from '@t/trading-log';
import { getDateComparerDesc } from '@u/comparers/date.comparer';
import { blockScrollFactory } from '@u/helpers/block-scroll-factory.helper';
import * as moment from 'moment';
import { BehaviorSubject, catchError, combineLatest, filter, map, Observable, of, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { TradingLogGroupSummaryInfoComponent } from '../trading-log-group-summary-info';
import {
  TradingLogSymbolSummaryPanelItemModel,
  TradingLogSymbolSummaryPanelItemValuesModel,
} from './trading-log-symbol-summary-panel.model';
import { MatSelectRemoveAriaOwnsDirective } from '@core/directives/mat-select-remove-aria-owns/mat-select-remove-aria-owns.directive';

@Component({
  standalone: true,
  selector: 'app-trading-log-symbol-summary-panel',
  templateUrl: './trading-log-symbol-summary-panel.component.html',
  styleUrls: ['./trading-log-symbol-summary-panel.component.scss'],
  imports: [CommonModule, MatSelectModule, MatFormFieldModule, MatBadgeModule, MatButtonModule,
    ReactiveFormsModule, MatProgressSpinnerModule, TradingLogGroupSummaryInfoComponent, MatIconModule, MatSelectRemoveAriaOwnsDirective],
  providers: [
    { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: blockScrollFactory, deps: [Overlay] },
    TradingLogLogicService, TradingLogSummaryPanelService]
})
export class TradingLogSymbolSummaryPanelComponent implements OnInit, OnDestroy {
  @Input() set symbolName(val: string) {
    if (!val) {
      return;
    }
    this.stateItem = this._tradingLogSummaryPanelService.getStateItem(val);
    this.itemControl.setValue(null, { emitEvent: false });
    this._symbolName$.next(val);
  }

  @Output() selected: EventEmitter<TradingLogSymbolSummaryPanelItemValuesModel> = new EventEmitter();

  protected items$: Observable<ReadonlyArray<TradingLogSymbolSummaryPanelItemModel>>;
  protected itemControl: FormControl = new FormControl<TradingLogSymbolSummaryPanelItemModel>(null);
  protected doNotShowTradesValue: Partial<TradingLogSymbolSummaryPanelItemModel> = {
    isDoNotShowTrades: true,
    groupId: 'do-not-show-trades'
  };
  protected itemsLength = 0;
  protected stateItem: TradingLogSymbolSummaryPanelStateDataItem;
  protected isLoaded = false;
  protected isError = false;

  private _symbolName$: BehaviorSubject<string> = new BehaviorSubject(null);
  private _destroy$: Subject<void> = new Subject();

  constructor(
    private _tradingLogStreamingService: TradingLogStreamingService,
    private _tradingLogSummaryPanelService: TradingLogSummaryPanelService,

    tradingLogLogicService: TradingLogLogicService,
  ) {

    this.items$ = combineLatest([
      this._tradingLogStreamingService.groupsLoaded$,
      this._tradingLogStreamingService.accountsLoaded$,
      this._tradingLogStreamingService.strategiesLoaded$,
    ]).pipe(
      filter(([isGroupsLoaded, isAccountsLoaded, isStrategiesLoaded]) => isGroupsLoaded && isAccountsLoaded && isStrategiesLoaded),
      switchMap(() =>
        combineLatest([
          this._tradingLogStreamingService.groups$,
          this._tradingLogStreamingService.groupValueUpdateEvents$.pipe(startWith(null)),
          this._tradingLogStreamingService.summaryMap$,
          this._tradingLogStreamingService.accounts$,
          this._tradingLogStreamingService.strategies$,
          this._symbolName$
        ])
      ),
      tap(() => {
        this.isLoaded = true;
      }),
      map(([groups, groupValueUpdateEvent, summaryMap, accounts, strategies, symbolName]) => {
        const items = (groupValueUpdateEvent?.groups || groups || [])
          .map((group) => ({ group, summary: summaryMap.get(group.id) }))
          .filter(({ group, summary }) => {
            if (!summary) {
              return false;
            }
            const groupStatus = tradingLogLogicService.getGroupStatusBySummary(group, summary);
            // Display only OPEN and CLOSED groups (from SPEC)
            return (groupStatus === TradingLogGroupStatus.Closed || groupStatus === TradingLogGroupStatus.Open)
              // And only groups with selected symbol
              && (group.symbol || '')?.toLowerCase() === (symbolName || '')?.toLowerCase();
          })
          .map(({ group, summary }) => {
            const accountName = accounts.find((account) => account.id === group.account_id)?.name ?? '';
            const strategyName = strategies.find((strategy) => strategy.id === group.strategy_id)?.name ?? '';
            const minDate = summary?.min_date ? moment(summary?.min_date, MomentDateTimeFormats.ServerDate) : null;
            const maxDate = summary?.max_date ? moment(summary?.max_date, MomentDateTimeFormats.ServerDate) : null;
            const item: TradingLogSymbolSummaryPanelItemModel = {
              groupId: group.id,
              groupType: group?.type,
              groupTypeDisplay: tradingLogLogicService.getGroupTypeDisplayName(group?.type),
              breakEven: summary?.break_even,
              costBasis: summary?.cost_average,
              minDate,
              maxDate,
              accountName: accountName || 'Account',
              strategyName: strategyName || 'Strategy',
              ...summary
            };
            return item;
          })
          .sort(getDateComparerDesc((item) => (item.maxDate ?? item.minDate ?? tradingLogMinAllowedDate)));

        let activeItems: TradingLogSymbolSummaryPanelItemModel[] = [];
        let archivedItems: TradingLogSymbolSummaryPanelItemModel[] = [];
        items.forEach((item) => {
          switch (item.groupType) {
            case TradingLogGroupType.Active:
              activeItems = [...activeItems, item];
              if (activeItems.length > 1) {
                item.groupTypeDisplay = ''; // clear group type display value for all but first item to correspond the design;
              }
              break;
            case TradingLogGroupType.Archived:
              archivedItems = [...archivedItems, item];
              if (archivedItems.length > 1) {
                item.groupTypeDisplay = ''; // clear group type display value for all but first item to correspond the design;
              }
              break;
            default:
              break;
          }
        }
        );
        return [...activeItems, ...archivedItems];
      }),
      tap((items) => {
        if (this.itemControl.value && items?.length) {
          const selectedItem = items.find((item) => item.groupId === this.itemControl.value.groupId);
          if (selectedItem) {
            setTimeout(() => {
              this.itemControl.setValue(selectedItem);
            }, 0);
          }
        }
        else {
          if (this.stateItem?.isDoNotShowTrades) {
            this.itemControl.setValue(this.doNotShowTradesValue);
          }
          else if (this.stateItem?.selectedGroupId) {
            const selectedItem = items.find((item) => item.groupId === this.stateItem.selectedGroupId);
            if (selectedItem) {
              this.itemControl.setValue(selectedItem);
            }
          }
        }
      }),
      tap((items) => this.itemsLength = items?.length ?? 0),
      catchError((err) => {
        console.error(`Error: ${err}`);
        this.isLoaded = true;
        this.isError = true;
        return of([]);
      })
    );

    this.itemControl.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(async (value: TradingLogSymbolSummaryPanelItemModel) => {
      this.stateItem.selectedGroupId = value.isDoNotShowTrades ? null : value.groupId;
      this.stateItem.isDoNotShowTrades = !!value.isDoNotShowTrades;
      this.updateSelected();
      await this.saveState();
    });
  }


  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnInit(): void {
  }

  protected trackItems(index: number, item: TradingLogSymbolSummaryPanelItemModel) { return item.groupId; }

  protected costBasisStateChanged(state: boolean) {
    this.stateItem.showCostBasis = state;
    this.saveState();
    this.updateSelected();
  }

  protected breakEvenStateChanged(state: boolean) {
    this.stateItem.showBreakEven = state;
    this.saveState();
    this.updateSelected();
  }

  protected reloadPage(): void {
    window.location.reload();
  }

  private async saveState(): Promise<void> {
    await this._tradingLogSummaryPanelService.saveState(this._symbolName$.value, this.stateItem);
  }

  private updateSelected() {
    this.selected.emit({
      breakEven: this.stateItem.isDoNotShowTrades || !this.stateItem.showBreakEven ? null : (this.itemControl.value?.breakEven || null),
      costBasis: this.stateItem.isDoNotShowTrades || !this.stateItem.showCostBasis ? null : (this.itemControl.value?.costBasis || null)
    });
  }
}
