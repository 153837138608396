<div class="scanner-table scanner-table-v2" id="mattbl">
  <div class="virtual-scroll-wrapper" [class.hidden]="dataSource?.data?.length === 0">
    <cdk-virtual-scroll-viewport
      tvsItemSize="29"
      headerHeight="27"
      bufferMultiplier="5"
      class="scroll-style power-x-scanner-virtual-scroll"
      appResizeDirective
      (resize)="onResize()"
    >
      <table
        mat-table
        matSort
        [dataSource]="dataSource"
        [matSortActive]="sortState.column"
        [matSortDirection]="sortState.direction"
        [trackBy]="trackByFn"
        (matSortChange)="onSortChange($event)"
      >
        <ng-container matColumnDef="flag">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Flag
          </th>
          <td class="scanner-emoji" mat-cell *matCellDef="let element; let i = index;">
            <div class="borderSelection borderSelection-emoji">
              <app-symbol-flag
                [flag]="element.flag"
                [securityId]="element.security_id"
                [smileyListType]="smileyListTypes.PowerX"
                [showNoneFlagOnlyOnHover]="true"
                [sendUpdateSignalOnChange]="false"
                [showStatisticsWhenVisible]="true"
                [rootClass]="'scanner-flag'"
                (menuClosed)="onSelectSmiley(element.security_id, $event)"
                (click)="$event.stopPropagation()"
              ></app-symbol-flag>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="signal">
          <th
            [matTooltip]="scannerSettingType"
            [matTooltipHideDelay]="0"
            [matTooltipPosition]="'below'"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            disableClear
          >
            Signal
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="borderSelection">
              <div [class]="element.signal === 'STO' ? 'lightRed' : 'lightGreen'">
                {{ element.signal === 'STO' ? 'SELL' : 'BUY' }}
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="symbol">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Symbol
          </th>
          <td mat-cell *matCellDef="let element;let i = index">
            <div class="borderSelection">
              {{element.symbol}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="country_code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Exchange
          </th>
          <td mat-cell *matCellDef="let element;let i = index">
            <div class="borderSelection country-code">
              <div
                [class]="element.country_code + '-icon'"
                [matTooltip]="exchangeCountries[element.country_code] + ' Exchange'"
              ></div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="company_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
            Company
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="relative borderSelection">
              <div class="company-eclips">
                {{ element.company_name }}
              </div>

              <div *ngIf="showSmileyStatistic" class="flag-statistics-widget">
                <app-symbol-flag-statistic-widget
                  [securityId]="element.security_id"
                  [listType]="smileyListTypes.PowerX"
                ></app-symbol-flag-statistic-widget>
              </div>

              <div class="ie-btns">
                <img
                  class="light-theme-img"
                  src="assets/img/add-s.svg"
                  (click)="$event.preventDefault(); $event.stopPropagation(); addToWatchlist(element);"
                  [matTooltip]="'Add to watchlist'"
                  [matTooltipPosition]="'above'"
                  alt="Add to watchlist"
                >
                <img
                  class="black-theme-img"
                  src="assets/img/add-s-white.svg"
                  (click)="$event.preventDefault(); $event.stopPropagation(); addToWatchlist(element);"
                  [matTooltip]="'Add to watchlist'"
                  [matTooltipPosition]="'above'"
                  alt="Add to watchlist"
                >
                <img
                  src="assets/img/Go.svg"
                  (click)="$event.preventDefault(); $event.stopPropagation(); moveToWatchlist(element);"
                  [matTooltip]="'Move to watchlist'"
                  [matTooltipPosition]="'above'"
                  alt="Move to watchlist"
                >
                <img
                  src="assets/img/close-red.svg"
                  (click)="$event.preventDefault(); $event.stopPropagation(); removeFromList(element);"
                  [matTooltip]="'Remove from scanner'"
                  [matTooltipPosition]="'above'"
                  alt="Remove from scanner"
                >
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="tableHeaderRow"></tr>
        <tr
          [id]="'scanner-tr-' + row.security_id"
          mat-row *matRowDef="let row; columns: displayedColumns;"
          (click)="onSelectSymbol(row)"
          [ngClass]="{ 'selected': row.id === selectedRow?.id }"
        ></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>

  <div class="no-data-msg" [class.hidden]="dataSource.data.length > 0">
    <div class="message-heading">
      <mat-icon svgIcon="warning-icon" class="icon-lg message-icon"></mat-icon>
      <p class="text">The Scanner found no matching symbols</p>
    </div>
    <div class="message-description">
      <p class="text">Consider <span class="redirect-text" (click)="goToWheelTrades()">"Wheel Trades"</span></p>
      <span class="text-divider vertical"></span>
      <p class="text">Change the scanner criteria</p>
    </div>
  </div>
</div>
