export const VideoHubTabNames = {
  VideoHubCategory: 'video-hub-category',
  VideoHubHome: 'video-hub-home',
  VideoHubTag: 'video-hub-tag',
  VideoHubPlayer: 'video-hub-player',
};

export const VideoHubUrlKey = 'video-hub-url';

export const videoHubHomeUrl = '/video-hub/home';
export const videoHubPlayerUrl = '/video-hub/player';
export const videoHubCategoryUrl = '/video-hub/category';
export const videoHubTagUrl = '/video-hub/tag';

