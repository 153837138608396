<app-top-menu></app-top-menu>
<nav class="navbar" [ngClass]="{ 'wheel-navbar': activeTab === tabNames.Wheel }">
  <mat-icon [svgIcon]="'menu'" class="header-menu-icon big-icon" (click)="openMenuModal('')"></mat-icon>

  <mat-button-toggle-group [value]="activeTab" hideSingleSelectionIndicator="true">
    <mat-button-toggle
      [value]="tabNames.PowerX"
      [class]="activeTab === tabNames.PowerX ? 'active' : 'inactive'"
      (click)="changeTab(tabNames.PowerX)"
      [appFeatureAvailability]="features.PowerX"
    >
      <p class="s1 boldText">PowerX</p>
      <span class="cap">Analyzer</span>
    </mat-button-toggle>
    <mat-button-toggle
      [value]="tabNames.Wheel"
      [class]="activeTab === tabNames.Wheel ? 'active' : 'inactive'"
      (click)="changeTab(tabNames.Wheel)"
      [appFeatureAvailability]="features.Wheel"
    >
      <p class="s1 boldText">The Wheel</p>
      <span class="cap">Analyzer</span>
    </mat-button-toggle>

    <!-- BCS Tab -->
    <mat-button-toggle
      [value]="tabNames.BullCallSpread"
      [class]="activeTab === tabNames.BullCallSpread ? 'active' : 'inactive'"
      (click)="changeTab(tabNames.BullCallSpread)"
      [appFeatureAvailability]="features.BullCallSpread"
    >
      <p class="s1 boldText">BCS</p>
      <span class="cap">Analyzer</span>
    </mat-button-toggle>

    <mat-button-toggle
      [value]="tabNames.Wtf"
      [class]="activeTab === tabNames.Wtf ? 'active' : 'inactive'"
      (click)="changeTab(tabNames.Wtf)"
      [appFeatureAvailability]="features.Wtf"
      class="wtf-tab-btn"
    >
      <p class="s1 boldText">WTF</p>
      <span class="cap">Analyzer</span>
    </mat-button-toggle>

    <mat-button-toggle
      [value]="tabNames.ShortSellingStocks"
      [class]="activeTab === tabNames.ShortSellingStocks ? 'active' : 'inactive'"
      (click)="changeTab(tabNames.ShortSellingStocks)"
      [appFeatureAvailability]="features.ShortSellingStocks"
      class="short-selling-tab-btn"
    >
      <p class="s1 boldText">SOL</p>
      <span class="cap">Analyzer</span>
    </mat-button-toggle>

    <mat-button-toggle
      [value]="tabNames.ShortingScanner"
      [class]="activeTab === tabNames.ShortingScanner ? 'active' : 'inactive'"
      (click)="changeTab(tabNames.ShortingScanner)"
      [appFeatureAvailability]="features.ShortingStocksScanner"
      class="short-selling-tab-btn"
    >
      <p class="s1 boldText">System 1</p>
      <span class="cap">Analyzer</span>
    </mat-button-toggle>

    @if (showDividendsStrategy$ | async) {
      <mat-button-toggle
        [value]="tabNames.DividendsStrategy"
        [class]="activeTab === tabNames.DividendsStrategy ? 'active' : 'inactive'"
        (click)="changeTab(tabNames.DividendsStrategy)"
        [appFeatureAvailability]="features.DividendsStrategy"
        class="short-selling-tab-btn"
      >
        <p class="s1 boldText">Dividends</p>
        <span class="cap">Analyzer</span>
      </mat-button-toggle>
    }
  </mat-button-toggle-group>

  <div class="nav-start">
    <div class="account-size" *ngIf="activeTab === tabNames.PowerX && !showMaintenance" (click)="positionSizing()">
      <div class="size">
        <span>Account Size</span>
        <p>${{ formatNumber(accountSize) }}</p>
      </div>
      <div class="risk">
        <span>Risk</span>
        <div class="risk-inner-div">
          <p>{{ formatDecimal(accountRiskPercent, 1) }}%</p>
          <p>${{ getRiskAmountPerTrade() }}</p>
        </div>
      </div>

      <button mat-icon-button class="settings-icon-btn">
        <mat-icon [svgIcon]="'header-settings-v2'" class="big-icon"></mat-icon>
      </button>
    </div>

    <div class="account-size" *ngIf="activeTab === tabNames.Wheel && !showMaintenance" (click)="wheelPositionSizing()">
      <div class="size">
        <span>Buying Power</span>
        <p>${{ userSetting.wBuyingPower | number: "1.0-0" }}</p>
      </div>
      <div class="risk">
        <span>Positions</span>
        <p>{{ userSetting.wMaxPosition }}</p>
      </div>

      <button mat-icon-button class="settings-icon-btn">
        <mat-icon [svgIcon]="'header-settings-v2'" class="big-icon"></mat-icon>
      </button>
    </div>

    <div class="view-mode-controls hide-on-max-991" *ngIf="activeTab === tabNames.Wheel && !showMaintenance">
      <button
        mat-icon-button
        class="view-mode-button"
        [ngClass]="{ 'active': (wheelViewMode$ | async) === wheelViewModes.Tabs }"
        (click)="changeViewMode(wheelViewModes.Tabs)"
      >
        <mat-icon svgIcon="tabs-mode-icon" class="middle-icon icon tabs-mode-icon"></mat-icon>
      </button>

      <button
        mat-icon-button
        class="view-mode-button"
        [ngClass]="{ 'active': (wheelViewMode$ | async) === wheelViewModes.Split }"
        (click)="changeViewMode(wheelViewModes.Split)"
      >
        <mat-icon svgIcon="split-mode-icon" class="middle-icon icon split-mode-icon"></mat-icon>
      </button>
    </div>
  </div>

  <!--  TODO: move all page-specific fragments to pages (add as slot with "leftNavContent" or "rightNavContent" attributes)  -->

  <div class="nav-start-wtf">
    <ng-content select="[leftNavContent]"></ng-content>
  </div>

  <div class="nav-end">
    <!--  TODO: pass "app-run-scanner-button" as rightNavContent into header  -->
    <app-run-scanner-button *ngIf="activeTab === tabNames.PowerX && !showMaintenance"></app-run-scanner-button>
    <ng-content select="[rightNavContent]"></ng-content>
  </div>

  <div
    *ngIf="activeTab === tabNames.PowerX && !isMobileOS && !showMaintenance"
    class="print-data-group"
  >
    <button mat-icon-button class="open-print-button" (click)="openPrintModal()">
      <mat-icon [svgIcon]="'print'" class="print-icon"></mat-icon>
    </button>
  </div>

  <div
    *ngIf="menuValue !== null"
    class="menu-modals scroll-style"
    [ngClass]="{ 'leftToRight': menuValue, 'rightToLeft': !menuValue }"
    id="menuContent"
  >
    <div class="profile-menu-item scroll-style">
      <div class="profiles-wrapper">
        <div class="profiles">
          <div class="profile-name-container">
            <div class="profile-icon">
              {{ user.first_name?.charAt(0) }}
            </div>
            <div class="profile-name">
            <span class="first-name overflow-ellipsis">
              {{ user.first_name }}
            </span>
            </div>
          </div>
          <a class="profile-menu-block-item profile-settings" (click)="mySettingsModal()" [matTooltip]="'My Settings'">
            <mat-icon [svgIcon]="'my_settings_icon'"></mat-icon>
          </a>
        </div>
      </div>

      <div class="profile-menu-block" *ngIf="user.access_type === 0 || user.access_type === 2">
        <mat-expansion-panel
          hideToggle="true"
          [expanded]="true"
          class="income-statement-panel"
        >
          <mat-expansion-panel-header style="background-color: transparent">
            <mat-panel-title>
              <div class="profile-menu-block-title">Admin</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="profile-menu-block-items">
            <a
              class="profile-menu-block-item"
              *ngIf="user.access_type === 0 || user.access_type === 2"
              (click)="navigateToConfigurationPage()"
            >
              <mat-icon [svgIcon]="'bubble_chart_icon'"></mat-icon>
              <span class="menu-title">Admin Panel</span>
            </a>
          </div>
        </mat-expansion-panel>
      </div>

      <div class="profile-menu-block">
        <mat-expansion-panel
          hideToggle="true"
          [expanded]="true"
          class="income-statement-panel"
        >
          <mat-expansion-panel-header style="background-color: transparent">
            <mat-panel-title>
              <div class="profile-menu-block-title">Strategies</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="strategies-container">
            <a
              class="profile-menu-block-item"
              (click)="changeTab(tabNames.PowerX)"
              [appFeatureAvailability]="features.PowerX"
            >
              <mat-icon class="strategy-icon" [svgIcon]="'power-x-icon'"></mat-icon>
              <span class="menu-title">PowerX</span>
            </a>
            <a
              class="profile-menu-block-item"
              (click)="changeTab(tabNames.Wheel)"
              [appFeatureAvailability]="features.Wheel"
            >
              <mat-icon class="strategy-icon" [svgIcon]="'wheel-icon'"></mat-icon>
              <span class="menu-title">The Wheel</span>
            </a>
            <a
              class="profile-menu-block-item"
              (click)="changeTab(tabNames.BullCallSpread)"
              [appFeatureAvailability]="features.BullCallSpread"
            >
              <mat-icon class="strategy-icon" [svgIcon]="'bcs-icon'"></mat-icon>
              <span class="menu-title">BCS</span>
            </a>
            <a
              class="profile-menu-block-item"
              (click)="changeTab(tabNames.Wtf)"
              [appFeatureAvailability]="features.Wtf"
            >
              <mat-icon class="strategy-icon" [svgIcon]="'wtf-icon'"></mat-icon>
              <span class="menu-title">WTF</span>
            </a>
            <a
              class="profile-menu-block-item"
              (click)="changeTab(tabNames.ShortSellingStocks)"
              [appFeatureAvailability]="features.ShortSellingStocks"
            >
              <mat-icon class="strategy-icon" [svgIcon]="'sol-icon'"></mat-icon>
              <span class="menu-title">SOL</span>
            </a>
            <a
              class="profile-menu-block-item"
              (click)="changeTab(tabNames.ShortingScanner)"
              [appFeatureAvailability]="features.ShortingStocksScanner"
            >
              <mat-icon class="strategy-icon" [svgIcon]="'system-one-icon'"></mat-icon>
              <span class="menu-title">System 1</span>
            </a>

            @if (showDividendsStrategy$ | async) {
              <a
                class="profile-menu-block-item"
                (click)="changeTab(tabNames.DividendsStrategy)"
                [appFeatureAvailability]="features.DividendsStrategy"
              >
                <mat-icon class="strategy-icon" [svgIcon]="'dividends-icon'"></mat-icon>
                <span class="menu-title">Dividends</span>
              </a>
            }
          </div>
        </mat-expansion-panel>
      </div>

      <div class="profile-menu-block">
        <mat-expansion-panel
          hideToggle="true"
          [expanded]="true"
          class="income-statement-panel"
        >
          <mat-expansion-panel-header style="background-color: transparent">
            <mat-panel-title>
              <div class="profile-menu-block-title">Trading</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="strategies-container">
            <a
              class="profile-menu-block-item"
              (click)="openTradingLog()"
              [appFeatureAvailability]="features.TradingLog"
            >
              <mat-icon [svgIcon]="'table_chart_icon'"></mat-icon>
              <span class="menu-title">Trading Log</span>
            </a>
            <a
              class="profile-menu-block-item"
              *ngIf="isTradierIntegrationEnabled$ | async"
              (click)="navigateToTradingPanelPage()"
              [appFeatureAvailability]="features.TradingPanel"
            >
              <mat-icon [svgIcon]="'trading_panel_icon'"></mat-icon>
              <span class="menu-title">Trading Panel</span>
            </a>
            <app-trading-hub-header-menu-button
              cssClass="profile-menu-block-item"
              (click)="closeMenu()"
              [appFeatureAvailability]="features.TradingHub"
            ></app-trading-hub-header-menu-button>
          </div>
        </mat-expansion-panel>
      </div>

      <div class="profile-menu-block">
        <mat-expansion-panel
          hideToggle="true"
          [expanded]="true"
          class="income-statement-panel"
        >
          <mat-expansion-panel-header style="background-color: transparent">
            <mat-panel-title>
              <div class="profile-menu-block-title">Scanners</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a
            class="profile-menu-block-item"
            (click)="navigateToStockScreenersPage()"
            [appFeatureAvailability]="features.StockScreeners"
          >
            <mat-icon [svgIcon]="'hot_list_icon'"></mat-icon>
            <span class="menu-title">Stock Screeners</span>
          </a>
          <a
            class="profile-menu-block-item"
            (click)="navigateToEarningsAnalysisPage()"
            [appFeatureAvailability]="features.EarningsAnalysis"
          >
            <mat-icon [svgIcon]="'correlation'"></mat-icon>
            <span class="menu-title">Earnings Analysis</span>
          </a>
        </mat-expansion-panel>
      </div>

      <div class="profile-menu-block">
        <mat-expansion-panel
          hideToggle="true"
          [expanded]="true"
          class="income-statement-panel"
        >
          <mat-expansion-panel-header style="background-color: transparent">
            <mat-panel-title>
              <div class="profile-menu-block-title">Calendars</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a
            class="profile-menu-block-item"
            (click)="navigateToCalendarPage(tabNames.MasterCalendar)"
            [appFeatureAvailability]="features.MasterCalendar"
          >
            <mat-icon [svgIcon]="'star-icon'"></mat-icon>
            <span class="menu-title">Master</span>
          </a>
          <a
            class="profile-menu-block-item"
            (click)="navigateToCalendarPage(tabNames.EconomicCalendar)"
            [appFeatureAvailability]="features.EconomicCalendar"
          >
            <mat-icon [svgIcon]="'economic-calendar'"></mat-icon>
            <span class="menu-title">Economic</span>
          </a>
          <a
            class="profile-menu-block-item"
            (click)="navigateToCalendarPage(tabNames.EarningsCalendar)"
            [appFeatureAvailability]="features.EarningsCalendar"
          >
            <mat-icon [svgIcon]="'earnings-calendar'"></mat-icon>
            <span class="menu-title">Earnings</span>
          </a>
          <a
            class="profile-menu-block-item"
            (click)="navigateToCalendarPage(tabNames.DividendsCalendar)"
            [appFeatureAvailability]="features.DividendsCalendar"
          >
            <mat-icon [svgIcon]="'dividends-calendar'"></mat-icon>
            <span class="menu-title">Dividends</span>
          </a>
          <a
            class="profile-menu-block-item"
            (click)="navigateToCalendarPage(tabNames.HolidaysCalendar)"
            [appFeatureAvailability]="features.HolidaysCalendar"
          >
            <mat-icon [svgIcon]="'wb_sunny_icon'"></mat-icon>
            <span class="menu-title">Holidays</span>
          </a>
        </mat-expansion-panel>
      </div>

      <div class="profile-menu-block">
        <mat-expansion-panel
          hideToggle="true"
          [expanded]="true"
          class="income-statement-panel"
        >
          <mat-expansion-panel-header style="background-color: transparent">
            <mat-panel-title>
              <div class="profile-menu-block-title">Tools</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="strategies-container">
            <a
              class="profile-menu-block-item"
              (click)="openAssetCorrelation()"
              [appFeatureAvailability]="features.AssetCorrelation"
            >
              <mat-icon [svgIcon]="'analyze-earning-icon'"></mat-icon>
              <span class="menu-title">Asset Correlation</span>
            </a>
            <a
              class="profile-menu-block-item"
              (click)="openMMTradesTable()"
              [appFeatureAvailability]="features.MMTrades"
            >
              <mat-icon [svgIcon]="'pie_chart_icon'"></mat-icon>
              <span class="menu-title">M & M Trades</span>
            </a>
            <a
              class="profile-menu-block-item"
              (click)="navigateToHeatmapPage()"
              [appFeatureAvailability]="features.Heatmap"
            >
              <mat-icon [svgIcon]="'heatmap'"></mat-icon>
              <span class="menu-title">Heatmap</span>
            </a>

            @if (showVideoHub) {
              <a
                class="profile-menu-block-item"
                (click)="openVideoHub()"
                [appFeatureAvailability]="features.VideoHub"
              >
                <mat-icon [svgIcon]="'video_hub_icon'"></mat-icon>
                <span class="menu-title">Video Hub</span>
              </a>
            }
          </div>
        </mat-expansion-panel>
      </div>

      <div class="divider"></div>

      <div class="dark-theme-toggle" (click)="toggleTheme(!!user.theme)">
        <h4 class="profile-menu-block-item theme-toggle">
          <mat-icon [svgIcon]="'theme_toggle_icon'"></mat-icon>
          Dark Theme
        </h4>
        <mat-slide-toggle
          color="primary"
          hideIcon="true"
          [ngModel]="!user.theme"
          (ngModelChange)="user.theme = $event"
          [disableRipple]="true"
        ></mat-slide-toggle>
      </div>

      <div class="profile-menu-block help-block" [ngClass]="{ 'show': isHelpMenuOpened }">
        <div class="profile-menu-block-title" (click)="toggleHelpMenu()">
          Help
          <button mat-icon-button class="rounded-full">
            <mat-icon [svgIcon]="'arrow-down_icon'" class="toggle-arrow-icon" [ngClass]="{ 'open': isHelpMenuOpened }"></mat-icon>
          </button>
        </div>
        <div class="profile-menu-block-items help-items">
          <a
            class="profile-menu-block-item"
            [ngStyle]="{ 'will-change': isHelpMenuOpened ? 'transform' : 'auto' }"
            target="_blank"
            href="https://members.rockwelltrading.com/courses/2914152/content"
          >
            PXO Tutorials
          </a>

          <ng-container *appHideForUnavailableFeature="features.PxoCommunity">
            <a
              class="profile-menu-block-item"
              [ngStyle]="{ 'will-change': isHelpMenuOpened ? 'transform' : 'auto' }"
              *ngIf="!isTrialUser"
              target="_blank"
              href="https://members.rockwelltrading.com/groups/2871771/feed"
            >
              PXO Community
            </a>
          </ng-container>

          <a
            class="profile-menu-block-item"
            [ngStyle]="{ 'will-change': isHelpMenuOpened ? 'transform' : 'auto' }"
            target="_blank"
            href="https://app.loopedin.io/powerx-optimizer/kb/table-of-contents/table-of-contents"
          >
            PXO User Guide
          </a>
          <a
            class="profile-menu-block-item"
            [ngStyle]="{ 'will-change': isHelpMenuOpened ? 'transform' : 'auto' }"
            target="_blank" href="https://www.rockwelltrading.com/contact-us/"
          >
            Contact Support
          </a>
          <a
            class="profile-menu-block-item"
            [ngStyle]="{ 'will-change': isHelpMenuOpened ? 'transform' : 'auto' }"
            target="_blank" href="https://www.rockwelltrading.com/pxo-releases"
          >
            Release Notes
          </a>
          <a
            class="profile-menu-block-item"
            [ngStyle]="{ 'will-change': isHelpMenuOpened ? 'transform' : 'auto' }"
            target="_blank" href="https://www.rockwelltrading.com/pxo-roadmap"
          >
            Roadmap
          </a>
          <a
            class="profile-menu-block-item"
            [ngStyle]="{ 'will-change': isHelpMenuOpened ? 'transform' : 'auto' }"
            (click)="$event.preventDefault(); navigateToLicenseAgreementPage()"
          >
            License Agreement
          </a>
          <a
            class="profile-menu-block-item"
            [ngStyle]="{ 'will-change': isHelpMenuOpened ? 'transform' : 'auto' }"
            (click)="$event.preventDefault(); navigateToDisclaimerPage()"
          >
            Disclaimer
          </a>
        </div>

        <div class="help-footer">
          <ul>
            <li (click)="sentryTest()">Version {{ version }}</li>
          </ul>
        </div>
      </div>

      <div class="divider"></div>

      <a class="profile-menu-block-item logout" (click)="goToLogout()">
        Log Out
        <mat-icon [svgIcon]="'logout_icon'" class="logout-icon"></mat-icon>
      </a>
    </div>

    <app-links-organiser [mode]="'WEB'" [isVisible]="!!menuValue"></app-links-organiser>
  </div>
</nav>

<div
  (click)="openHelpModal()"
  [class]="['backTransparent', helpValue ? 'modalShow' : 'modalHide']"
></div>

<div
  (click)="openMenuModal('')"
  [class]="['backBlack', menuValue ? 'modalShow' : 'modalHide']"
></div>
