import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  ViewEncapsulation,
  Input,
  Output,
  Renderer2
} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { ChatCodyService } from '@s/chat-cody.service';
import { DialogsService } from '@s/common';
import { MomentDateTimeFormats } from '@const';
import { startAutoScrollToBottom } from '@u/ui-utils';
import {
  ICodyConversationData,
  ICodyConversationUpdated,
  ICodyMessageWithData,
  IGroupedByDateCodyMessages
} from '@c/trading-hub/chat-cody-second/cody-chat.model';
import { ICodyConversation, ICodyMessage, ICodyPostMessageStreamEvent } from '@mod/data/chat-cody.model';

@Component({
  selector: 'app-chat-cody-second-conversation',
  templateUrl: './chat-cody-second-conversation.component.html',
  styleUrls: ['./chat-cody-second-conversation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ChatCodySecondConversationComponent {
  @Input() set isActive(value: boolean) {
    if (value) {
      this.scrollToBottom();
    }
  }

  @Input() set conversationData(value: ICodyConversationData) {
    this.currentConversationData = value;
    this.groupedByDateMessages = this.groupMessagesByDate(this.messages, value);
    this.changeDetectorRef.markForCheck();
  }

  @Input() set conversation(value: ICodyConversation) {
    this.currentConversation = value;

    this.chatCodyService.getMessagesByConversationId(value.id)
      .pipe(
        switchMap((response) => {
          if (response.result.meta.pagination.total_pages === 1) {
            return of([response]);
          }

          // take max 6 pages, 3 => [2, 3]
          const pages = Array(Math.min(response.result.meta.pagination.total_pages, this.maxHistoryPagesLength - 1) - 1)
            .fill(0)
            .map((item, index) => index + 2);

          return combineLatest([
            of(response),
            ...pages.map((item) => this.chatCodyService.getMessagesByConversationId(value.id, { page: item }))
          ]);
        }),
        map((items) => {
          return items.flatMap((item) => item.result.data);
        }),
        tap((messages) => {
          const sortedMessages = [...messages].sort((a, b) => a.created_at - b.created_at);

          this.messages = sortedMessages;
          this.groupedByDateMessages = this.groupMessagesByDate(sortedMessages, this.currentConversationData);

          this.changeDetectorRef.markForCheck();
          this.scrollToBottom();
        }),
        tap(() => {
          const prompt = this.chatCodyService.rockyPrompt$.value;
          if (prompt) {
            this.sendPrompt(prompt);
            this.chatCodyService.rockyPrompt$.next(null);
          }
          this.isLoading.next(false);
        })
      )
      .subscribe();
  }

  @Output() public conversationDeleted = new EventEmitter<ICodyConversation>();
  @Output() public conversationUpdated = new EventEmitter<ICodyConversationUpdated>();

  protected messages: ICodyMessage[] = [];
  protected groupedByDateMessages: IGroupedByDateCodyMessages[] = [];

  protected currentConversation: ICodyConversation = null;
  protected currentConversationData: ICodyConversationData = { conversationId: null, feedbacks: {} };

  protected isLoading = new BehaviorSubject<boolean>(true);
  protected errorMessage: string | null = null;
  protected waitingForResponse = false;
  protected isAbleToSubmitOrRegenerate = true;
  protected messageFormControl = new FormControl<string>('');
  protected isChatMenuOpened = false;

  protected maxQuestionTextLength = 2000;
  protected maxHistoryPagesLength = 6;
  protected defaultErrorMessage = 'Operation failed by unknown reason. Please try again later.';
  protected questionExamples: string[] = [
    'What is Golden cross in stocks?',
    'What is moving average in stocks?',
    'Explain MACD indicator?',
  ];

  constructor(
    private chatCodyService: ChatCodyService,
    private dialogsService: DialogsService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) { }

  protected onChangeAnswerInput(): void {
    if (this.errorMessage) {
      this.hideErrorMessage();
    }
  }

  protected sendPrompt(content?: string) {
    const question = content ?? this.messageFormControl.value.trim();

    if (question.length === 0 || this.waitingForResponse) {
      return;
    }

    const newMessage = {
      id: uuidV4(),
      content: question,
      conversation_id: this.currentConversation.id,
      machine: false,
      flagged: false,
      failed_responding: false,
      created_at: moment().unix(),
    };

    this.messages = [...this.messages, newMessage];
    this.groupedByDateMessages = this.groupMessagesByDate(this.messages, this.currentConversationData);

    this.messageFormControl.setValue('');
    this.waitingForResponse = true;
    this.changeDetectorRef.markForCheck();
    this.scrollToBottom();

    this.chatCodyService.postMessageByConversationId(this.currentConversation.id, question)
      .pipe(
        take(1),
        catchError((error) => {
          this.errorMessage = error.error?.description ?? this.defaultErrorMessage;
          return of(null);
        }),
      )
      .subscribe((response) => {
        if (!response) {
          return;
        }

        const answer = {
          id: uuidV4(),
          content: '',
          conversation_id: this.currentConversation.id,
          machine: true,
          flagged: false,
          failed_responding: false,
          created_at: moment().unix(),
        };

        const evtSource = new EventSource(response.result.stream_url);
        const clearAutoScroll = startAutoScrollToBottom('messages-container', 500);

        evtSource.onerror = (error) => {
          console.error(`Failed to get Cody response: ${JSON.stringify(error)}`);
          evtSource.close();

          answer.content = JSON.stringify(error);
          answer.failed_responding = true;

          this.errorMessage = this.defaultErrorMessage;

          this.updateMessage(answer);
          this.waitingForResponse = false;

          clearAutoScroll();
        };

        evtSource.onmessage = (event: ICodyPostMessageStreamEvent) => {
          const data = event.data;
          let newChunk = '';

          if (data === '[START]') {
            this.updateMessage(answer);
            this.waitingForResponse = true;

            return;
          }

          if (data === '[END]') {
            evtSource.close();

            this.waitingForResponse = false;
            this.updateMessage(answer);
            clearAutoScroll();

            return;
          }

          try {
            newChunk = JSON.parse(data)?.chunk ?? '';
          } catch (error) {}

          answer.content += newChunk;

          // do not regroup messages - "groupMessagesByDate" is too heavy to call on each chunk
          // just update the last message in the last group
          const lastGroup = this.groupedByDateMessages[this.groupedByDateMessages.length - 1];
          lastGroup.messages[lastGroup.messages.length - 1].content =
            this.formatChunkChatAnswer(
              lastGroup.messages[lastGroup.messages.length - 1].content
              + newChunk
            );

          this.changeDetectorRef.detectChanges();
        };
      });
  }

  protected async handleClickFeedback(message: ICodyMessageWithData, type: boolean): Promise<void> {
    if (message.feedback !== null) {
      return;
    }

    this.hideErrorMessage();

    if (type === false) {
      const availableFeedbackOptions = [
        'This is incorrect',
        'This is partially correct',
        'This is missing information',
        'This conflicts with how we trade at Rockwell Trading',
        'Other',
      ];
      const feedbackDetails = await this.dialogsService.openChatAnswerFeedbackModal({ availableFeedbackOptions });

      if (!feedbackDetails || !feedbackDetails.isConfirmed) {
        return;
      }

      const currentMessageIndex = this.messages.findIndex((item) => item.id === message.id);
      const questionMessage = this.messages[currentMessageIndex - 1];

      try {
        await this.chatCodyService.addAnswerFeedback({
          prompt: questionMessage.content,
          completion: message.content,
          isLike: false,
          feedbackText: feedbackDetails.feedbackText,
          feedbackOptions: feedbackDetails.selectedFeedbackOptions.join('; '),
        });
      } catch (error) { }
    }

    const newConversationData: ICodyConversationData = {
      ...this.currentConversationData,
      conversationId: this.currentConversation.id,
      feedbacks: {
        ...this.currentConversationData.feedbacks,
        [message.id]: type,
      }
    };

    this.currentConversationData = newConversationData;
    this.conversationUpdated.emit({
      conversation: this.currentConversation,
      data: newConversationData,
    });

    this.groupedByDateMessages = this.groupMessagesByDate(this.messages, this.currentConversationData);
    this.changeDetectorRef.markForCheck();
  }

  protected clearConversation(): void {
    // Do not remove empty line, it's important for UI (to keep '/n' and empty-line)
    const message = `Do you really want to delete this conversation?

      You can’t undo this action.`;

    this.dialogsService.customConfirm$({
      header: 'Delete conversation',
      confirmationText: message,
      subText: '',
      icon: 'info-icon',
      okText: 'Yes',
      cancelText: 'No',
      showCancel: true,
    })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.conversationDeleted.emit(this.currentConversation);
        }
      });
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      this.scrollToElement('bottom-scroll-anchor');
    }, 100);
  }

  private formatAllChatAnswer(text: string): string {
    return text.replace(/\#\#\#\#(.*)/g, '<h4><strong>$1</strong></h4>')
      .replace(/\#\#\#(.*)/g, '<h4 class="header-custom">$1</h4>')
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  }

  private formatChunkChatAnswer(text: string): string {
    const strongTagCount = text
      .split(/(<strong>|<\/strong>)/)
      .filter((tag) => tag.match(/(<strong>|<\/strong>)/)).length;
    const regex = /\*\*/g;

    return text.replace(regex, (match, offset) =>
      strongTagCount % 2 === 0 ? '<strong>' : '</strong>'
    );
  }

  private scrollToMessage(message: ICodyMessage): void {
    setTimeout(() => {
      const latestMessageID = 'message_' + message.id;
      this.scrollToElement(latestMessageID);
    }, 100);
  }

  private scrollToElement(id: string): void {
    const element = document.querySelector(`#${id}`);

    if (element) {
      this.renderer
        .selectRootElement(`#${id}`, true)
        .scrollIntoView({ behavior: 'instant', block: 'end' });
    }
  }

  private hideErrorMessage(): void {
    this.errorMessage = null;
  }

  private updateMessage(newMessage: ICodyMessage): void {
    if (this.messages.some((message) => message.id === newMessage.id)) {
      this.messages = this.messages.map((message) => {
        if (message.id === newMessage.id) {
          return newMessage;
        }

        return message;
      });
    } else {
      this.messages = [...this.messages, newMessage];
    }

    this.groupedByDateMessages = this.groupMessagesByDate(this.messages, this.currentConversationData);
    this.changeDetectorRef.detectChanges();
    this.scrollToBottom();
  }

  private groupMessagesByDate(
    messages: ICodyMessage[],
    conversationData: ICodyConversationData,
  ): IGroupedByDateCodyMessages[] {
    if (!messages) {
      return [];
    }

    const dateFormat = MomentDateTimeFormats.ServerDate;
    const messagesGroupedByDate = messages.reduce((acc, item) => {
      const formattedDate = moment.unix(item.created_at).format(dateFormat);
      const messageWithFeedback = {
        ...item,
        content: this.formatAllChatAnswer(item.content),
        feedback: conversationData.feedbacks[item.id] ?? null,
      };

      if (acc[formattedDate]) {
        acc[formattedDate] = [...acc[formattedDate], messageWithFeedback];
      } else {
        acc[formattedDate] = [messageWithFeedback];
      }

      return acc;
    }, {} as Record<string, ICodyMessageWithData[]>);

    return Object.keys(messagesGroupedByDate)
      .map((key) => ({ date: key, messages: messagesGroupedByDate[key] }));
  }
}
