import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AsyncPipe, NgClass, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BehaviorSubject, combineLatest, from, of, Subject, Subscriber, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ApexOptions, ApexXAxis, ApexYAxis } from 'ng-apexcharts/lib/model/apex-types';

import { ObservableService } from '@s/observable.service';
import { SymbolsService } from '@s/symbols.service';
import { DialogsService } from '@s/common';
import { IncomeStatementService } from '@s/income-statement.service';
import { ResizeDirectiveModule } from '@core/directives/resize-directive/resize-directive.module';
import { UserDataService } from '@s/user-data.service';
import { saveTabStateDebounceTime, TradingHubTabs, UserSettings } from '@const';
import { round } from '@u/utils';
import { defaultChartOptions, defaultXAxis, defaultYAxis } from './income-statement.data';
import { getNumberUnit } from '@u/helpers/number-unit.helper';
import { TradingHubMode } from '@c/trading-hub/trading-hub.model';
import {
  IncomeStatementType,
  IIncomeStatementChartData,
  IIncomeStatementChartDataSourceItem,
  ISymbolDescription,
} from './income-statement.model';
import { IIncomeStatementItem } from '@mod/data/income-statement.model';

const DEFAULT_IS_EXPANDED_STATE = false;
const DEFAULT_SELECTED_TAB = 1;
const DEFAULT_NUMBER_OF_INCOME_STATEMENT_ITEMS = 5;
const DEFAULT_UPDATE_CHART_SIZE_DEBOUNCE_TIME = 100;
const DEFAULT_CHANGE_SYMBOL_DEBOUNCE_TIME_MS = 500;

@Component({
  standalone: true,
  selector: 'app-income-statement',
  templateUrl: './income-statement.component.html',
  styleUrls: ['./income-statement.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    MatExpansionModule,
    MatTabsModule,
    MatButtonModule,
    NgStyle,
    NgApexchartsModule,
    ResizeDirectiveModule,
    NgIf,
    MatProgressSpinnerModule,
    NgTemplateOutlet,
    MatIconModule,
    MatTooltipModule,
    AsyncPipe
  ],
})
export class IncomeStatementComponent implements OnInit, OnDestroy {
  @Input() set securityId(value: number) {
    this.updateCharts$.next(value);
  }

  @Input() settingsSuffix = '';

  @ViewChild('chartContainer') component: ElementRef;

  protected isLoading = true;

  protected isExpanded = true;
  protected saveIsExpandedState$ = new Subject<boolean>();
  protected selectedTab = DEFAULT_SELECTED_TAB;
  protected tabState$ = new Subject<number>();

  protected pageContainerPaddings = 36;
  protected chartHeight = 170;
  protected minBarWidth = 40;
  protected chartHorizontalPaddings = 32;

  protected initialRevenueSeriesSettings = {
    name: 'Revenue',
    data: [],
    color: 'var(--primary-color)',
  };

  protected initialNetIncomeSeriesSettings = {
    name: 'Net Income',
    data: [],
    color: 'var(--success-color)',
  };

  protected containerWidth = 0;
  protected chartWidth = 0;

  protected annualDataSource: IIncomeStatementChartDataSourceItem[] = [];
  protected quarterlyDataSource: IIncomeStatementChartDataSourceItem[] = [];

  protected annualChartDetails: IIncomeStatementChartData = {
    dataSource: [],
    series: [
      { ...this.initialRevenueSeriesSettings, data: [] },
      { ...this.initialNetIncomeSeriesSettings, data: [] },
    ],
    xAxis: { ...defaultXAxis },
    yAxis: { ...defaultYAxis },
    isShowZeroLine: false,
  };

  protected quarterlyChartDetails: IIncomeStatementChartData = {
    dataSource: [],
    series: [
      { ...this.initialRevenueSeriesSettings, data: [] },
      { ...this.initialNetIncomeSeriesSettings, data: [] },
    ],
    xAxis: { ...defaultXAxis },
    yAxis: { ...defaultYAxis },
    isShowZeroLine: false,
  };

  protected chartOptions: ApexOptions = {
    ...defaultChartOptions,
    chart: {
      ...defaultChartOptions.chart,
      height: this.chartHeight,
    }
  };

  protected showRockyDataWindow = this.observableService.showRockyDataWindow.value;
  protected showRockyIconAlways = this.observableService.showRockyAlways;

  private updateCharts$ = new BehaviorSubject<number>(null);
  private containerWidth$ = new Subject<{ width: number, height: number }>();
  private currentSymbolDetails: ISymbolDescription | null = null;
  private subscriptions = new Subscriber();

  constructor(
    private userDataService: UserDataService,
    private observableService: ObservableService,
    private dialogsService: DialogsService,
    private incomeStatementService: IncomeStatementService,
    private symbolsService: SymbolsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    const settingsSuffix = this.settingsSuffix === ''
      ? ''
      : '_' + this.settingsSuffix;

    this.subscriptions.add(
      this.updateCharts$
        .pipe(
          tap(() => this.isLoading = true),
          debounceTime(DEFAULT_CHANGE_SYMBOL_DEBOUNCE_TIME_MS),
          distinctUntilChanged(),
          switchMap((securityId) => {
            return securityId === null ? of([]) : this.incomeStatementService.get(securityId);
          }),
          tap((incomeStatements) => {
            this.updateChart(incomeStatements);
            this.isLoading = false;

            this.changeDetectorRef.detectChanges();
          }),
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.updateCharts$.pipe(
        switchMap((id) => from(this.symbolsService.getById(id))),
        filter((response) => !!response),
      ).subscribe(({ symbol, description }) => {
        this.currentSymbolDetails = { symbol, description };

        this.changeDetectorRef.detectChanges();
      })
    );

    this.subscriptions.add(
      combineLatest([
        from(this.userDataService.getAsInt(UserSettings.IncomeStatementTab + settingsSuffix)),
        from(this.userDataService.getAsInt(UserSettings.IncomeStatementIsExpanded + settingsSuffix)),
      ])
        .pipe(take(1))
        .subscribe(([savedTab, isExpandedRaw]) => {
          const availableTabs = [0, 1];

          if (savedTab !== null && availableTabs.includes(savedTab)) {
            this.selectedTab = savedTab;
          }

          this.isExpanded = Boolean(isExpandedRaw ?? DEFAULT_IS_EXPANDED_STATE);

          this.changeDetectorRef.detectChanges();
        })
    );

    this.subscriptions.add(
      this.containerWidth$
        .pipe(
          debounceTime(DEFAULT_UPDATE_CHART_SIZE_DEBOUNCE_TIME),
          distinctUntilChanged((a, b) => a.width === b.width),
        )
        .subscribe(({ width, height }) => {
          const newRoundedWidth = round(width, 0);

          if (round(this.containerWidth, 0) !== newRoundedWidth) {
            this.containerWidth = newRoundedWidth;
            this.updateChartSize(newRoundedWidth);
          }

          this.changeDetectorRef.detectChanges();
        })
    );

    this.subscriptions.add(
      this.tabState$
        .pipe(debounceTime(saveTabStateDebounceTime), distinctUntilChanged())
        .subscribe(async (tabIndex) => {
          await this.userDataService.set(UserSettings.IncomeStatementTab + settingsSuffix, tabIndex);
        })
    );

    this.subscriptions.add(
      this.saveIsExpandedState$
        .pipe(debounceTime(saveTabStateDebounceTime), distinctUntilChanged())
        .subscribe(async (isExpanded) => {
          await this.userDataService.set(UserSettings.IncomeStatementIsExpanded + settingsSuffix, isExpanded);
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  protected onContainerResize(e: { contentRect: { width: number, height: number } }): void {
    this.containerWidth$.next(e.contentRect);
  }

  protected onChangeTab(event: { index: number }): void {
    if (this.selectedTab !== event.index) {
      this.tabState$.next(event.index);
    }

    this.selectedTab = event.index;
    this.updateChartSize(this.containerWidth);
  }

  protected onExpandChange(newState: boolean): void {
    if (this.isExpanded !== newState) {
      this.saveIsExpandedState$.next(newState);
    }

    this.isExpanded = newState;
  }

  private updateChartSize(
    width: number,
    seriesNumber: number = DEFAULT_NUMBER_OF_INCOME_STATEMENT_ITEMS,
  ): void {
    const minChartWidth = (this.minBarWidth * seriesNumber) + this.chartHorizontalPaddings;
    const newChartWidth = Math.max(width - this.pageContainerPaddings, minChartWidth);

    if (this.chartWidth === newChartWidth) {
      return;
    }

    this.chartWidth = newChartWidth;
    this.chartOptions = { ...this.chartOptions, chart: { ...this.chartOptions.chart, width: newChartWidth } };
  }

  private updateChart(items: IIncomeStatementItem[]): void {
    this.annualDataSource = this.transformToChartData(items.filter((item) => item.type === IncomeStatementType.Annual));
    this.quarterlyDataSource = this.transformToChartData(items.filter((item) => item.type === IncomeStatementType.Quarterly));

    this.annualChartDetails = this.getChartProps(this.annualDataSource);
    this.quarterlyChartDetails = this.getChartProps(this.quarterlyDataSource);
  }

  private transformToChartData(
    items: IIncomeStatementItem[]
  ): IIncomeStatementChartDataSourceItem[] {
    return _.orderBy(items, ['date'], ['asc'])
      .map((details) => ({
        date: details.date,
        type: details.type,
        xAxisLabel: this.getXAxisLabel(details.date, details.type),
        netIncome: details.net_income,
        totalRevenue: details.total_revenue,
      }));
  }

  private getChartProps(items: IIncomeStatementChartDataSourceItem[]): IIncomeStatementChartData {
    // or calculate from min absolute value (not 0 or null) divided by 10 to make it visible in all cases
    const zeroBarValue = 0;

    const series = [
      {
        ...this.initialRevenueSeriesSettings,
        data: items.map((details) => {
          if (details.totalRevenue === null) {
            return null;
          }

          return details.totalRevenue || zeroBarValue;
        }),
      },
      {
        ...this.initialNetIncomeSeriesSettings,
        data: items.map((details) => {
          if (details.netIncome === null) {
            return null;
          }

          return details.netIncome || zeroBarValue;
        }),
      },
    ];

    const xAxisValues = items.map((details) => details.xAxisLabel);
    const yAxis: ApexYAxis = { ...defaultYAxis };
    const xAxis: ApexXAxis = {
      ...defaultXAxis,
      range: xAxisValues.length - 1,
      categories: xAxisValues,
    };

    const isShowZeroLine = items
      .flatMap((item) => ([item.netIncome, item.totalRevenue]))
      .some((item) => item < 0);

    return {
      dataSource: items,
      series,
      xAxis,
      yAxis,
      isShowZeroLine,
    };
  }

  private getXAxisLabel(date: string, type: IncomeStatementType): string {
    if (type === IncomeStatementType.Annual) {
      return moment(date).format('YYYY');
    }

    return moment(date).format('MMM YYYY');
  }

  public openTradingHubModal(event: PointerEvent): void {
    event.stopPropagation();

    this.userDataService.set(UserSettings.TradingHubTab, TradingHubTabs.IBot3);
    this.observableService.tradingHubTab.next(TradingHubTabs.IBot3);

    const promptArray = [];

    if (this.quarterlyDataSource.length) {
      promptArray.push(...this.generatePrompt(this.quarterlyDataSource, 'Quarterly', 'MMM YYYY'));
    }

    if (this.annualDataSource.length) {
      promptArray.push(...this.generatePrompt(this.annualDataSource, 'Annual', 'YYYY'));
    }

    let initialPrompt = `Act as a stock analysis expert. I will give you the revenue and the earnings data of the last 5 years of a stock.<br>`;

    if (this.currentSymbolDetails) {
      initialPrompt += `<b>Stock Symbol</b>=${this.currentSymbolDetails.symbol};<br>`
        + `<b>Stock Name</b>=${this.currentSymbolDetails.description};<br>`;
    }

    initialPrompt += `1. First, analyze the data in terms of growth and stability of the company.<br>`
      + `2. Then analyze the data considering the impact of geopolitical events of the past 5 years, the Covid pandemic, inflation data, interest rates and others.<br>`
      + `3. Explain in simple terms how external factors might have impacted the revenue and earnings.<br>`
      + `4. Explain the <b>'Income Statement'</b> Revenue and Net Income of a stock.`;

    const extendedPrompt = `${initialPrompt}<br>${promptArray
      .map((prompt, index) => `4.${++index}. ${prompt}`)
      .join('<br>')}`;

    this.dialogsService.openTradingHubModal(TradingHubMode.Help, extendedPrompt);
  }

  private generatePrompt(dataSource: IIncomeStatementChartDataSourceItem[], prefix: string, dateFormat: string): string[] {
    return dataSource.reduce(
      (acc, data) => {
        const date = moment(data.date).format(dateFormat);
        const revenuePrompt = `${date}=${getNumberUnit(data.totalRevenue)}; `;
        const netIncomePrompt = `${date}=${getNumberUnit(data.netIncome)}; `;

        acc[0] += revenuePrompt;
        acc[1] += netIncomePrompt;

        return acc;
      },
      [`${prefix} Revenue: `, `${prefix} Net Income: `]
    );
  }
}
