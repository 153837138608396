import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

@NgModule({
  declarations: [CountdownTimerComponent, MaintenanceComponent],
  imports: [
    CommonModule,
    MatButtonModule
  ],
  exports: [MaintenanceComponent],
})
export class MaintenanceModule { }
