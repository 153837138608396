<div class="filters-presets-menu shared-component">
  <button
    mat-stroked-button
    color="primary"
    [matMenuTriggerFor]="menu"
    (click)="$event.stopPropagation(); isMenuOpened = true;"
    class="selected-filter-button"
    [class.active]="isMenuOpened"
  >
    <span class="selected-filter-text">
      {{ selectedPreset?.title ?? '(Select preset)' }}
    </span>

    <mat-icon
      iconPositionEnd
      [svgIcon]="'arrow-down_icon'"
      class="middle-icon presets-arrow-down-icon"
    ></mat-icon>
  </button>

  <!-- do not remove, it's for the next release -->
  <!-- <button
    mat-icon-button
    class="btn-standard primary-bg primary save-filter-btn"
    (click)="savePresetSettings()"
    [matTooltip]="'Save filter changes for the selected preset'"
    [matTooltipPosition]="'above'"
    [matTooltipHideDelay]="0"
  >
    <mat-icon [svgIcon]="'save_icon'" class="middle-icon"></mat-icon>
  </button> -->

  <mat-menu
    #menu="matMenu"
    class="filters-presets-settings-menu"
    (closed)="resetEditMode(); isMenuOpened = false"
  >
    <!-- DEFAULT PRESETS SECTION -->
    <p class="filters-list-title">PXO PRESETS:</p>
    <div
      cdkDropList
      [cdkDropListDisabled]="currentPredefinedFiltersList.length < 2"
      class="filters-list-default filters-drop-list default-presets draggable scroll-style"
      [class.drop-disabled]="currentPredefinedFiltersList.length < 2"
      (cdkDropListDropped)="onDropPredefined($event)"
    >
      <ng-container *ngFor="let filter of currentPredefinedFiltersList; trackBy: trackByFn">
        <button
          mat-menu-item
          class="filters-list-item"
          [class.selected]="selectedPreset && selectedPreset.id === filter.id"
          (click)="selectPreset(filter)"
          (contextmenu)="$event.preventDefault()"
          disableRipple
          cdkDragBoundary=".filters-list-default"
          cdkDragLockAxis="y"
          cdkDrag
        >
          <mat-icon
            cdkDragHandle
            [svgIcon]="'drag-handler'"
            class="drag-handler"
          ></mat-icon>

          {{ filter.title }}

          <!-- CURRENTLY THESE ACTIONS ARE DISABLED FOR PREDEFINED PRESETS, CAN TO BE CHANGED IN THE NEXT VERSION -->
          <div class="drop-list-actions">
            <button class="filters-presets-action-btn filters-presets-edit">
              <mat-icon
                class="monochrome-color-icon monochrome-color-icon-hover"
                [svgIcon]="'edit_icon'"
              ></mat-icon>
            </button>
            <button class="filters-presets-action-btn filters-presets-remove">
              <mat-icon
                class="monochrome-color-icon monochrome-color-icon-hover"
                [svgIcon]="'trash_icon'"
              ></mat-icon>
            </button>
          </div>
        </button>
      </ng-container>
    </div>

    <mat-divider class="divider"></mat-divider>

    <!--MY PRESETS SECTION-->
    <p class="filters-list-title">MY PRESETS:</p>

    <div
      cdkDropList
      [cdkDropListDisabled]="currentCustomFiltersList.length < 2"
      class="filters-list-custom filters-drop-list custom-presets draggable scroll-style"
      [class.drop-disabled]="currentCustomFiltersList.length < 2"
      (cdkDropListDropped)="onDropCustom($event)"
    >
      <ng-container *ngFor="let filter of currentCustomFiltersList; trackBy: trackByFn">
        <ng-container *ngIf="filter.id === editedItemId">
          <ng-container *ngTemplateOutlet="editField"></ng-container>
        </ng-container>

        <ng-container *ngIf="filter.id !== editedItemId">
          <button
            mat-menu-item
            class="filters-list-item"
            [class.selected]="selectedPreset && selectedPreset.id === filter.id"
            (click)="selectPreset(filter)"
            (contextmenu)="$event.preventDefault()"
            disableRipple
            cdkDragBoundary=".filters-list-custom"
            cdkDragLockAxis="y"
            cdkDrag
          >
            <mat-icon
              cdkDragHandle
              [svgIcon]="'drag-handler'"
              class="drag-handler"
            ></mat-icon>

            {{ filter.title }}

            <ng-container
              [ngTemplateOutlet]="customPresetMenu"
              [ngTemplateOutletContext]="{ filter: filter }"
            ></ng-container>
          </button>
        </ng-container>
      </ng-container>
    </div>

    <!-- ADD FILTER BUTTON -->
    <button
      *ngIf="editedItemId !== newPresetTempId"
      mat-button
      color="primary"
      class="add-filter-btn full-width"
      (click)="$event.stopPropagation(); setEditModeForNewPreset()"
    >
      <mat-icon [svgIcon]="'plus_large'"></mat-icon>
      <span class="label right-side-title">Save Filter as...</span>
    </button>

    <ng-container *ngIf="editedItemId === newPresetTempId">
      <ng-container *ngTemplateOutlet="editField"></ng-container>
    </ng-container>
  </mat-menu>
</div>

<!-- REUSABLE TEMPLATE | CUSTOM PRESET MENU -->
<ng-template #customPresetMenu let-filter="filter">
  <div class="drop-list-actions">
    <button class="filters-presets-action-btn filters-presets-edit">
      <mat-icon
        class="monochrome-color-icon monochrome-color-icon-hover"
        [svgIcon]="'edit_icon'"
        (click)="$event.preventDefault(); $event.stopPropagation(); setEditMode(filter)"
      ></mat-icon>
    </button>
    <button class="filters-presets-action-btn filters-presets-remove">
      <mat-icon
        class="monochrome-color-icon monochrome-color-icon-hover"
        [svgIcon]="'trash_icon'"
        (click)="$event.preventDefault(); $event.stopPropagation(); removeItem(filter.id)"
      ></mat-icon>
    </button>
  </div>
</ng-template>

<!-- REUSABLE TEMPLATE | EDITING FILTER PANEL -->
<ng-template #editField>
  <div
    (click)="$event.stopPropagation();"
    class="new-filter-wrapper"
  >
    <div class="new-filter-input">
      <div class="form-group full-width">
        <mat-form-field class="new-filter-name-field w-100" appearance="outline" subscriptSizing="dynamic">
          <input
            id="edit-preset-name-input"
            matInput
            type="text"
            placeholder="Preset name"
            [maxlength]="maxPresetTitleLength"
            [(ngModel)]="newFilterName"
            (keydown)="$event.stopPropagation();"
            (keyup)="$event.stopPropagation();"
            (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); savePresetTitle();"
            (keydown.escape)="$event.stopPropagation(); $event.preventDefault(); resetEditMode();"
            (keydown.shift.enter)="$event.stopPropagation(); $event.preventDefault();"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="new-filter-actions" (click)="$event.stopPropagation();">
      <button
        mat-icon-button
        class="successful-bg btn-standard"
        aria-label="add"
        (click)="$event.stopPropagation(); $event.preventDefault(); savePresetTitle();"
      >
        <mat-icon class="middle-icon successful icon-add" [svgIcon]="'select_icon'"></mat-icon>
      </button>

      <button
        mat-icon-button
        class="danger-bg btn-standard primary-bg"
        aria-label="cancel"
        (click)="$event.stopPropagation(); $event.preventDefault(); resetEditMode();"
      >
        <mat-icon class="middle-icon danger icon-cancel" [svgIcon]="'plus'"></mat-icon>
      </button>
    </div>
  </div>
</ng-template>
