<div class="expected-wrapper" [ngClass]="wrapperClass">
  <div class="header-wrap">
    <span class="header">Expected</span>
    <span class="header">Move</span>
  </div>
  <div class="move-wrapper" #expected>
    <ng-container *ngFor="let item of expectedMoves; let i = index">
      <div class="item">
        <div class="data-wrapper" (click)="onExpectedMoveShowClick(item.index)">
          <span class="date">{{ item.expirationDate | date:'MMM d' }}</span>
          <div *ngIf="wrapperClass === 'default'" class="hide-icon">
            <mat-icon
              class="custom-icon-size visibility-icon"
              [ngClass]="{
                'visible': item.index === 1 ? showExpectedMoveOne : showExpectedMoveTwo,
                'expected-move-one': item.index === 1,
                'expected-move-two': item.index === 2
              }"
              [svgIcon]="(item.index === 1 ? showExpectedMoveOne : showExpectedMoveTwo) ? 'eye' : 'close-eye'"
            ></mat-icon>
          </div>
        </div>
        <div class="move-up">
          <div class="data">
            <mat-icon [svgIcon]="'long-arrow-icon'" class="arrow"></mat-icon>
            <span class="price">${{ item.priceUp | number:'1.2-2' }}</span>
            <app-label class="expected-change-wrapper" [appendClass]="'light-success'" (click)="onExpectedMoveValueClick()">
              <p *ngIf="valuesInPercentExpectedMove">{{ item.expectedPercent | number:'1.2-2' }}%</p>
              <p *ngIf="!valuesInPercentExpectedMove">${{ item.expectedMove | number:'1.2-2' }}</p>
            </app-label>
          </div>
          <div class="data">
            <mat-icon [svgIcon]="'long-arrow-icon'" class="arrow down"></mat-icon>
            <span class="price">${{ item.priceDown | number:'1.2-2' }}</span>
            <app-label class="expected-change-wrapper" [appendClass]="'light-danger'" (click)="onExpectedMoveValueClick()">
              <p *ngIf="valuesInPercentExpectedMove">-{{ item.expectedPercent | number:'1.2-2' }}%</p>
              <p *ngIf="!valuesInPercentExpectedMove">-${{ item.expectedMove | number:'1.2-2' }}</p>
            </app-label>
          </div>
        </div>
      </div>
      <span *ngIf="i === 0 && expectedMoves.length > 1" class="expected-dot"></span>
    </ng-container>
  </div>
  @if (showRockyDataWindow && !hideRockyIcon) {
    <div class="rocky-icon-wrapper">
      <mat-icon
        [class.always-visible]="showRockyIconAlways$ | async"
        [svgIcon]="'cody-chat'"
        class="rocky-icon"
        (click)="openTradingHubModal()"
        [matTooltip]="'Ask Rocky'"
        [matTooltipHideDelay]="0"
      ></mat-icon>
    </div>
  }
</div>
