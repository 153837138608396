import {
  DividendsStrategyScannerFilterModel
} from '@c/dividends-strategy-content/dividends-strategy-scanner-filter/dividends-strategy-scanner-filter.model';

export const EMIT_DIVIDENDS_FILTER_CHANGE_DEBOUNCE_TIME_MS = 800;
export const DEFAULT_IS_DIVIDENDS_STRATEGY_FILTERS_HIDDEN = false;

export const DEFAULT_DIVIDENDS_STRATEGY_FILTERS_STATE: DividendsStrategyScannerFilterModel = {
  divPerShareFrom: '',
  divPerShareTo: '',
  divYieldPercentFrom: '',
  divYieldPercentTo: '',
  stockPriceFrom: '',
  stockPriceTo: '',
  breakEvenPeriodYears: 3,
  avgBreakEvenDaysFrom: '',
  avgBreakEvenDaysTo: '',
  roiFrom: '',
  roiTo: '',
};

export const RECOMMENDED_DIVIDENDS_STRATEGY_FILTERS_STATE: DividendsStrategyScannerFilterModel = {
  divPerShareFrom: '',
  divPerShareTo: '',
  divYieldPercentFrom: '',
  divYieldPercentTo: '',
  stockPriceFrom: '',
  stockPriceTo: '',
  breakEvenPeriodYears: 3,
  avgBreakEvenDaysFrom: '',
  avgBreakEvenDaysTo: '',
  roiFrom: '',
  roiTo: '',
};
