import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WheelScannerResponse, WheelSmiley } from '@core1/model';
import { ISymbolSmiley } from '@mod/symbol-smiley/symbol-smiley.model';
import {
  DividendsCount,
  IScannerResponse,
  IScannerResponseV2,
  IWheelFilter,
  IWheelStockOption,
  MinStrikeToLowestClose,
  SectorsFilter,
} from '@t/wheel/wheel.types';

@Injectable({ providedIn: 'root' })
export class WheelScannerService {
  public _symbolChart$: BehaviorSubject<Array<IWheelStockOption> | null> = new BehaviorSubject(null);

  public data$: BehaviorSubject<WheelScannerResponse[]> = new BehaviorSubject([]);

  // specific flag for wheel-data-window, to restore previous selected symbol (if it was selected from scanner)
  public firstScannerResponseReceived = new Subject<boolean>();

  public lastReceivedScannerData$: BehaviorSubject<Array<IWheelStockOption>> = new BehaviorSubject([]);
  public lastReceivedFilteredPortfolioSectorsList$ = new BehaviorSubject<string[]>([]);

  public lastUpdatedTime: BehaviorSubject<string> = new BehaviorSubject(null);

  public timer: BehaviorSubject<string> = new BehaviorSubject(null);

  public smileyTrigger$: BehaviorSubject<ISymbolSmiley[]> = new BehaviorSubject([]); // legacy, for old wheel-watchlist

  public onSelectChart$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(private http: HttpClient) { }

  // listened by data-window, passes stock-options
  public getSymbolChart$(): Observable<Array<IWheelStockOption> | null> {
    return this._symbolChart$.asObservable();
  }

  public setSymbolChart$(value: Array<IWheelStockOption>): void {
    this._symbolChart$.next(value);
  }

  public getWheelScannerDataV2(): Observable<IScannerResponseV2> {
    return this.http.post<{ result: IScannerResponseV2 }>('/v2/wheel/scannerV2', {})
      .pipe(map((res) => res.result));
  }

  public getDataBySymbol(symbolId: number): Observable<IScannerResponse> {
    return this.http.post<{ result: IScannerResponse }>('/v2/wheel', { security_ids: [symbolId] })
      .pipe(map((res) => res.result));
  }

  public getWheelSmileyByUserId(): Observable<WheelSmiley[]> {
    return this.http.get<{ result: WheelSmiley[] }>('/wheel/smiley').pipe(map((res) => res.result));
  }

  public createWheelSmiley(data: any): Observable<boolean> {
    return this.http.post<{ result: any }>('/wheel/smiley/', data).pipe(map((res) => res.result));
  }

  public updateWheelSmiley(data: any): Observable<boolean> {
    return this.http.put<{ result: any }>('/wheel/smiley/', data).pipe(map((res) => res.result));
  }

  public filterStocks(
    data: Array<IWheelStockOption> | null,
    filters: IWheelFilter,
    portfolioSectors: string[] = this.lastReceivedFilteredPortfolioSectorsList$.getValue(),
  ): Array<IWheelStockOption> | null {
    let filteredData = data;

    if (!filteredData || !filters) {
      return filteredData;
    }

    const hasStrikeFromFilter = filters.strikeFrom !== '';
    const hasStrikeToFilter = filters.strikeTo !== '';
    const hasRoiFromFilter = filters.roiFrom !== '';
    const hasRoiToFilter = filters.roiTo !== '';
    const hasPremiumFromFilter = filters.premiumFrom !== '';
    const hasPremiumToFilter = filters.premiumTo !== '';
    const hasExpirationFilter = filters.expiration !== 'All';
    const hasFlagsFilter = filters.flags.length > 0;
    const hasPERatioFromFilter = filters.peRatioFrom !== '';
    const hasPERatioToFilter = filters.peRatioTo !== '';
    const hasMarketCapFromFilter = filters.marketCapFrom.filterValue !== '';
    const hasMarketCapToFilter = filters.marketCapTo.filterValue !== '';
    const hasDividendsAtLeastOneFilter = filters.dividends === DividendsCount.AtLeastOne;
    const hasDividendsNoneFilter = filters.dividends === DividendsCount.None;
    const hasChangeInFromFilter = filters.changeInPercentFrom !== '';
    const hasChangeInToFilter = filters.changeInPercentTo !== '';
    const hasDropInFromFilter = filters.dropInPercentFrom !== '';
    const hasDropInToFilter = filters.dropInPercentTo !== '';

    filteredData = filteredData.filter((el) =>
      (!hasStrikeFromFilter || el.strike_price >= (filters.strikeFrom as number))
      && (!hasStrikeToFilter || el.strike_price <= (filters.strikeTo as number))
      && (!hasRoiFromFilter || el.annularized >= (filters.roiFrom as number))
      && (!hasRoiToFilter || el.annularized <= (filters.roiTo as number))
      && (!hasPremiumFromFilter || el.option_premium >= (filters.premiumFrom as number))
      && (!hasPremiumToFilter || el.option_premium <= (filters.premiumTo as number))
      && (!hasExpirationFilter || el.expiration === filters.expiration)
      && (!hasFlagsFilter || filters.flags.includes(el.flag))
      && (!hasPERatioFromFilter || el.pe_ratio >= (filters.peRatioFrom as number))
      && (!hasPERatioToFilter || el.pe_ratio <= (filters.peRatioTo as number) && el.pe_ratio !== null)
      && (!hasMarketCapFromFilter || el.market_cap >= (filters.marketCapFrom.filterValue as number))
      && (!hasMarketCapToFilter || el.market_cap <= (filters.marketCapTo.filterValue as number))
      && (!hasDividendsNoneFilter || el.dividends_count <= 0)
      && (!hasDividendsAtLeastOneFilter || el.dividends_count > 0)
      && (!hasChangeInFromFilter || el.price_change_percentage >= (filters.changeInPercentFrom as number))
      && (!hasChangeInToFilter || el.price_change_percentage <= (filters.changeInPercentTo as number))
      && (!hasDropInFromFilter || el.drop_percentage >= (filters.dropInPercentFrom as number) && el.drop_percentage !== null)
      && (!hasDropInToFilter || el.drop_percentage <= (filters.dropInPercentTo as number) && el.drop_percentage !== null)
    );

    if (filters.minStrike && filters.minStrike !== MinStrikeToLowestClose.AllStrikes) {
      const minStrikeDataBySymbol: { [key: string]: number } = {};

      filteredData.forEach((element) => {
        minStrikeDataBySymbol[element.symbol] =
          !minStrikeDataBySymbol[element.symbol] || minStrikeDataBySymbol[element.symbol] > element.strike_price
            ? element.strike_price
            : minStrikeDataBySymbol[element.symbol];
      });

      filteredData = filteredData.filter((el) => {
        switch (filters.minStrike) {
          case MinStrikeToLowestClose.Below:
            return minStrikeDataBySymbol[el.symbol] < el.close_40_min;
          case MinStrikeToLowestClose.AtLowestCloseInd:
            return minStrikeDataBySymbol[el.symbol] <= el.close_40_min + 1 && minStrikeDataBySymbol[el.symbol] >= el.close_40_min;
          case MinStrikeToLowestClose.AtOrBellowLowestInd:
            return minStrikeDataBySymbol[el.symbol] <= el.close_40_min + 1;
          default:
            return true;
        }
      });
    }

    if (filters.sectors === SectorsFilter.ExcludePortfolioSectors) {
      filteredData = filteredData.filter((item) => !portfolioSectors.includes(item.sector));

      const filteredStocksSectors = filteredData.reduce((acc: string[], item) => {
        if (!acc.includes(item.sector)) {
          acc.push(item.sector);
        }

        return acc;
      }, []);
    }

    return filteredData;
  }

  public filterStockOptions(
    data: Array<IWheelStockOption> | null,
    filters: IWheelFilter,
  ): Array<IWheelStockOption> | null {
    let filteredData = data;

    if (!filteredData || !filters) {
      return [];
    }

    const hasStrikeFromFilter = filters.strikeFrom !== '';
    const hasStrikeToFilter = filters.strikeTo !== '';
    const hasRoiFromFilter = filters.roiFrom !== '';
    const hasRoiToFilter = filters.roiTo !== '';
    const hasPremiumFromFilter = filters.premiumFrom !== '';
    const hasPremiumToFilter = filters.premiumTo !== '';
    const hasExpirationFilter = filters.expiration !== 'All';
    const hasDropInFromFilter = filters.dropInPercentFrom !== '';
    const hasDropInToFilter = filters.dropInPercentTo !== '';

    filteredData = filteredData.filter((el) =>
      (!hasStrikeFromFilter || Number(el.strike_price) >= Number(filters.strikeFrom))
      && (!hasStrikeToFilter || Number(el.strike_price) <= Number(filters.strikeTo))
      && (!hasRoiFromFilter || Number(el.annularized) >= Number(filters.roiFrom))
      && (!hasRoiToFilter || Number(el.annularized) <= Number(filters.roiTo))
      && (!hasPremiumFromFilter || Number(el.option_premium) >= Number(filters.premiumFrom))
      && (!hasPremiumToFilter || Number(el.option_premium) <= Number(filters.premiumTo))
      && (!hasExpirationFilter || el.expiration === filters.expiration)
      && (!hasDropInFromFilter || el.drop_percentage >= (filters.dropInPercentFrom as number) && el.drop_percentage !== null)
      && (!hasDropInToFilter || el.drop_percentage <= (filters.dropInPercentTo as number) && el.drop_percentage !== null)
    );

    return filteredData;
  }
}
