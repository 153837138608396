<section *ngIf="mode === 'MOBILE'" class="quick-links MOBILE">
  <button mat-icon-button mat-dialog-close class="popup-close-btn" tabindex="-1">
    <mat-icon [svgIcon]="'close-modal-p'" class="close-modal-icon">
    </mat-icon>
  </button>
  <div class="quick-links-heading">
    <div class="quick-links-heading-title">
      <mat-icon [svgIcon]="'pin-icon'" class="heading-icon pin-icon"></mat-icon>
      <span>Links Organizer</span>
    </div>
    <button
      mat-button
      color="primary"
      *ngIf="quickLinks.length"
      (click)="isShowEditPane = true; selectedLink = null"
    >
      <mat-icon [svgIcon]="'plus_large'"></mat-icon>
      Add Link
    </button>
  </div>

  <div cdkDropList class="quick-links-list scroll-style" (cdkDropListDropped)="drop($event)">
    <div class="quick-link-wrapper" *ngFor="let link of quickLinks; let i = index"
         cdkDrag
         cdkDragLockAxis="y"
         cdkDragBoundary=".quick-links-list"
         [cdkDragDisabled]="isShowEditPane">
      <mat-icon
        *ngIf="!isShowEditPane"
        cdkDragHandle
        [svgIcon]="'drag-handler'"
        class="drag-handler"
      ></mat-icon>
      <ng-container *ngIf="selectedLink !== link">
        <span class="quick-link-indent" [class.margin-left]="isShowEditPane">{{retrieveIndentString(link.title)}}</span>
        <span
          (click)="openLink(link.url)"
          class="quick-link"
          [class.empty]="!link.url"
        >
          {{link.title.trim()}}
        </span>
      </ng-container>

      <app-links-organiser-edit-pane
        *ngIf="isShowEditPane && selectedLink === link"
        [selectedLink]="selectedLink"
        (addLink)="addLink($event)"
        (updateLink)="updateLink($event, i)"
        (cancel)="cancelLinkEdit()"
      ></app-links-organiser-edit-pane>

      <div *ngIf="selectedLink !== link" class="quick-link-actions">
        <button class="quick-link-action-btn quick-link-edit" (click)="selectLink(link)">
          <mat-icon class="monochrome-color-icon monochrome-color-icon-hover" [svgIcon]="'edit_icon'"></mat-icon>
        </button>
        <button class="quick-link-action-btn quick-link-remove" (click)="removeLink(i)">
          <mat-icon class="monochrome-color-icon monochrome-color-icon-hover" [svgIcon]="'trash_icon'"></mat-icon>
        </button>
      </div>
    </div>

    <app-links-organiser-edit-pane
      *ngIf="isShowEditPane && !selectedLink"
      [selectedLink]="selectedLink"
      (addLink)="addLink($event)"
      (cancel)="cancelLinkEdit()"
    ></app-links-organiser-edit-pane>

    <ng-container *ngIf="!quickLinks.length && !isShowEditPane">
      <div class="no-links">
        <div class="no-links-image"></div>
        <div class="no-links-content">
          <p class="no-links-description">No added links for now</p>
          <button
            mat-button
            color="primary"
            (click)="isShowEditPane = true"
          >
            <mat-icon [svgIcon]="'plus_large'"></mat-icon>
            Add Link
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</section>

<section *ngIf="mode === 'WEB'" class="quick-links WEB">
  <div class="quick-links-heading">
    <div class="quick-links-heading-title">
      <mat-icon [svgIcon]="'pin-icon'" class="heading-icon pin-icon"></mat-icon>
      <span>Links Organizer</span>
    </div>
    <button
      mat-button
      color="primary"
      *ngIf="quickLinks.length"
      (click)="isShowEditPane = true; selectedLink = null"
    >
      <mat-icon [svgIcon]="'plus_large'" class="middle-icon"></mat-icon>
      Add Link
    </button>
  </div>

  <div cdkDropList class="quick-links-list scroll-style" (cdkDropListDropped)="drop($event)">
    <div class="quick-link-wrapper" *ngFor="let link of quickLinks; let i = index"
         cdkDrag
         cdkDragLockAxis="y"
         cdkDragBoundary=".quick-links-list"
         [cdkDragDisabled]="isShowEditPane">
      <mat-icon
        *ngIf="!isShowEditPane"
        cdkDragHandle
        [svgIcon]="'drag-handler'"
        class="drag-handler"
      ></mat-icon>
      <ng-container *ngIf="selectedLink !== link">
        <span class="quick-link-indent" [class.margin-left]="isShowEditPane">{{retrieveIndentString(link.title)}}</span>
        <span
          (click)="openLink(link.url)"
          class="quick-link"
          [class.empty]="!link.url"
        >
          {{link.title.trim()}}
        </span>
      </ng-container>

      <app-links-organiser-edit-pane
        *ngIf="isShowEditPane && selectedLink === link"
        [selectedLink]="selectedLink"
        (addLink)="addLink($event)"
        (updateLink)="updateLink($event, i)"
        (cancel)="cancelLinkEdit()"
      ></app-links-organiser-edit-pane>

      <div *ngIf="selectedLink !== link" class="quick-link-actions">
        <button class="quick-link-action-btn quick-link-edit" (click)="selectLink(link)">
          <mat-icon class="monochrome-color-icon monochrome-color-icon-hover" [svgIcon]="'edit_icon'"></mat-icon>
        </button>
        <button class="quick-link-action-btn quick-link-remove" (click)="removeLink(i)">
          <mat-icon class="monochrome-color-icon monochrome-color-icon-hover" [svgIcon]="'trash_icon'"></mat-icon>
        </button>
      </div>
    </div>

    <app-links-organiser-edit-pane
      *ngIf="isShowEditPane && !selectedLink"
      [selectedLink]="selectedLink"
      (addLink)="addLink($event)"
      (cancel)="cancelLinkEdit()"
    ></app-links-organiser-edit-pane>

    <ng-container *ngIf="!quickLinks.length && !isShowEditPane">
      <div class="no-links">
        <div class="no-links-image"></div>
        <div class="no-links-content">
          <p class="no-links-description">No added links for now</p>
          <button
            mat-button
            color="primary"
            (click)="isShowEditPane = true"
          >
            <mat-icon [svgIcon]="'plus_large'"></mat-icon>
            Add Link
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</section>
