import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { NewQuickLink, QuickLink } from '@m/common/links-organiser/links-organiser.model';
import { ObservableService } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';
import { UserSettings } from '@const';

@Component({
  selector: 'app-links-organiser',
  templateUrl: './links-organiser.component.html',
  styleUrls: ['./links-organiser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinksOrganiserComponent {
  @Input() readonly mode: 'WEB' | 'MOBILE' = 'MOBILE';
  @Input() set isVisible(value) {
    if (!value) {
      this.isShowEditPane = false;
      this.selectedLink = null;
    }
  }
  protected isShowEditPane = false;
  protected selectedLink: QuickLink | null = null;
  protected quickLinks: QuickLink[] = this.observableService.quickLinks.getValue();

  constructor(private observableService: ObservableService, private userDataService: UserDataService) { }

  protected selectLink(link): void {
    this.selectedLink = link;
    this.isShowEditPane = true;
  }

  protected cancelLinkEdit(): void {
    this.selectedLink = null;
    this.isShowEditPane = false;
  }

  protected openLink(url: string): void {
    const externalUrl = url.match(/^(https?:\/\/|\/)/) ? url : `//${url}`;

    window.open(externalUrl, '_blank');
  }

  protected retrieveIndentString(linkTitle: string): string {
    const indentMatch = linkTitle.match(/^\s+/);

    return indentMatch ? indentMatch[0] : '';
  }

  protected async addLink(newLink: NewQuickLink): Promise<void> {
    this.quickLinks.push({id: this.quickLinks.length + 1, ...newLink });
    this.isShowEditPane = !this.isShowEditPane;
    await this.userDataService.set(UserSettings.QuickLinks, this.quickLinks);
  }

  protected async updateLink(updatedLink: QuickLink, index: number): Promise<void> {
    this.quickLinks.splice(index, 1, updatedLink);
    this.selectedLink = null;
    this.isShowEditPane = !this.isShowEditPane;
    await this.userDataService.set(UserSettings.QuickLinks, this.quickLinks);
  }

  protected async removeLink(index: number): Promise<void> {
    this.quickLinks.splice(index, 1);
    await this.userDataService.set(UserSettings.QuickLinks, this.quickLinks);
  }

  protected async drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.quickLinks, event.previousIndex, event.currentIndex);
    await this.userDataService.set(UserSettings.QuickLinks, this.quickLinks);
  }
}
