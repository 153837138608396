import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { LinksOrganiserComponent } from '@m/common/links-organiser/components/links-organiser/links-organiser.component';
import { FormDefaultsModule } from '@c/shared/forms';
import { LinksOrganiserEditPaneComponent } from './components/links-organiser-edit-pane/links-organiser-edit-pane.component';

@NgModule({
  declarations: [LinksOrganiserComponent, LinksOrganiserEditPaneComponent],
  imports: [CommonModule, DragDropModule, FormDefaultsModule, MatDialogModule],
  exports: [LinksOrganiserComponent]
})
export class LinksOrganiserModule { }
