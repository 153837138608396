import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TabNames, userAgreementUrl, UserSettingsLocal } from '@const';
import { CompareDataWindow } from '@core1/business/compareDatawinow';
import { ObservableService } from '@core1/directives/observable.service';
import { Users } from '@core1/model';
import { environment } from '@env/environment';
import { AppLoaderService as AppLoaderServiceV2 } from '@s/app-loader.service';
import { AuthService } from '@s/common/auth.service';
import { ObservableService as ObservableServiceV2 } from '@s/observable.service';
import { UserDataService as UserDataServiceV2 } from '@s/user-data.service';
import { LocalStorageService } from '@s/local-storage.service';
import { from, Subject } from 'rxjs';
import { delay, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { LoginService } from './login.service';
import { NavigationService } from '@s/navigation.service';
import { DataChannelService } from '@s/data-channel.service';
import { LoginNavigationControlValueEnum } from '@c/my-settings-modal/my-settings-modal.model';
import { LoginRedirectPageList } from '@c/my-settings-modal/my-settings-modal.data';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  public user: Users;
  public isLogin = true;
  public isForgotPass = false;
  public isInvalidEmail = false;
  public serverError: string;
  public showIfNoTag = false;
  public showNoTagMsg: string;
  public showError = false;
  public isSessionExpired = false;
  public isConcurrentLogin = false;
  public isDelay = true;

  loginForm: UntypedFormGroup;
  forgotPassForm: UntypedFormGroup;
  emailRegx =
    /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
  submitted: boolean;
  isLoading = false;

  private _destroy$: Subject<boolean> = new Subject();

  constructor(
    private loginService: LoginService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private observableService: ObservableService,
    public dialog: MatDialog,
    public appLoaderServiceV2: AppLoaderServiceV2,
    private userDataServiceV2: UserDataServiceV2,
    private _observableServiceV2: ObservableServiceV2,
    private _authService: AuthService,
    private _route: ActivatedRoute,
    private _localStorageService: LocalStorageService,
    private _navigationService: NavigationService,
    private dataChannelService: DataChannelService,
  ) { }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.minLength(4)]],
      password: [null, Validators.required],
    });

    this.forgotPassForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.pattern(this.emailRegx)]],
    });

    this._route.queryParamMap
      .pipe(
        filter((map) => map.has('s') && map.get('s') === 'session-expired'),
        delay(100),
        switchMap((_) =>
          from(
            this.router.navigate([], {
              relativeTo: this._route,
              queryParams: { s: null },
              queryParamsHandling: 'merge',
              replaceUrl: true,
            })
          )
        ),
        tap((_) => (this.isSessionExpired = true)),
        delay(500),
        tap((_) => this.loginForm.markAsPristine()),
        tap((_) => (this.isDelay = false)),
        takeUntil(this._destroy$)
      )
      .subscribe();

    this._route.queryParamMap
      .pipe(
        filter((map) => map.has('s') && map.get('s') === 'concurrent-login'),
        delay(100),
        switchMap((_) =>
          from(
            this.router.navigate([], {
              relativeTo: this._route,
              queryParams: { s: null },
              queryParamsHandling: 'merge',
              replaceUrl: true,
            })
          )
        ),
        tap((_) => (this.isConcurrentLogin = true)),
        delay(500),
        tap((_) => this.loginForm.markAsPristine()),
        tap((_) => (this.isDelay = false)),
        takeUntil(this._destroy$)
      )
      .subscribe();

    if (JSON.parse(localStorage.getItem('user'))) {
      this._navigationService.redirectToTab(TabNames.PowerX);
    } else {
      if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        document.getElementsByTagName('body')[0].classList.remove('black-theme');
      } else {
        document.getElementsByTagName('body')[0].classList.add('black-theme');
      }
    }

    this.loginForm.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => this.showError = false);
  }

  // function for login and forgot form show/hide
  forgotpassShow(value) {
    this.serverError = '';
    if (value === 'forgotPsw') {
      this.isLogin = false;
      this.isForgotPass = true;
    } else {
      this.isLogin = true;
      this.isForgotPass = false;
    }
  }

  // function call on login form submit
  async loginSubmit() {
    this.serverError = '';
    this.showError = false;
    if (!this.loginForm.valid) {
      return;
    }

    this.loginForm.markAsDirty();

    this.isLoading = true;
    const userEmail = this.loginForm.value.email;
    const userPassword = this.loginForm.value.password;
    const loginId = uuidv4();
    const loginResult = await this.loginService.login(userEmail, userPassword, loginId);
    this.user = loginResult.result;
    this._localStorageService.set(UserSettingsLocal.LoginId, loginId);

    if (loginResult.type === false) {
      this._authService.watchUserActivity(environment.UpdateTokenByUserActivityDelaySec);
      this.userDataServiceV2.setAuthToken(this.user.token);
      this.userDataServiceV2.setAuthTokenExpirationDate(this.user.tokenExpirationDate || '');
      this._observableServiceV2.showMaintenance.next(this._observableServiceV2.showMaintenance.value);

      // initialize connection for data channel with received token, before redirect to tab
      if (!this.dataChannelService.isConnected){
        this.dataChannelService.initConnection();
      }

      if (!loginResult.result.userAgreement) {
        localStorage.setItem(
          'userLoginDetails',
          JSON.stringify({
            userid: loginResult.result.userId,
            email: this.loginForm.value.email,
            password: this.loginForm.value.password,
            ghlUserId: loginResult.result.ghlUserId,
          })
        );

        // TODO: check - does it make sense to use as a tab (keep in activeTab)
        return this.router.navigate([userAgreementUrl]);
      }

      delete this.user.accessType; // To prevent access type in local storage

      localStorage.setItem('user', JSON.stringify(this.user));
      this.observableService.userSetting.next(this.user);

      const plChartOption: any = {
        firstSchema: new CompareDataWindow().setProfitLossValue(this.user.leftVariant).schema,
        firstSchemaName: new CompareDataWindow().setProfitLossValue(this.user.leftVariant).schemaName,
        secondSchema: new CompareDataWindow().setProfitLossValue(this.user.rightVariant).schema,
        secondSchemaName: new CompareDataWindow().setProfitLossValue(this.user.rightVariant).schemaName,
      };

      localStorage.setItem('theme', this.user.theme == 0 ? 'dark' : 'light');
      this.observableService.setTheme(this.user.theme == 0 ? 'dark' : 'light');
      this.observableService.theme.next(this.user.theme == 0 ? 'dark' : 'light');
      localStorage.setItem('exchange', 'us');
      localStorage.setItem('profitloss', JSON.stringify(plChartOption));
      localStorage.setItem(
        'comparePosition',
        JSON.stringify({
          first: this.user.leftVariant,
          second: this.user.rightVariant,
        })
      );

      this.observableService.comparePostion.next({
        first: this.user.leftVariant,
        second: this.user.rightVariant,
      });
      localStorage.setItem('selectedTrade', 'none');
      localStorage.setItem('selectedEntryExit', 'none');

      // initialize V2 application
      await this.appLoaderServiceV2.initializeApplication();

      const loginNavigationSettings = this._observableServiceV2.loginNavigationSettings.getValue();
      if (loginNavigationSettings.controlValue === LoginNavigationControlValueEnum.SelectedPage) {
        await this._navigationService.redirectToTab(loginNavigationSettings.selectControlValue.tabName);
      } else {
        const lastPage = LoginRedirectPageList.find((p) => p.tabName === this._observableServiceV2.activeTab.getValue());

        if (lastPage) {
          await this._navigationService.redirectToTab(lastPage.tabName);
        } else {
          await this._navigationService.redirectToTab(TabNames.Wheel);
        }
      }
    } else {
      this.isLoading = false;
      this.showError = true;
      if (loginResult.description === 'userDisabled') {
        this.showIfNoTag = true;
        this.showNoTagMsg = `Strange... Your email address is correct, but according to our system, you don't have access to PowerX Optimizer. Please contact us at <a href='mailto:support@rockwelltrading.com'>support@rockwelltrading.com</a> so that we can sort this out.<br/><br/>`;
      } else if (loginResult.description === 'userNotFound') {
        this.showIfNoTag = true;
        this.showNoTagMsg = `We're sorry. We can't find this email in our database. Please contact us at <a href='mailto:support@rockwelltrading.com'>support@rockwelltrading.com</a> to make sure we have the right email on file.<br/><br/>`;
      } else if (loginResult.description === 'tagNotFound') {
        this.showIfNoTag = true;
        this.showNoTagMsg = `Strange... Your email address and password is correct, but according to our system, you don't have access to PowerX Optimizer. Please contact us at <a href='mailto:support@rockwelltrading.com'>support@rockwelltrading.com</a> so that we can sort this out.<br/><br/>`;
      } else if (loginResult.description === 'passwordNotFound') {
        this.showIfNoTag = true;
        this.showNoTagMsg = `This is not the password we have on file. Click on ‘Forgot Password’ and we will send you an email with the password we have on file.<br/><br/>`;
      } else {
        this.showIfNoTag = false;
        this.serverError = 'Please enter a valid email address.';
      }
    }
  }

  // function call on login form submit
  async loginSubmitDirect() {
    this.isLoading = true;
    this.serverError = '';
    if (!this.loginForm.valid) {
      return;
    } else {
      const userEmail = this.loginForm.value.email;
      const userPassword = this.loginForm.value.password;
      const loginId = uuidv4();
      const res = await this.loginService.login(userEmail, userPassword, loginId);
      this.user = res.result;
      this._localStorageService.set(UserSettingsLocal.LoginId, loginId);
      if (res.type === false) {
        this._authService.watchUserActivity(environment.UpdateTokenByUserActivityDelaySec);
        delete this.user.accessType; // To prevent access type in local storage
        localStorage.setItem('user', JSON.stringify(this.user));
        this.observableService.userSetting.next(this.user);
        const plChartOption: any = {
          firstSchema: new CompareDataWindow().setProfitLossValue(this.user.leftVariant).schema,
          firstSchemaName: new CompareDataWindow().setProfitLossValue(this.user.leftVariant).schemaName,
          secondSchema: new CompareDataWindow().setProfitLossValue(this.user.rightVariant).schema,
          secondSchemaName: new CompareDataWindow().setProfitLossValue(this.user.rightVariant).schemaName,
        };

        localStorage.setItem('theme', this.user.theme == 0 ? 'dark' : 'light');
        this.observableService.setTheme(this.user.theme == 0 ? 'dark' : 'light');
        this.observableService.theme.next(this.user.theme == 0 ? 'dark' : 'light');
        localStorage.setItem('exchange', 'us');
        localStorage.setItem('profitloss', JSON.stringify(plChartOption));
        localStorage.setItem(
          'comparePosition',
          JSON.stringify({
            first: this.user.leftVariant,
            second: this.user.rightVariant,
          })
        );

        this.observableService.comparePostion.next({
          first: this.user.leftVariant,
          second: this.user.rightVariant,
        });
        localStorage.setItem('selectedTrade', 'none');
        localStorage.setItem('selectedEntryExit', 'none');

        // initialize V2 application
        await this.appLoaderServiceV2.initializeApplication();
        await this._navigationService.redirectToTab(TabNames.PowerX);
      } else {
        this.showError = true;
        this.serverError = 'Please enter valid email and password';
      }
    }
  }

  // function call on forgot form submit
  forgotPassSubmit() {
    this.submitted = true;
    this.serverError = '';
    if (!this.forgotPassForm.valid) {
      return;
    } else {
      const userEmail = this.forgotPassForm.value.email;

      this.loginService.forgotPassword(userEmail).subscribe(
        (res) => { },
        (err) => {
          this.serverError = 'Provide correct email address';
        }
      );
    }
  }
}
