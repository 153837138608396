<div mat-dialog-title>
  <div class="modal-heading">
    {{ data.customHeader? data.customHeader : data.showAddWatchlistButton ? 'Add Symbol to Watchlist' : 'Symbol Search' }}
    <div class="modal-right-icon">
      <mat-icon mat-dialog-close [svgIcon]="'close-modal-p'" class="close-button-icon"></mat-icon>
    </div>
  </div>
  <div class="modal-search">
    <a class="modal-search-btn" href="javascript:void(0)">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none">
        <path stroke="#a3a6af" d="M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5"></path>
      </svg>
    </a>
    <input
      type="text"
      autocomplete="off"
      id="symbolSearch"
      #myinput
      [(ngModel)]="searchString"
      spellcheck="false"
      placeholder="Search"
      name="searchString"
      (click)="$event.target.select()"
      (keydown.enter)="onEnterButton()"
      (input)="searchSymbol()"
      maxlength="100"
      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
    >
    <a class="modal-close-btn" href="javascript:void(0)" (click)="clearSearchResult()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="12" height="12" fill="none">
        <path stroke="#a3a6af" stroke-width="1.2" d="M1 1l15 15m0-15L1 16"></path>
      </svg>
    </a>
  </div>
</div>

<mat-dialog-content class="symbol-search-modal-content mat-typography">
  <table class="symbol-search-table" *ngIf="symbolData.length > 0">
    <tr *ngFor="let symbol of symbolData | slice:0:40" (click)="selectSymbol(symbol)">
      <td [innerHTML]="symbol?.symbol | highlight: searchString"></td>
      <td [innerHTML]="symbol?.description | highlight: searchString"></td>
      <td >
        {{ ExchangeCountries[symbol?.country_code] }}
      </td>
      <td>
        <div class="{{symbol?.country_code}}-icon"></div>
      </td>
      <td>
        <ng-container *ngIf="data.showAddWatchlistButton && { showDelete: getIsDelete(symbol) } as variable">
          <img
            (click)="addToWatchlist(symbol)"
            *ngIf="!variable.showDelete"
            alt="plus-img"
            [matTooltip]="'Add to Watchlist'"
            [src]="addSvg"
            [matTooltipHideDelay]="100"
            [matTooltipPosition]="'below'"
          >
          <img
            *ngIf="variable.showDelete"
            (click)="deleteSymbol(symbol)"
            src="../../../../../assets/img/close-red.svg"
            class="cancle-image"
            alt="cancle-img"
            [matTooltip]="'Delete from Watchlist'"
            [matTooltipHideDelay]="100"
            [matTooltipPosition]="'below'"
          >
        </ng-container>
      </td>
    </tr>
  </table>
  <table class="symbol-search-table" *ngIf="symbolData.length === 0">
    <tr align="center">
      <td class="search-table-no-data">No symbols match your criteria</td>
    </tr>
  </table>
</mat-dialog-content>
