<div *ngIf="showMaintenance" class="maintenance">
  <div class="content timer" *ngIf="numberCrunchingStatus === jobStatus.InProgress">
    <p class="description">We're busy crunching numbers</p>
    <p class="bold">Data should be available in</p>
    <app-countdown-timer></app-countdown-timer>
    <p class="description">
      Due to market volatility and the increased volume the data processing takes a little bit longer.
    </p>
  </div>
  <div class="content timer" *ngIf="numberCrunchingStatus === jobStatus.Overtime">
    <p class="description">We are still busy crunching numbers.</p>
    <p class="bold">Please wait...</p>
    <img src="../../../assets/img/process-background.png" />
    <p class="description">
      We will update this page automatically when process finishes.
    </p>
  </div>
  <div class="content timer" *ngIf="numberCrunchingStatus === jobStatus.Done || numberCrunchingStatus === jobStatus.Failed">
    <img src="../../../assets/img/refresh-background.png" />
    <p class="bold">Please refresh the page to get the updated data.</p>
    <button mat-flat-button color="primary" class="fill-btn" (click)="reloadPage()">Refresh</button>
  </div>
</div>
