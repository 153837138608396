import { IElementsVisibilityConfig } from '@c/shared/symbol-details-panel/symbol-details-panel.model';

export const DEFAULT_CURRENT_ELEMENTS_VISIBILITY_CONFIG: IElementsVisibilityConfig = {
  showOpenNewsBtn: true,
  showExpectedMove: false,
  showEps: true,
  showMarketCap: true,
  showDividendYield: true,
  showPriceEarningsRatio: true,
  showUpcomingEarnings: true,
  showDivPerShare: false,
  showUpcomingDividends: false,
  showSharesHoldings: false,
};
