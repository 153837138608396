import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { NavigationService } from '@s/navigation.service';
import { ObservableService } from '@s/observable.service';
import { UserDataService } from '@s/user-data.service';
import { TabNames, UserSettings } from '@const';
import { SolSignalAlertDataModel } from '@c/sol-signal-alert/sol-signal-alert.model';

@Component({
  selector: 'app-sol-signal-alert',
  templateUrl: './sol-signal-alert.component.html',
  styleUrl: './sol-signal-alert.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule]
})
export class SolSignalAlertComponent {
  protected currentNotificationIndex = 0;

  constructor(
    private observableService: ObservableService,
    private userDataService: UserDataService,
    private navigationService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef,
    private ref: MatSnackBarRef<SolSignalAlertComponent>,
    @Inject(MAT_SNACK_BAR_DATA) protected data: SolSignalAlertDataModel,
  ) {
  }

  protected onClickSymbol(securityId: number): void {
    const currentTab = this.observableService.activeTab.getValue();

    this.observableService.shortSellingStocksSymbol.next(securityId);
    this.userDataService.set(UserSettings.ShortSellingStocksSymbol, securityId);

    if (currentTab !== TabNames.ShortSellingStocks) {
      this.navigationService.redirectToTab(TabNames.ShortSellingStocks, {}, securityId);
    }

    if (this.data.notifications.length === 1) {
      this.ref.dismissWithAction();
    }
  }

  protected close() {
    this.ref.dismiss();
  }

  protected onClickNext(): void {
    this.currentNotificationIndex = this.currentNotificationIndex === this.data.notifications.length - 1
      ? this.data.notifications.length - 1
      : this.currentNotificationIndex + 1;

    this.changeDetectorRef.markForCheck();
  }

  protected onClickPrev(): void {
    this.currentNotificationIndex = this.currentNotificationIndex === 0
      ? 0
      : this.currentNotificationIndex - 1;

    this.changeDetectorRef.markForCheck();
  }
}
