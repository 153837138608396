import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
import { IChartWidgetApi, TimescaleMark, TimeScaleMarkShape } from '@chart/charting_library';

import { UserDataService } from '@s/user-data.service';
import { HistoricalDataService } from '@s/historical-data.service';
import { EarningsService } from '@s/earnings.service';
import { DividendsService } from '@s/dividends.service';
import { SplitDataService } from '@s/split-data.service';
import { ObservableService } from '@core1/directives/observable.service';
import { convertToEasternTime, formatNumberValue } from '@u/utils';
import { IChartIndicator, IChartSaveData } from '@core/types';
import {
  dividendsMarkerColor,
  earningsAboveExpectationsMarkerColor,
  earningsBelowExpectationsMarkerColor,
  earningsMarkerColor,
  formatDecimalExt,
  MomentDateTimeFormats,
  splitMarkerColor,
  staticStudies,
  studiesWithForceOverlay
} from '@const';
import { oldIndicatorInputsIDs } from '../change-app-versions-data/old-indicator-inputs-id';
import { ISymbol } from '@s/symbols.service';

@Injectable({
  providedIn: 'root'
})
export class TradingChartService {
  theme = '';
  private _destroy$: Subject<boolean> = new Subject();

  constructor(
    private userDataService: UserDataService,
    private historicalDataService: HistoricalDataService,
    private earningsService: EarningsService,
    private dividendsService: DividendsService,
    private splitDataService: SplitDataService,
    private observableService: ObservableService
  ) {
    this.observableService.theme$
      .pipe(takeUntil(this._destroy$))
      .subscribe((theme) => this.theme = theme);
  }

  private tvWidget;

  async saveChartData(newData, oldData, chartType, tvWidget) {
    this.tvWidget = tvWidget;

    if (!_.isEqual(newData, oldData)) {
      await this.userDataService.set(chartType, newData);
      return newData;
    }

    return oldData;
  }

  async takeChartScreenshot(): Promise<Node> {
    if (!this.tvWidget) {
      return;
    }

    return await this.tvWidget.takeClientScreenshot();
  }

  public getCurrentChartUserIndicators(activeChart: IChartWidgetApi): IChartIndicator[] {
    if (!activeChart) {
      return [];
    }

    // filter out "symbol" input for indicators
    return activeChart.getAllStudies()
      .filter((study) => !staticStudies.includes(study.name))
      .map((study) => ({
        name: study.name,
        visible: activeChart.getStudyById(study.id).isVisible(),
        inputs: activeChart.getStudyById(study.id).getInputValues()
          .filter((item) => item.id !== 'symbol'),
        styleValues: activeChart.getStudyById(study.id).getStyleValues(),
      }));
  }

  async restoreUserIndicators(chartSaveData: IChartSaveData, activeChart: IChartWidgetApi) {
    if (!chartSaveData.indicators || !chartSaveData.indicators.length) {
      return [];
    }

    const uniqueIndicators = chartSaveData.indicators.reduce((acc, item) => {
      if (!acc.some((existingItem) => _.isEqual(existingItem, item))) {
        acc.push(item);
      }

      return acc;
    }, []);

    try {
      await Promise.all(
        uniqueIndicators.map(async (indicator) => {
          if (typeof indicator === 'string') {
            await activeChart.createStudy(indicator, false, false);
            return;
          }

          const forceOverlay = studiesWithForceOverlay.includes(indicator.name);
          const visible = indicator.visible ?? true;

          const isArrayOfStudyInputValueItem = indicator.inputs
            && Array.isArray(indicator.inputs)
            && indicator.inputs.every((item) => (typeof item === 'object') && ('id' in item) && ('value' in item));

          const inputs = isArrayOfStudyInputValueItem
            ? indicator.inputs.reduce((acc, item) => {
              // do not apply "symbol" input, it leads to select "Another symbol" option instead of "Main chart symbol"
              if (item.id === 'symbol') {
                return acc;
              }

              // do not use previous version with array of values, use only new one - from "getInputValues" without changes
              if (item.id && item.value) {
                acc[item.id] = item.value;
              }

              return acc;
            }, {})
            : {};

          const newStudyID = await activeChart.createStudy(
            indicator.name,
            forceOverlay,
            false,
            inputs,
            { visible, ...(indicator.styleValues ?? {}) },
          );

          // previous format of saved inputs in indicator is array of values from each StudyInputValueItem
          // to restore it: take default inputs from created indicator
          // and replace values in them with saved indicators (from array of values)
          if (!isArrayOfStudyInputValueItem
            && oldIndicatorInputsIDs[indicator.name]
            && oldIndicatorInputsIDs[indicator.name].length === indicator.inputs.length
          ) {
            const currentIndicatorInputNames = activeChart.getStudyById(newStudyID).getInputValues()
              .map((item) => item.id);

            // combine IDs and values to get array of StudyInputValueItem
            // example (['showMA', 'length', 'col_prev_close'], [false, 20, false])
            //   => [{"id": "showMA", "value": false}, {"id": "length", "value": 20}, {"id": "col_prev_close", "value": false}]
            const restoredInputs = oldIndicatorInputsIDs[indicator.name]
              .map((item, index) => ({ id: item, value: indicator.inputs[index] }))
              .filter((item) => currentIndicatorInputNames.includes(item.id)); // use only relevant for new version inputs

            activeChart.getStudyById(newStudyID).setInputValues(restoredInputs);
          }
        })
      );
    } catch (e) {
      return [];
    }
    return chartSaveData.indicators;
  }

  async getDataForBars(symbol, periodParams, chartHistoryIntervalMonths) {
    const minimalDate = moment().subtract(chartHistoryIntervalMonths, 'month').toDate();
    let bars = await this.historicalDataService.get(symbol.security_id);
    bars = bars.filter((bar) => bar.time > minimalDate);
    const chartToDate = new Date(periodParams.to * 1000).valueOf();
    const firstBarDate = new Date(bars[0].time).valueOf();
    if (chartToDate <= firstBarDate) {
      return [];
    } else {
      return bars;
    }
  }

  async getDataForTimescaleMarks(symbol, timeZone, chartHistoryIntervalMonths) {
    const { firstBar, lastBar} = await this.historicalDataService.getFirstAndLastBars(symbol.security_id);

    if (!firstBar) {
      return;
    }

    const chartMinDate = moment().subtract(chartHistoryIntervalMonths, 'month');
    const firstDateOnChart = convertToEasternTime(firstBar.date);
    const minDate = chartMinDate.unix() <= firstDateOnChart.unix()
      ? chartMinDate
      : firstDateOnChart;

    let maxDate = convertToEasternTime(lastBar.date).add(30, 'days').startOf('day');
    while (maxDate.isoWeekday() === 6 || maxDate.isoWeekday() === 7) {
      maxDate.add(-1, 'days');
    }

    return await this.getDataForTimescaleMarksInternal(symbol, timeZone, minDate, maxDate);
  }

  async getDataForTimescaleMarksV2(symbol: ISymbol, timeZone: string, bars: any[]) {
    if (!bars.length) {
      return [];
    }

    const minDate = moment(bars[0].time);
    const maxDate = moment(bars[bars.length - 1].time).add(30, 'days').startOf('day');

    return await this.getDataForTimescaleMarksInternal(symbol, timeZone, minDate, maxDate);
  }


  private async getDataForTimescaleMarksInternal(symbol: ISymbol, timeZone: string, minDate: moment.Moment, maxDate: moment.Moment) {
    const [earnings, dividends, splits] = await Promise.all([
      this.earningsService.get(symbol.security_id),
      this.dividendsService.get(symbol.security_id),
      this.splitDataService.get(symbol.security_id),
    ]);

    const earningsMarkers: TimescaleMark[] = earnings
      .filter((e) => moment(e.report_date).unix() >= minDate.unix() && moment(e.report_date).unix() <= maxDate.unix())
      .map((earning) => {
        const color = earning.percent === null
          ? earningsMarkerColor
          : earning.percent >= 0
            ? earningsAboveExpectationsMarkerColor
            : earningsBelowExpectationsMarkerColor;
        const shape: TimeScaleMarkShape = earning.percent === null
          ? 'earning'
          : earning.percent === 0
            ? 'earning'
            : earning.percent > 0 ? 'earningUp' : 'earningDown';
        const reportDate = convertToEasternTime(earning.report_date).tz(timeZone).format('ddd, MMM D, YYYY');
        const actual = earning.actual === null
          ? '-'
          : formatDecimalExt(earning.actual, 0, 2);
        const estimated = earning.estimate === null
          ? '-'
          : formatDecimalExt(earning.estimate, 0, 2);
        const surpriseSign = earning.percent === null || earning.percent === 0
          ? ''
          : earning.percent > 0 ? '+' : '';
        const surprise = earning.percent === null
          ? '-'
          : earning.percent === 0 ? '0%' : `${formatDecimalExt(earning.percent * 100, 0, 2)}%`;

        const revenueActual = earning.revenue === null
          ? '-'
          : formatNumberValue(earning.revenue, 3);
        const revenueEstimated = earning.revenue_est === null
          ? '-'
          : formatNumberValue(earning.revenue_est, 3);
        const revenueSurpriseSign = earning.revenue_surprise === null || earning.revenue_surprise === 0
          ? ''
          : earning.revenue_surprise > 0 ? '+' : '';
        const revenueSurprise = earning.revenue_surprise === null
          ? '-'
          : formatNumberValue(earning.revenue_surprise, 3);
        const revenueSurprisePercent = earning.revenue_surprise_percent === null
          ? '-'
          : earning.revenue_surprise_percent === 0 ? '0' : formatDecimalExt(earning.revenue_surprise_percent * 100, 0, 2);
        const revenueSurpriseValue = earning.revenue_surprise !== null && earning.revenue_surprise_percent !== null
          ? `${revenueSurpriseSign}${revenueSurprise} (${revenueSurpriseSign}${revenueSurprisePercent}%)`
          : '-';

        return {
          id: `earnings${moment(earning.report_date).unix()}`,
          time: moment(earning.report_date).unix(),
          color,
          label: 'E',
          shape,
          tooltip: [
            'Earnings & Revenue',
            `______________________`,
            `Date: ${reportDate}`,
            `Actual: ${actual}`,
            `Estimated: ${estimated}`,
            `Surprise: ${surpriseSign}${surprise}`,
            `______________________`,
            `Actual: ${revenueActual}`,
            `Estimated: ${revenueEstimated}`,
            `Surprise: ${revenueSurpriseValue}`,
          ],
        };
      });

    const dividendsMarkers = dividends
      .filter((d) => moment(d.date).unix() >= minDate.unix() && moment(d.date).unix() <= maxDate.unix())
      .map((dividend) => {
        // Tooltip is currently single line due to bug https://github.com/tradingview/charting_library/issues/6601
        const dividendExDate = dividend.date === null
          ? '-'
          : convertToEasternTime(dividend.date).tz(timeZone).format(MomentDateTimeFormats.ReadableDateFullYear);

        const dividendValue = dividend.value ?? '-';

        const dividendPaymentDate = dividend.payment_date === null
          ? '-'
          : convertToEasternTime(dividend.payment_date).tz(timeZone).format(MomentDateTimeFormats.ReadableDateFullYear);

        return {
          id: `dividends${moment(dividend.date).unix()}`,
          time: moment(dividend.date).unix(),
          color: dividendsMarkerColor,
          label: 'D',
          tooltip: [
            'Dividends',
            `______________________`,
            `Ex-date: ${dividendExDate}`,
            `Dividend: ${dividendValue}`,
            `Payment Date: ${dividendPaymentDate}`,
          ],
        };
      });

    const splitMarkers = splits
      .filter((s) => moment(s.date).unix() >= minDate.unix() && moment(s.date).unix() <= maxDate.unix())
      .map((split) => {
        return {
          id: `splits${moment(split.date).unix()}`,
          time: moment(split.date).unix(),
          color: splitMarkerColor,
          label: 'S',
          tooltip: [
            `Split ${split.split}`,
            `______________________`,
            convertToEasternTime(split.date).tz(timeZone).format(MomentDateTimeFormats.ReadableDateFullYear),
          ],
        };
      });

    return [
      ...earningsMarkers,
      ...dividendsMarkers,
      ...splitMarkers
    ];
  }
}
