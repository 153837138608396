import { ExchangeCountriesCodes, WatchlistType } from '@const';

export interface ISymbolDetailsPanelConfig {
  watchlistType?: WatchlistType;
}

export enum RelativelyToMarketTimeOptions {
  BeforeMarket = 'BeforeMarket',
  DuringMarket = 'DuringMarket',
  AfterMarket = 'AfterMarket',
}

// temp, to avoid global refactoring
export interface IEarningDetails {
  security_id: number;
  symbol: string;
  original_report_date: string;
  report_date: string;
  date_confirmed: number; // 0 | 1
  before_after_market: RelativelyToMarketTimeOptions;
  earnings_in_days: number;
}

export interface IPanelSymbolDetails {
  company: string;
  createdDate: string; // date-string: "2021-12-20T19:00:09.000Z"
  exchange: string;
  exchangeCode: string;
  countryCode: string; // ExchangeCountriesCodes ?
  industry: string;
  lastTradePrice: number | null;
  longDescription: string;
  longDescriptionFormatted: string;
  sector: string;
  securityId: number;
  symbol: string;
  marketCap: number | null;
  formattedMarketCap: string | null;
  priceEarningsRatio: number | null;
  dividendYield: number | null;
  eps: number | null;
  percentInsiders: number | null;
  percentInstitutions: number | null;
  isFromCache: boolean;
  symbolIndexDetails: ISymbolIndexDetails;
}

export interface ILiveData {
  symbol: string;
  open: number | null;
  high: number | null;
  low: number | null;
  close: number | null;
  volume: number | null;
  time: number;
  country: ExchangeCountriesCodes;
  isWeekend: boolean;
  isHoliday: boolean;
  isPreMarket: boolean;
  isPostMarket: boolean;
}

export interface IElementsVisibilityConfig {
  showOpenNewsBtn: boolean;
  showExpectedMove: boolean;
  showEps: boolean;
  showMarketCap: boolean;
  showDividendYield: boolean;
  showPriceEarningsRatio: boolean;
  showUpcomingEarnings: boolean;
  showDivPerShare: boolean;
  showUpcomingDividends: boolean;
  showSharesHoldings: boolean;
}

export interface ISymbolIndexDetails {
  isVisible: boolean;
  displayValue: string;
}
